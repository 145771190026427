import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image as ImageR,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { PropertyService } from "../../../../service/property_service";
import { OccurrencesService } from "../../../../service/occurrences_service";
import { UserService } from "../../../../service/user_service";
import { CropSeasonService } from "../../../../service/crop_season_service";
import { BusinessService } from "../../../../service/business_service";
import { SettingsService } from "../../../../service/settings_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
	containerImgTitle: {
		fontFamily: "Roboto bold",
		fontSize: "9px",
		marginBottom: "20px",
	},
	containerImgView: {
		flexDirection: "column",
		fontSize: "9px",
		width: "40%",
	},
	containerImgViewText: {
		fontSize: "9px",
		alignItems: "flex-end",
	},
});

// const data = {
// 	cultura: "TOMATE CEREJA",
// 	area: "TALHÃO 01",
// 	categoria: "CLIMA",
// 	tipo: "SECA",
// 	dtIdentificacao: "11/11/2022",
// 	identificado: "LUCAS",
// 	descricao:
// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut convallis dolor, eu vestibulum sapien. Vivamus sit amet velit euismod, blandit tortor eget, tempus ante. Aliquam non dui vel purus faucibus gravida a at lacus. Aliquam sed nisl consectetur, gravida ipsum nec, pharetra turpis. Phasellus scelerisque augue erat, sit amet maximus ipsum malesuada ac. Fusce vel bibendum purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque et eros nec quam tristique rutrum. Proin dui elit, tempus sed vulputate venenatis, venenatis sed nulla. Ut lobortis porta aliquet.",
// };

const PdfPerdas = ({id}) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [company, setCompany] = useState(null);
	const [propriedades, setPropriedades] = useState(null);
	const [tipoOcorrencia, setTipoOcorrencia] = useState("");
	const [data, setData] = useState([]);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [logo, setLogo] = useState(null);

	const propertyService = new PropertyService();
	const userService = new UserService();
	const occurrencesService = new OccurrencesService();
	const cropSeasonService = new CropSeasonService();
	const businessService = new BusinessService();
	const settingsService = new SettingsService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async() => {
		await downloadLogo();
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		const company = await businessService.getById(user.businessId);
		setCompany(company);
		setUser(user);
		const propriedade = await propertyService.getById(propertyId);
		setPropriedades(propriedade);
		const item = await occurrencesService.getById(id);
		const ocorrencias = await getOcorrencia(item);
		setData(ocorrencias);
		await downloadFiles();
		setLoading(false);
	};

	const downloadLogo = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		let initiLogo = null;

		const base64 = await settingsService.getBusinessLogo(user.businessId);

		if(base64 && base64 !== ""){
			const mimeType = getMimeType(base64);
			const binaryString = atob(base64, "base64");

			let bytes = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++)
				bytes[i] = binaryString.charCodeAt(i);

			const blob = new Blob([bytes], { type: mimeType });

			initiLogo = new File([blob], "0", {
				type: mimeType,
			});

			setLogo(initiLogo);
		} else {
			setLogo(null);
		}
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	const downloadFiles = async () => {
		const initiFiles = [];
		const filesInStorage = await occurrencesService.getImages(id);
		for (let i = 0; i < filesInStorage.length; i++) {
			const fileInStorage = filesInStorage[i];
			const blob = await fetch(fileInStorage).then(r => r.blob());

			const image = new Image();
			const imageUrl = URL.createObjectURL(blob);
			image.src = imageUrl;

			await new Promise((resolve) => {
				image.onload = resolve;
			});

			const canvas = document.createElement("canvas");
			const maxWidth = 600;
			const scaleSize = maxWidth / image.width;
			canvas.width = maxWidth;
			canvas.height = image.height * scaleSize;

			const ctx = canvas.getContext("2d");
			ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

			const resizedBlob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 0.7));

			const file = new File([resizedBlob], `${i}.jpg`, {
				type: "image/jpeg",
			});
			const fileUrl = URL.createObjectURL(file);
			initiFiles.push(fileUrl);
			URL.revokeObjectURL(imageUrl);
		}
		setFiles(initiFiles);
	};
	
	async function getOcorrencia(occurrence) {
		const occurrenceInSafra = occurrence.ocorrenciaSafras;
		const tipoOcorrencia = occurrence.tipoOcorrencia;
		const categoriaOcorrencia = tipoOcorrencia.categoriaOcorrencia;
		let auxOcorrencia = [];
		const quantidade = occurrence?.quantidade !== null ? occurrence.quantidade : 0;
		const responsavel = await userService.getById(occurrence.responsavel_cadastro_id);
		setTipoOcorrencia({
			categoria: categoriaOcorrencia.nome,
			tipo: tipoOcorrencia.nome,
			qte: quantidade,
			data: new Date(occurrence.data).toLocaleDateString("pt-BR")
		});
		for (let i = 0; i < occurrenceInSafra.length; i++) {
			const element = occurrenceInSafra[i];
			const safra = await cropSeasonService.getById(element.safraId);
			const propriedadeCultivo = safra.propriedadeCultivo;
			const cultivo = propriedadeCultivo.cultivo;
			const talhao = safra.talhao;
			const subdivisao = safra?.subdivisao;
			const tipo = subdivisao?.categoriaSubdivisao;
			auxOcorrencia.push({
				id:occurrence.id,
				cultura: captalizeFirstLetterEacheWord(cultivo.cultura + " " + (cultivo.variedade ?? "")),
				area: `Talhão ${talhao.nome} ${subdivisao!== null && subdivisao !== undefined? (captalizeFirstLetterEacheWord(tipo) + ": "  + subdivisao.complemento + " - ") : ""}`,
				categoria: categoriaOcorrencia.nome,
				tipo: tipoOcorrencia.nome,
				dtIdentificado: new Date(occurrence.data).toLocaleDateString("pt-BR"),
				identificado: responsavel?.nome !== undefined? responsavel?.nome : "Nâo Informado",
				descricao: occurrence.descricao,
			});		
		}
		return auxOcorrencia;
	}
	
	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>OCORRÊNCIAS</Text>
							<Text style={styles.textBoldFazenda}>
								{company?.nome_fantasia ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{propriedades?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<ImageR style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>Categoria:</Text>
							<Text style={styles.textNormal}>{tipoOcorrencia.categoria}</Text>
							<Text style={styles.textBold}>Tipo:</Text>
							<Text style={styles.textNormal}>{tipoOcorrencia.tipo}</Text>
						</View>
					</View>

					<View style={styles.body}>
						<View style={styles.mainTitle}>
							<Text>Ocorência</Text>
							<Text style={{ fontSize: "10px" }}>total: {tipoOcorrencia.qte}</Text>
						</View>

						<View style={styles.content}>
							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Cultura</Text>
								<Text style={styles.larguraTexto}>Área</Text>
								<Text style={styles.larguraTexto}>Categoria</Text>
								<Text style={styles.larguraTexto}>Tipo</Text>
								<Text style={styles.larguraTexto}>Data da identificação</Text>
								<Text style={styles.larguraTexto}>identificado por:</Text>
							</View>
							{data.map((i) => {
								return(
									<>
										<View key={i.id} style={styles.tableContent}>
											<Text style={styles.larguraTexto}>{captalizeFirstLetterEacheWord(i.cultura)}</Text>
											<Text style={styles.larguraTexto}>{i.area}</Text>
											<Text style={styles.larguraTexto}>{i.categoria}</Text>
											<Text style={styles.larguraTexto}>{i.tipo}</Text>
											<Text style={styles.larguraTexto}>{i.dtIdentificado}</Text>
											<Text style={styles.larguraTexto}>{i.identificado}</Text>
										</View>
										<View style={{ marginTop: "20px" }}>
											<Text style={styles.tableTitle}>Descrição</Text>
											<Text style={styles.textNormal}>{i.descricao}</Text>
										</View>
									</>);
							})}
							
						</View>
					</View>

					<View style={styles.body} >
						<Text style={styles.containerImgTitle}>Resgistro Fotográfico:</Text>
						<View
							style={{
								flexDirection: "row",
								fontSize: "9px",
								justifyContent: "space-between",
							}}
						>
							{files.map((file,index) => {
								return (
									<View key={index} style={styles.containerImgView} >
										<View style={styles.containerImgViewText} wrap={false}>
											<ImageR style={{width:"auto",height:"200px"}} src={file}  />									
											<Text>{tipoOcorrencia.data}</Text>
										</View>
									</View>
								);
							})}
						</View>
					</View>

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<ImageR src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										www.sisagri.com.br
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};

export default PdfPerdas;
