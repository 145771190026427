import { CustomerRepository } from "../repository/customer_repository";

export class CustomerService {

	constructor() {
		this.customerRepository = new CustomerRepository();
	}

	async getByProperty(params, propertyId) {
		const customersData = await this.customerRepository.getByProperty(params, propertyId);
		return customersData.clients;
	}

	async getById(id) {
		const customer = await this.customerRepository.getById(id);
		return customer;
	}

	async getByPropertyClientId(id) {
		const customer = await this.customerRepository.getByPropertyClientId(id);
		return customer;
	}

	async save(customer) {
		const savedCustomer = await this.customerRepository.save(customer);
		return savedCustomer;
	}

	async update(customer) {
		const savedCustomer = await this.customerRepository.update(customer);
		return savedCustomer;
	}

	async delete(id) {
		return await this.customerRepository.delete(id);
	}

	async changeVisibility(id, propertyId) {
		await this.customerRepository.changeVisibility(id, propertyId);
	}

	async saveBarCode(customer) {
		const savedCustomer = await this.customerRepository.saveBarCode(customer);
		return savedCustomer;
	}

	async updateBarCode(customer) {
		const savedCustomer = await this.customerRepository.updateBarCode(customer);
		return savedCustomer;
	}
}