import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Avatar, Box, Grid, Modal } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
// import logo from "../../../../Assets/images/logoHeaderPdf.png";
import InputMask from "../../../../components/InputMask";
import styles from "../../SettingsPag.module.css";
import { BusinessService } from "../../../../service/business_service";
import { SettingsService } from "../../../../service/settings_service";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};
function YourPRoperty() {
	const [nameFantasy, setNameFantasy] = useState("");
	const [razaoSocial, setRazaoSocial] = useState("");
	const [cpfCnpj, setCpfCnpj] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [openFantasy, setOpenFantasy] = useState(false);
	const [openRazaoSocial, setOpenRazaoSocial] = useState(false);
	const [openCpfCnpj, setOpenCpfCnpj] = useState(false);
	const [openPhone, setOpenPhone] = useState(false);
	const [openEmail, setOpenEmail] = useState(false);
	const [openLogo, setOpenLogo] = useState(false);
	const [aux, setAux] = useState("");
	const [filesLogo, setFilesLogo] = useState([]);
	const [initialFilesLogo, setInitialFilesLogo] = useState(null);

	const businessService = new BusinessService();
	const settingsService = new SettingsService();

	useEffect(() => {
		initData();
		downloadFilesLogo();
	}, []);

	const initData = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const company = await businessService.getById(user.businessId);
		if (company !== null && company !== undefined) {
			if (company.telefone !== null && company.telefone !== undefined) {
				const phone = company.telefone.replace("+55", "").replace(" ", "");
				setPhone(phone);
			}
			const nomeFantasia = company.nome_fantasia;
			const razaoSocial = company.nome;
			const cpfCnpj = company.cpf_cnpj;
			const email = company.email;
			if (nomeFantasia !== null && nomeFantasia !== undefined)
				setNameFantasy(nomeFantasia);
			if (razaoSocial !== null && razaoSocial !== undefined)
				setRazaoSocial(razaoSocial);
			if (cpfCnpj !== null && cpfCnpj !== undefined) setCpfCnpj(cpfCnpj);
			if (email !== null && email !== undefined) setEmail(email);
		}
	};

	const downloadFilesLogo = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		let initiFiles = null;

		const base64 = await settingsService.getBusinessLogo(user.businessId);

		if(base64 && base64 !== ""){
			const mimeType = getMimeType(base64);
			const binaryString = atob(base64, "base64");

			let bytes = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++)
				bytes[i] = binaryString.charCodeAt(i);

			const blob = new Blob([bytes], { type: mimeType });

			initiFiles = [
				new File([blob], "0", {
					type: mimeType,
				})
			];

			setInitialFilesLogo(initiFiles);
		} else {
			setInitialFilesLogo(null);
		}
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	const handleSaveLogo = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const logoToSave = new FormData();

		for (let i = 0; i < filesLogo.length; i++) {
			const image = filesLogo[i];
			logoToSave.append("logo", image);
		}

		if(initialFilesLogo?.length > 0)
			await settingsService.updateBusinessLogo(user.businessId, logoToSave);
		else 
			await settingsService.saveBusinessLogo(user.businessId, logoToSave);

		await downloadFilesLogo();
		setFilesLogo([]);
	};

	const deleteLogo = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		await settingsService.deleteBusinessLogo(user.businessId);
		await downloadFilesLogo();
		setFilesLogo([]);
	};

	const handleOpenFantasy = () => {
		setAux(nameFantasy);
		setOpenFantasy(true); //abre o modal
	};
	const handleOpenLogo = () => {
		setAux(filesLogo);
		setOpenLogo(true); //abre o modal
	};
	const handleOpenRazaoSocialn = () => {
		setAux(razaoSocial);
		setOpenRazaoSocial(true); //abre o modal
	};
	const handleOpenCpfCnpj = () => {
		setAux(cpfCnpj);
		setOpenCpfCnpj(true); //abre o modal
	};
	const handleOpenEmail = () => {
		setAux(email);
		setOpenEmail(true); //abre o modal
	};
	const handleOpenPhone = () => {
		setAux(phone);
		setOpenPhone(true); //abre o modal
	};
	const handleClose = () => {
		setOpenFantasy(false); //fecha o modal
		setOpenRazaoSocial(false); //fecha o modal
		setOpenCpfCnpj(false); //fecha o modal
		setOpenPhone(false); //fecha o modal
		setOpenEmail(false); //fecha o modal
		setOpenLogo(false); //fecha o modal
	};
	const handleCloseCancelFantasy = () => {
		setNameFantasy(aux);
		setOpenFantasy(false); //fecha o modal
	};
	const handleCloseCancelRazaoSocia = () => {
		setRazaoSocial(aux);
		setOpenRazaoSocial(false); //fecha o modal
	};
	const handleCloseCancelCpfCnpj = () => {
		setCpfCnpj(aux);
		setOpenCpfCnpj(false); //fecha o modal
	};
	const handleCloseCancelPhone = () => {
		setPhone(aux);
		setOpenPhone(false); //fecha o modal
	};
	const handleCloseCancelEmail = () => {
		setEmail(aux);
		setOpenEmail(false); //fecha o modal
	};

	const handleCloseNomeFantasia = async () => {
		await handleSaveBusiness();
		handleClose();
	};
	const handleCloseCancelLogo = () => {
		setOpenLogo(false); //fecha o modal
	};

	const handleCloseRazaoSocial = async () => {
		await handleSaveBusiness();
		handleClose();
	};

	const handleCloseCpfCnpj = async () => {
		await handleSaveBusiness();
		handleClose();
	};

	const handleClosePhone = async () => {
		await handleSaveBusiness();
		handleClose();
	};

	const handleCloseEmail = async () => {
		await handleSaveBusiness();
		handleClose();
	};

	const handleCloseLogo = async () => {
		await handleSaveLogo();
		handleClose();
	};

	const handleSaveBusiness = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const business = await businessService.getById(user.businessId);
		const cpf_cnpj = cpfCnpj
			.replace(".", "")
			.replace("-", "")
			.replace("/", "")
			.replace(" ", "");

		const phoneBusiness = phone && phone !== ""
			? `+55${phone}`
			: null;

		const businessToUpdate = {
			id: business.id,
			name: razaoSocial !== "" ? razaoSocial : null,
			alias: nameFantasy !== "" ? nameFantasy : null,
			cpfCnpj: cpf_cnpj !== "" ? cpf_cnpj : null,
			email: email !== "" ? email : null,
			phone: phoneBusiness,
			neighborhood: business.endereco.bairro,
			cep: business.endereco.cep,
			city: business.endereco.cidade,
			federativeUnit: business.endereco.uf,
			address: business.endereco.endereco,
			observation: business.endereco.observacao,
		};

		await businessService.update(business.id, businessToUpdate);
		initData();
	};

	function formatCpfCnpj(cpfCnpj) {
		let cpfCnpjNumbers = cpfCnpj.replace(/\D/g, "").split("");
		if (cpfCnpjNumbers.length === 11) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				cpfCnpjNumbers[2] +
				"." +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				cpfCnpjNumbers[5] +
				"." +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				cpfCnpjNumbers[8] +
				"-" +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10]
			);
		} else if (cpfCnpjNumbers.length === 14) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				"." +
				cpfCnpjNumbers[2] +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				"." +
				cpfCnpjNumbers[5] +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				"/" +
				cpfCnpjNumbers[8] +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10] +
				cpfCnpjNumbers[11] +
				"-" +
				cpfCnpjNumbers[12] +
				cpfCnpjNumbers[13]
			);
		} else {
			return "CPF/CNPJ Inválido";
		}
	}

	function formatPhone(phone) {
		let test = phone.split("");
		if (test.length === 0) {
			return "Nenhum telefone cadastrado";
		} else {
			return (
				"(" +
				test[0] +
				test[1] +
				") " +
				test[2] +
				test[3] +
				test[4] +
				test[5] +
				test[6] +
				" - " +
				test[7] +
				test[8] +
				test[9] +
				test[10]
			);
		}
	}
	return (
		<>
			<Box component="main">
				<Box
					sx={{
						width: "100%",
						minWidth: { xs: "100vw", sm: "66.666667vw", md: "75vw" },
						height: "100%",
						minHeight: "90vh",
						bgcolor: "white",
						m: 0,
						mt: 2,
						borderRadius: 8,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={0}
						sx={{
							display: "flex",
							width: "100%",
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							p: 3,
							overflowY: "auto",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={0}
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid item xs={12} sm={6} md={6}>
									<Box
										sx={{
											display: "flex",
											width: "max-content",
											textAlign: "center",
											flexDirection: "column",
										}}
									>
										<p>
											<b>Logo da Empresa</b>
										</p>
										<Avatar
											alt="Remy Sharp"
											src={
												initialFilesLogo !== null
													? URL.createObjectURL(initialFilesLogo[0])
													: ""
											}
											sx={{
												mx: 1,
												border: "1px solid gray",
												boxShadow: " 0px 9px 10px 0px rgba(0,0,0,0.7)",
												width: 100,
												height: 100,
											}}
										/>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										width: "100%",
										textAlign: "center",
										flexDirection: "column",
										my: 5,
									}}
								>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={0}
										sx={{
											display: "flex",
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Grid
											item
											xs={12}
											sm={6}
											md={6}
											sx={{
												display: "flex",
												justifyContent: "start",
											}}
										>
											<button className={styles.buttonRemovePhotoConfig} 
												onClick={deleteLogo}>
												remover logo
											</button>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											md={6}
											sx={{
												display: "flex",
												justifyContent: "start",
											}}
										>
											<button
												className={styles.buttonConfig}
												onClick={handleOpenLogo}
											>
												alterar logo
											</button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={6} md={6}>
							<p>Nome Fantasia</p>
							<h4>{nameFantasy}</h4>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								width: "100%",
								textAlign: "center",
								flexDirection: "column",
								my: 5,
							}}
						>
							<button
								onClick={handleOpenFantasy}
								className={styles.buttonConfig}
							>
								editar
							</button>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<p>Razão Social</p>
							<h4>{razaoSocial}</h4>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								width: "100%",
								textAlign: "center",
								flexDirection: "column",
								my: 5,
							}}
						>
							<button
								onClick={handleOpenRazaoSocialn}
								className={styles.buttonConfig}
							>
								editar
							</button>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<p>CPF/CNPJ</p>
							<h4>{formatCpfCnpj(cpfCnpj)}</h4>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								width: "100%",
								textAlign: "center",
								flexDirection: "column",
								my: 5,
							}}
						>
							<button
								onClick={handleOpenCpfCnpj}
								className={styles.buttonConfig}
							>
								editar
							</button>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<p>Telefone</p>
							<h4>{formatPhone(phone)}</h4>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								width: "100%",
								textAlign: "center",
								flexDirection: "column",
								my: 5,
							}}
						>
							<button onClick={handleOpenPhone} className={styles.buttonConfig}>
								editar
							</button>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<p>Email</p>
							<h4>{email}</h4>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								width: "100%",
								textAlign: "center",
								flexDirection: "column",
								my: 5,
							}}
						>
							<button onClick={handleOpenEmail} className={styles.buttonConfig}>
								editar
							</button>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openFantasy}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className={styles.registrationInputModal}
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Nome Fantasia</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<input
								type="text"
								value={nameFantasy}
								onChange={(e) => setNameFantasy(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelFantasy}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseNomeFantasia}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openRazaoSocial}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className={styles.registrationInputModal}
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Razão Social</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<input
								type="text"
								value={razaoSocial}
								onChange={(e) => setRazaoSocial(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelRazaoSocia}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseRazaoSocial}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openCpfCnpj}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className={styles.registrationInputModal}
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>CPF/CNPJ</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<InputMask
								name="CPF/CNPJ"
								mask={
									cpfCnpj && cpfCnpj.length > 11
										? "99.999.999/9999-99"
										: "999.999.999-999"
								}
								value={cpfCnpj}
								onChange={(e) => setCpfCnpj(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelCpfCnpj}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseCpfCnpj}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openPhone}
				className={styles.registrationInputModal}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Telefone</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<InputMask
								value={phone}
								placeholder="Telefone"
								mask="(99)99999-9999"
								onChange={(e) => setPhone(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelPhone}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleClosePhone}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openEmail}
				className={styles.registrationInputModal}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Email</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<input
								type="email"
								value={email}
								placeholder={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelEmail}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseEmail}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openLogo}
				className={styles.registrationInputModal}
				onClose={handleCloseCancelLogo}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						className={styles.registrationInputModal}
						display="flex"
						justifyContent="center"
						alignItems="ccenter"
						width={"auto"}
						padding={0}
						margin={0}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p>Alterar Logo</p>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<DropzoneArea
								Icon={CameraAltIcon}
								onChange={(addedFiles) => {
									if (addedFiles.length <= 1) setFilesLogo(addedFiles);
								}}
								acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
								showPreviews={true}
								showPreviewsInDropzone={false}
								filesLimit={1}
								maxFileSize={5000000}
								previewText=" "
								dropzoneText="Faça upload da logo de sua empresa"
								getFileLimitExceedMessage={(filesLimit) =>
									`Quantidade excedida: ${filesLimit} `
								}
								getFileAddedMessage={(fileName) =>
									`Imagem adicionada: ${fileName} `
								}
								getFileRemovedMessage={(fileName) =>
									`Imagem removida: ${fileName} `
								}
								showAlerts={["success", "info"]}
								// initialFiles={initialFiles}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseCancelLogo}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleCloseLogo}
									>
										Cadastrar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}

export default YourPRoperty;
