// export const EVENTS = [

// ];

export const RESOURCES = [
	{
		admin_id: 1,
		title: "Todos",
		color: "#00A69A"
	},
	{
		admin_id: 2,
		title: "Atividades",
		color: "#ab2d2d"
	},
	{
		admin_id: 3,
		title: "Colheitas",
		color: "#58ab2d"
	},
	{
		admin_id: 4,
		title: "Previsão de colheita",
		color: "#a001a2"
	},
];
