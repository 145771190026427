import { PropertyRepository } from "../repository/property_repository";

export class PropertyService {

	constructor() {
		this.propertyRepository = new PropertyRepository();
	}

	async getByUser(userId) {
		const properties = await this.propertyRepository.getByUser(userId);
		return properties;
	}

	async save(property) {
		const savedProperty = await this.propertyRepository.save(property);
		return await this.getById(savedProperty.id);
	}

	async update(property) {
		const savedProperty = await this.propertyRepository.update(property);
		return await this.getById(savedProperty.id);
	}

	async getById(propertyId) {
		const property = await this.propertyRepository.getById(propertyId);
		return property;
	}

	async getByBusiness(businessId) {
		const properties = await this.propertyRepository.getByBusiness(businessId);
		return properties;
	}

	async delete(id) {
		await this.propertyRepository.delete(id);
	}

	async savePlot(plot) {
		return await this.propertyRepository.savePlot(plot);
	}

	async saveOtherArea(otherArea) {
		return await this.propertyRepository.saveOtherArea(otherArea);
	}

	async updateOtherArea(otherArea) {
		return await this.propertyRepository.updateOtherArea(otherArea);
	}

	async updatePlot(plot) {
		return await this.propertyRepository.updatePlot(plot);
	}

	async saveSubdivision(subdivision) {
		return await this.propertyRepository.saveSubdivision(subdivision);
	}

	async updateSubdivision(subdivision) {
		return await this.propertyRepository.updateSubdivision(subdivision);
	}

	async getPlotById(plotId) {
		const plot = await this.propertyRepository.getPlotById(plotId);
		return plot;
	}

	async getPlots(propertyId) {
		const plot = await this.propertyRepository.getPlots(propertyId);
		return plot;
	}

	async deletePlot(plotId) {
		const plot = await this.propertyRepository.deletePlot(plotId);
		return plot;
	}

	async getSubdivisions(propertyId) {
		const subdivisions = await this.propertyRepository.getSubdivisions(propertyId);
		return subdivisions;
	}

	async deleteSubdivision(subdivisionId) {
		const plot = await this.propertyRepository.deleteSubdivision(subdivisionId);
		return plot;
	}

	async deleteOtherArea(otherAreaId) {
		const plot = await this.propertyRepository.deleteOtherArea(otherAreaId);
		return plot;
	}

	async getSubdivisionById(subdivisionId) {
		const plot = await this.propertyRepository.getSubdivisionById(subdivisionId);
		return plot;
	}
}