import axios from "axios";

export class OrderRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async save(order) {
		try {
			const result = await this.axios.post("order", order, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveItem(orderId, orderItem) {
		try {
			const result = await this.axios.post(`order/item/${orderId}`, orderItem, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateItem(orderId, orderItem) {
		try {
			const result = await this.axios.put(`order/item/${orderId}`, orderItem, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getByProperty(propertyId, params) {
		try {
			const result = await this.axios.get(`order/property/${propertyId}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getOrder(id) {
		try {
			const result = await this.axios.get(`order/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getOrderItem(id) {
		try {
			const result = await this.axios.get(`order/item/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async delete(id) {
		try {
			const result = await this.axios.delete(`order/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async changeVisibility(id) {
		try {
			const result = await this.axios.put(`order/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteItem(id) {
		try {
			const result = await this.axios.delete(`order/item/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async changeVisibilityItem(id) {
		try {
			const result = await this.axios.put(`order/item/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateItemAsReturned(item) {
		try {
			const result = await this.axios.put("order/return/item", item, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}