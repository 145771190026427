/* eslint-disable react/prop-types */
import * as Button from "../../components/Buttons";
import React from "react";

//material ul imports
import { IconAdd } from "../IconsImport";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

import "./style.css";
import HelpButton from "../TopMenu/HelpButton";

const styles = {
	bgcolor: "rgba(0, 0, 0, 0)",
	boxShadow: "none",
	width: "100%",
	m: 0,
	p: 0,
};
function SearchHeader(props) {
	return (
		<Box component="main" sx={{ width: "100%" }}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid container xs={props.children ? 12 : 5} sm={props.children ? 12 : 5} md={2}>
					{(props.children && 
						<Grid item xs={2} sm={5}/>
					)}
					<Grid item xs={6} sm={props.children ? 2 : 6} md={11}>
						<Item sx={styles}>
							{props.title !== undefined ? (
								<h2>{props.title}</h2>
							) : (
								props.headerComponents
							)}
						</Item>
					</Grid>
					<Grid item xs={props.children ? 1 : 6} sm={props.children ? 1 : 3} md={1}>
						<HelpButton
							verticalAnchorOrigin="bottom"
							horizontalAnchorOrigin="center"
							verticalTransformOrigin="top"
							horizontalTransformOrigin="center"
							help={props.help}
						/>
					</Grid>
				</Grid>
				{(props.children && 
				<Grid item xs={8} sm={9} md={8}>
					<Item sx={styles}>{props.children}</Item>
				</Grid>)}
				{(!props.children && 
					<Grid item xs={1} sm={4} md={8} />
				)}
				<Grid item xs={4} sm={3} md={2}>
					<Item sx={styles}>
						{props.button === undefined ? (
							props.textButton !== undefined ? (
								<>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={styles}>
												<Button.GenericButtonGreen
													href={props.href}
													funcClick={props.funcClick}
													text={props.textButton}
													icon={
														props.icon !== undefined ? props.icon : <IconAdd />
													}
												/>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={styles}>{props.extraButton}</Item>
										</Grid>
									</Grid>
								</>
							) : (
								""
							)
						) : (
							props.button
						)}
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}

export default SearchHeader;
