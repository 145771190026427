/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { ReportService } from "../../../../service/report_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "16px",
		marginBottom: "3px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
		textTransform: "uppercase",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfVendas = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const clienteID = props.clienteId;
	const culturaID = props.culturaId;
	const logo = props.logo;
	
	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			customer: String(clienteID) !== "-1" ? clienteID : null,
			propertyFarm: String(culturaID) !== "-1" ? culturaID : null,
			property: propertyId,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const activityData = await reportService.getSalesData(reportProps);

		setData(activityData);
		setLoading(false);
	};

	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>Vendas</Text>
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>Cliente:</Text>
							<Text style={styles.textNormal}>
								{data.cliente?.nome ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Produto:</Text>
							<Text style={styles.textNormal}>
								{data.produto?.nome ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Período:</Text>
							<Text style={styles.textNormal}>
								{new Date(props.startDate).toLocaleDateString("pt-BR")} a{" "}
								{new Date(props.finalDate).toLocaleDateString("pt-BR")}
							</Text>
						</View>
					</View>

					{data.dados.map((item, index) => (
						<View style={styles.body} key={index}>
							<View style={styles.mainTitle}>
								<Text>{item.title}</Text>
								<Text style={{ fontSize: "10px" }}>
									total comercializado: {item.total}
								</Text>
							</View>

							<View style={styles.content}>
								<View style={styles.tableTitle}>
									<Text style={styles.larguraTexto}>data</Text>
									<Text style={styles.larguraTexto}>cód. venda</Text>
									<Text style={styles.larguraTexto}>produto</Text>
									<Text style={styles.larguraTexto}>preço</Text>
									<Text style={styles.larguraTexto}>Quantidade</Text>
									<Text style={styles.larguraTexto}>
										Quantidade por Embalagem
									</Text>
									<Text style={styles.larguraTexto}>unidade</Text>
									<Text style={styles.larguraTexto}>nº de vol.</Text>
								</View>

								{item.vendas.map((i, indx) => (
									<View key={indx} style={styles.tableContent}>
										<Text style={styles.larguraTexto}>{i.data}</Text>
										<Text style={styles.larguraTexto}>{i.codigo}</Text>
										<Text style={styles.larguraTexto}>{i.produto}</Text>
										<Text style={styles.larguraTexto}>{i.preco}</Text>
										<Text style={styles.larguraTexto}>{i.quantidade}</Text>
										<Text style={styles.larguraTexto}>
											{i.quantidadePorEmbalagem}
										</Text>
										<Text style={styles.larguraTexto}>{i.unidade}</Text>
										<Text style={styles.larguraTexto}>{i.volumes}</Text>
									</View>
								))}
							</View>
						</View>
					))}

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};

export default PdfVendas;
