import React from "react";
import { Chart } from "react-google-charts";

function Charts(props) {
	return (
		<Chart
			chartType={props.chartType}
			width="100%"
			height="100%"
			data={props.data}
			options={props.options}
		/>
	);
}
export default Charts;
