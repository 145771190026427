import { AuthRepository } from "../repository/auth_repository";

export class AuthService {

	constructor() {
		this.authRepository = new AuthRepository();
	}

	async authenticate(username, password) {
		const accessToken = await this.authRepository.authenticate(username, password);

		localStorage.setItem("access_token", accessToken);
	}

	async getProfile() {
		const profile = await this.authRepository.getProfile();
		localStorage.setItem("user", JSON.stringify(profile));
		return profile;
	}

	async sendResetPasswordToken(username) {
		return await this.authRepository.sendResetPasswordToken(username);
	}

	async resetPasswordWithToken(body) {
		return await this.authRepository.resetPasswordWithToken(body);
	}
}