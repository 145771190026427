import axios from "axios";

export class LossRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async save(loss) {
		try {
			const result = await this.axios.post("loss", loss, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async update(loss) {
		try {
			const result = await this.axios.put("loss", loss, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async delete(id) {
		try {
			const result = await this.axios.delete(`loss/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async changeVisibility(id) {
		try {
			const result = await this.axios.put(`loss/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
	
	async getByProperty(propertyId, params) {
		try {
			const result = await this.axios.get(`loss/property/${propertyId}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getAllLossTypes() {
		try {
			const result = await this.axios.get("loss/type/all", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getById(lossId) {
		try {
			const result = await this.axios.get(`loss/${lossId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}