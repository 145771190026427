/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Chart from "../../Chart";
// import "./style.css";
import styles from "./CadernoCampo.module.css";
// import ButtonExportChart from "../../ButtonExportChart";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Button, Grid, Menu, MenuItem, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import * as ExcelJS from "exceljs";
import { CSVLink } from "react-csv";
// import { csvReport } from "./dataCsvCadenoDeCampo.js";

import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Modal,
	Slide,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import HelpButtonInput from "../../../components/HelpButtonInput";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import InputData from "../../InputData";

import { CropSeasonService } from "../../../service/crop_season_service";
import { BusinessService } from "../../../service/business_service";
import { PropertyService } from "../../../service/property_service";
import { UserService } from "../../../service/user_service";
import { ReportService } from "../../../service/report_service";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
	maxHeight: "61vh",
	overflowY: "auto"
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const headCells = [
	{
		id: "nome",
		label:"Nome"
	},
	{
		id: "data_plantio",
		label: "Data Plantio"
	}
];
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function CadernoCampo(props) {
	const [allSafras, setAllSafras] = useState([]);
	const [selectedSafra, setSelectedSafra] = useState(-1);
	const [data, setData] = useState([
		["ATIVIDADE", "QUANTIDADE", { role: "style" }],
		["SERVIÇOS", 0, "red"],
		["INSUMOS", 0, "blue"],
		["COLHEITA", 0, "green"],
	]);
	const [csvData, setCsvData] = useState(null);
	let headerData = [];
	const [periodo, setPeriodo] = useState(2);
	const [disable, setDisable] = useState(false);
	const [inputDisabled, setInputDisabled] = useState(true);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [openSafraDialog, setOpenSafraDialog] = useState(false);
	const [openSafra, setOpenSafra] = useState(false);
	const [idRowSafra, setIdRowSafra] = useState(null);
	const dataNow = getCurrentDate();
	
	const [safraNome, setSafraNome] = useState();
	const [area, setArea] = useState();
	const [propriedadeNome, setPropriedadeNome] = useState();
	const [user, setUser] = useState(null);
	const [company, setCompany] = useState(null);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");
	const keysForActiveSafra = headCells; //array de chaves
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("cuture");

	const cropSeasonService = new CropSeasonService();
	const businessService = new BusinessService();
	const propertyService = new PropertyService();
	const reportService = new ReportService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
	};
	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async ()  => {
		const propertyId = localStorage.getItem("propertyId");
		let cropSeasons = await cropSeasonService.get(propertyId, {limit: 100000});
		cropSeasons = cropSeasons.cropsSeason;
		getSafrasWithDetails(cropSeasons).then((safras) => {
			setAllSafras(safras);
			setSelectedSafra(-1);
		});
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);
		const business = businessService.getById(user.empresaId);
		setCompany(business);
		fetchNotebookFieldData();
		fetchNotebookFieldChartData();
	};

	const fetchNotebookFieldData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		if (allSafras.length > 0) {
			let startDate = getStartDateIncio();
			let finalDate = getFinalDateFinal();
			let currentDate = new Date();
			let currentMonth = currentDate.getMonth() + 1;
			if (finalDate === null) {
				finalDate = `${currentDate.getFullYear()}-${currentMonth
					.toString()
					.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
			}
			const params = {
				cropSeason: String(selectedSafra) !== "-1" ? allSafras[selectedSafra].id : null,
				propertyId: propertyId,
				startDate,
				finalDate,
				withValues: true,
			};
			const notebookFieldData = await reportService.getFieldNotebookData(params);
			setCsvData(notebookFieldData);
		}
	};

	useEffect(() => {
		fetchNotebookFieldData();
		fetchNotebookFieldChartData();
	}, [allSafras, selectedSafra, periodo, personalizedFinalDate, personalizedStartDate,selectedDateInicio,selectedDateFim]);

	const fetchNotebookFieldChartData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		if (allSafras.length > 0) {
			let startDate = getStartDateIncio();
			let finalDate = getFinalDateFinal();
			let currentDate = new Date();
			let currentMonth = currentDate.getMonth() + 1;
			if (finalDate === null) {
				finalDate = `${currentDate.getFullYear()}-${currentMonth
					.toString()
					.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
			}
			const params = {
				cropSeason: String(selectedSafra) !== "-1" ? allSafras[selectedSafra].id : null,
				propertyId: propertyId,
				startDate,
				finalDate,
				withValues: true,
			};
			const notebookFieldData = await reportService.getFieldNotebookGraphicData(params);
			setDataForPeriod(notebookFieldData);
		}
	};

	function getCurrentDate() {
		let newDate = new Date();
		let date_raw = newDate.getDate();
		let month_raw = newDate.getMonth() + 1;
		let year = newDate.getFullYear();
		var date, month;

		if (date_raw < 10) date = "0" + date_raw.toString();
		else date = date_raw.toString();
		if (month_raw < 10) month = "0" + month_raw.toString();
		else month = month_raw.toString();

		return `${date}-${month}-${year}`;
	}
	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		if (periodo <= 4) {
			setPersonalizedStartDate(null);
			setSelectedDateInicio("");
			if (periodo === 1) {
				let startMonth =
					currentMonth < 2 ? 12 - (1 - currentMonth) : currentMonth - 1;
				return `${currentDate.getDate().toString().padStart(2, "0")}/${startMonth
					.toString()
					.padStart(2, "0")}/${
					currentMonth < 2
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}`;
			} else if (periodo === 2) {
				let startMonth =
					currentMonth < 4 ? 12 - (3 - currentMonth) : currentMonth - 3;
				return `${currentDate.getDate().toString().padStart(2, "0")}/${startMonth
					.toString()
					.padStart(2, "0")}/${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}`;
			} else if (periodo === 3) {
				let startMonth =
					currentMonth < 7 ? 12 - (6 - currentMonth) : currentMonth - 6;
				return `${currentDate.getDate().toString().padStart(2, "0")}/${startMonth
					.toString()
					.padStart(2, "0")}/${
					currentMonth < 7
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}`;
			} else if (periodo === 4) {
				return `${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
					.toString()
					.padStart(2, "0")}/${currentDate.getFullYear() - 1}`;
			}
		}
		return (
			selectedDateInicio ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		if (periodo <= 4) {
			setPersonalizedFinalDate(null);
			setSelectedDateFim("");
			return `${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`;
		}
		return (
			selectedDateFim ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	const getStartDateIncio = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		if (periodo <= 4) {
			setPersonalizedStartDate(null);
			setSelectedDateInicio("");
			if (periodo === 1) {
				let startMonth =
					currentMonth < 2 ? 12 - (1 - currentMonth) : currentMonth - 1;
				return `${
					currentMonth < 2
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth.toString().padStart(2, "0")}-${currentDate
					.getDate()
					.toString()
					.padStart(2, "0")}`;
			} else if (periodo === 2) {
				let startMonth =
					currentMonth < 4 ? 12 - (3 - currentMonth) : currentMonth - 3;
				return `${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth.toString().padStart(2, "0")}-${currentDate
					.getDate()
					.toString()
					.padStart(2, "0")}`;
			} else if (periodo === 3) {
				let startMonth =
					currentMonth < 7 ? 12 - (6 - currentMonth) : currentMonth - 6;
				return `${
					currentMonth < 7
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth.toString().padStart(2, "0")}-${currentDate
					.getDate()
					.toString()
					.padStart(2, "0")}`;
			} else if (periodo === 4) {
				return `${currentDate.getFullYear() - 1}-${currentMonth
					.toString()
					.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
			}
		}
		const data = selectedDateInicio.split("/");
		const dataR = `${data[2]}-${data[1]}-${data[0]}`;
		return (	
			dataR ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const getFinalDateFinal = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		if (periodo <= 4) {
			setPersonalizedFinalDate(null);
			setSelectedDateFim("");
			return `${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
		}
		const data = selectedDateFim.split("/");
		const dataR = `${data[2]}-${data[1]}-${data[0]}`;
		return (
			dataR ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const setDataForPeriod = (notebookFieldData) => {
		let newData = [data[0]];
		newData.push(["APLICAÇÃO DE SERVIÇO", notebookFieldData.serviceQuantity, "red"]);
		newData.push(["APLICAÇÃO DE INSUMO", notebookFieldData.inputQuantity, "blue"]);
		newData.push(["COLHEITA", notebookFieldData.harvestQuantity, "green"]);
		setData(newData); 
	};

	const getSafrasWithDetails = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			const farm = cropSeason.propriedadeCultivo;
			const plot = cropSeason.talhao;

			const cultivation = farm.cultivo;
			const subDiv = cropSeason?.subdivisao;

			const catSub = subDiv?.categoriaSubdivisao;
			safras.push({
				id: cropSeason.id,
				safra: cropSeason,
				nome: `Talhão ${plot.nome}${
					subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
			});
		}
		return safras;
	};
	let dataAllSafras = [];

	dataAllSafras.push({
		id: -1,
		nome: "Remover",
		data_plantio: "-",
		index: -1,
	});
	// const dataAllSafras = allSafras.map((item, index) => {
	// 	return {
	// 		id: item.id,
	// 		nome: item.nome,
	// 		data_plantio: item.safra.data_plantio,
	// 		index: index,
	// 	};
	// });
	const formatDate = (stringDate) => {
		const date = new Date(stringDate);
		const formatedDate =
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear();
		return formatedDate;
	};
	allSafras.map((item, index) => {
		return dataAllSafras.push({
			id: item.id,
			nome: item.nome,
			data_plantio: formatDate(item.safra.dataPlantio),
			index: index,
		});
	});
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = async (index) => {
		let itemsQuantity = 0;
		console.log(data);
		itemsQuantity += data[1][1];
		itemsQuantity += data[2][1];
		itemsQuantity += data[3][1];
		if(itemsQuantity <= 2000){
			if (index === "0") {
				return (window.location.href = `/pdf/?pag=CadernoCampoCusto&index=${
					props.index
				}&safraId=${
					selectedSafra !== -1 ? allSafras[selectedSafra].id : -1
				}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`);
			}
			if (index === "1") {
				return (window.location.href = `/pdf/?pag=CadernoCampo&index=${
					props.index
				}&safraId=${
					selectedSafra !== -1 ? allSafras[selectedSafra].id : -1
				}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`);
			}
			if (index === "2") {
				// return (window.location.href = `/pdf/?pag=CadernoCampo&index=${props.index}`);
			}
		} else {
			const propertyId = localStorage.getItem("propertyId");
			let params = {
				cropSeason: String(selectedSafra) !== "-1" ? selectedSafra : null,
				propertyId: propertyId,
				startDate: getStartDateIncio(),
				finalDate: getFinalDateFinal(),
				showValue: "n"
			};
			if (index === "0")
				params.showValue = "s";
			const pdf = await reportService.getFieldNotebookPDF(params);
			const blob = new Blob([pdf]);
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `RelatorioCadernoDeCampo_${dataNow}.pdf`); // Nome do arquivo
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		}

		setAnchorEl(null);
	};

	function periodoPersonalizado(e) {
		setPeriodo(+e);

		if (+e === 5) setInputDisabled(false);
		else setInputDisabled(true);
	}

	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setOpenSafra(false); //fecha o modal
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const handleOpenSafraDialog = (e) => {
		if (e.target.id !== undefined) setIdRowSafra(e.target.id); //seta o id do elemento clicado para abrir o dialog

		setOpenSafraDialog(true); //abre o modal
	};
	
	const handleCloseSafraDialog = () => {
		setOpenSafraDialog(false); //fecha o modal
	};
	const handleCloseAddSafra = () => {
		if (idRowSafra !== "-1") {
			setSafraNome(allSafras[idRowSafra].nome);
			setSafra(idRowSafra);
			setOpenSafra(false); //fecha o modal
			setOpenSafraDialog(false);
		} else {
			setOpenSafra(false); //fecha o modal
			setOpenSafraDialog(false);
			setSafraNome("");
			setSafra(-1);
			setData([
				["ATIVIDADE", "QUANTIDADE", { role: "style" }],
				["SERVIÇOS", 0, "red"],
				["INSUMOS", 0, "blue"],
				["COLHEITA", 0, "green"],
			]);
		}
	};

	function setSafra(safraId) {
		setSelectedSafra(safraId);
	}
	
	headerData.push(
		{
			label: `${csvData?.area?.nome ?? "Todas as áreas"} | ${csvData?.safra?.nome ?? "Todas as safras"}  `,
			key: "coluna1",
		},
		{ label: "", key: "coluna2" },
		{ label: "", key: "coluna3" },
		{
			label: `Propriedade: ${csvData?.propriedade?.nome ?? "Não informado"} | Usuario: ${
				user?.name ?? "Não informado"
			} | Empresa: ${csvData?.empresa?.nome ?? "Não informado"}`,
			key: "coluna4",
		},
		{ label: "", key: "coluna5" },
		{ label: "", key: "coluna6" },
		{ label: "", key: "coluna7" },
		{ label: "", key: "coluna8" }
	);

	let headerExcel = [];
	headerExcel.push(
		{
			header: `${csvData?.area?.nome ?? "Todas as áreas"} | ${csvData?.safra?.nome}  `,
			key: "coluna1",
		},
		{ header: "", key: "coluna2" },
		{ header: "", key: "coluna3" },
		{
			header: `Propriedade: ${csvData?.propriedade?.nome} | Usuario: ${
				user?.name ?? "Não informado"
			} | Empresa: ${csvData?.empresa?.nome ?? "Não informado"}`,
			key: "coluna4",
		},
		{ header: "", key: "coluna5" },
		{ header: "", key: "coluna6" },
		{ header: "", key: "coluna7" },
		{ header: "", key: "coluna8" }
	);
	function dataCadernoCampo() {
		let newData = [];
		if(csvData) {
			let totalInsumos = 0;
			let totalServicos = 0;
			if (Object.keys(csvData.produtos).length > 0) {
				newData.push(
					{
						coluna1: "",
						coluna2: "",
						coluna3: "",
						coluna4: "PRODUTOS APLICADOS",
						coluna5: "",
						coluna6: "",
						coluna7: "",
						coluna8: "",
					},
					{
						coluna1: "Grupo",
						coluna2: "Data",
						coluna3: "Produto",
						coluna4: "Quantidade",
						coluna5: "Medida",
						coluna6: "Modo",
						coluna7: "Valor unitario",
						coluna8: "Valor total",
					}
				);

				Object.keys(csvData.produtos).forEach((key) => {
					const productGroup = csvData.produtos[key];
					for (let i = 0; i < productGroup.dados.length; i++) {
						const item = productGroup.dados[i];
						newData.push({
							coluna1: key,
							coluna2: item.data,
							coluna3: item.produto,
							coluna4: item.quantidade,
							coluna5: item.unidade,
							coluna6: item.modo,
							coluna7: Number(item.vlrUnit?.toFixed(2)),
							coluna8: Number(item.vlrTotal?.toFixed(2)),
						});
						totalInsumos += Number(item.vlrTotal);
					}
				});

				newData.push({
					coluna1: "",
					coluna2: "",
					coluna3: "",
					coluna4: "",
					coluna5: "",
					coluna6: "TotalProdutos Aplicados:",
					coluna7: "",
					coluna8: totalInsumos,
				});
			}

			if (Object.keys(csvData.atividades).length > 0) {
				newData.push(
					{
						coluna1: "",
						coluna2: "",
						coluna3: "",
						coluna4: "",
						coluna5: "",
						coluna6: "",
						coluna7: "",
						coluna8: "",
					},
					{
						coluna1: "",
						coluna2: "",
						coluna3: "",
						coluna4: "ATIVIDADES REALIZADAS",
						coluna5: "",
						coluna6: "",
						coluna7: "",
						coluna8: "",
					},
					{
						coluna1: "Grupo",
						coluna2: "Data",
						coluna3: "Hora",
						coluna4: "Serviço",
						coluna5: "Duração",
						coluna6: "Operador",
						coluna7: "Valor unitario",
						coluna8: "Valor total",
					}
				);

				Object.keys(csvData.atividades).forEach((key) => {
					const serviceGroup = csvData.atividades[key];
					for (let i = 0; i < serviceGroup.dados.length; i++) {
						const item = serviceGroup.dados[i];
						newData.push({
							coluna1: key,
							coluna2: item.data,
							coluna3: item.horaAplicacao,
							coluna4: item.servico,
							coluna5: item.duracao,
							coluna6: item.operador,
							coluna7: Number(item.vlrUnit?.toFixed(2)),
							coluna8: Number(item.vlrTotal?.toFixed(2)),
						});
						totalServicos += Number(item.vlrTotal);
					}
				});

				newData.push({
					coluna1: "",
					coluna2: "",
					coluna3: "",
					coluna4: "",
					coluna5: "",
					coluna6: "Total Atividades Realizadas:",
					coluna7: "",
					coluna8: totalServicos,
				});
			}
			newData.push(
				{
					coluna1: "",
					coluna2: "",
					coluna3: "",
					coluna4: "",
					coluna5: "",
					coluna6: "",
					coluna7: "",
					coluna8: "",
				},
				{
					coluna1: "",
					coluna2: "",
					coluna3: "",
					coluna4: "",
					coluna5: "",
					coluna6: "",
					coluna7: "Total Geral:",
					coluna8: (totalInsumos + totalServicos),
				}
			);

			if (csvData.colheitas.length > 0) {
				newData.push(
					{
						coluna1: "",
						coluna2: "",
						coluna3: "",
						coluna4: "",
						coluna5: "",
						coluna6: "",
					},
					{
						coluna1: "",
						coluna2: "",
						coluna3: "REGISTRO DE COLHEITA",
						coluna4: "",
						coluna5: "",
						coluna6: "",
					},
					{
						coluna1: "Grupo",
						coluna2: "Data",
						coluna3: "Operador",
						coluna4: "Lote",
						coluna5: "Quantidade",
					}
				);

				for (let i = 0; i < csvData.colheitas.length; i++) {
					const item = csvData.colheitas[i];
					newData.push({
						coluna1: "Colheita",
						coluna2: item.data,
						coluna3: item.operador,
						coluna4: item.lote,
						coluna5: Number(item.quantidade),
					});
				}
			}
		}

		return newData;
	}

	function ExcelExportButton() {
		const handleExport = () => {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet("Caderno de Campo");

			// Chame a função para obter os dados
			const data = dataCadernoCampo();

			// Defina os cabeçalhos das colunas
			worksheet.columns = headerExcel;

			// Adicione os dados na planilha
			worksheet.addRows(data);

			// Crie o arquivo Excel
			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `RelatorioCadernoDeCampo${dataNow}.xlsx`;
				a.click();
			});
		};

		return (
			<MenuItem id={3} onClick={handleExport}>
				Exportar para Excel
			</MenuItem>
		);
	}
	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);
	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={8} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "100%",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Safra: </p>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={1}
												sx={{
													p: 0,
													display: "flex",
													width: "100%",
													maxWidth: "350px",
												}}
											>
												<Grid item xs={12} sm={6} md={5}>
													<button
														onClick={handleOpenSafra}
														type="button"
														className={disable ? styles.ButtonCancelar : styles.ButtonColher}
														disabled={disable}
													>
														Selecionar Safra
													</button>
												</Grid>
												<Grid item xs={12} sm={6} md={7}>
													<select
														id="safra"
														onChange={(e) => setSelectedSafra(e.target.value)}
														value={!disable ? selectedSafra : -1}
														className={styles.SelectSafra}
														disabled
													>
														<option value={-1}>Safra</option>
														{allSafras.map((item, index) => {
															return (
																<option value={index} key={index}>
																	{item.nome}
																</option>
															);
														})}
													</select>
												</Grid>
												<Grid
													item
													xs={12}
													sx={{
														display: "flex",
														justifyContent: "start",
														textAlign: "center",
													}}
												>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="allSelect"
															id="allSafraCheckbox"
															onChange={() => setDisable(!disable)}
														/>
														<label htmlFor="allSafraCheckbox" className={styles.labelTitle}>
															<b>Selecionar todas as Safras</b>
														</label>
													</div>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Período:</p>
											<select
												className={styles.Select}
												onChange={(e) => periodoPersonalizado(e.target.value)}
												value={periodo}
											>
												<option value={1}>1 Mes</option>
												<option value={2}>3 Messes</option>
												<option value={3}>6 Messes</option>
												<option value={4}>1 Ano</option>
												<option value={5}>Personalizado</option>
											</select>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p
												className={inputDisabled ? styles.textButtonOff : styles.textButton}
											>
												Data de início:
											</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={inputDisabled ? styles.ButtonOff : styles.Button}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p
												className={inputDisabled ? styles.textButtonOff : styles.textButton}
											>
												Data de finalização:
											</p>
											<InputData
												classN={inputDisabled ? styles.ButtonOff : styles.Button}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<Button
										id="basic-button"
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										className={
											styles.ButtonExportChart
										}
										onClick={handleClick}
									>
										<IosShareIcon /> Exportar
										{open ? <ExpandLess /> : <ExpandMore />}
									</Button>
									<Menu
										id="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem id={0} onClick={(e) => handleClose(e.target.id)}>
											PDF - com custos
										</MenuItem>
										<MenuItem id={1} onClick={(e) => handleClose(e.target.id)}>
											PDF - sem custos
										</MenuItem>

										<CSVLink
											data={dataCadernoCampo()}
											headers={headerData}
											className={styles.textDropdown}
											filename={`RelatorioCadernoDeCampo${dataNow}.csv`}
											lineSeparator={"\n"}
											encoding="ANSI"
										>
											<MenuItem id={2}>Planilha</MenuItem>
										</CSVLink>
										{ExcelExportButton()}
									</Menu>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<Chart data={data} options={props.options} chartType="ColumnChart" />
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								minHeight: "50vh",
							}}
						>
							<Chart data={data} options={props.options} chartType="PieChart" />
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openSafra}
				onClose={handleCloseSafra}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Safra</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Safras"}
									text={"Clique em cima do item desejado para seleciona-lo."}
								/>
							</div>
							<Paper sx={{ width: "100%", p: 0, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer>
										<Table stickyHeader>
											<EnhancedTableHead
												className={styles.tableGenerator}
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onSelectAllClick={handleSelectAllClicks}
												onRequestSort={handleRequestSort}
												rowCount={dataAllSafras.length}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRows
														.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{keysForActiveSafra.map((column) => {
																		return (
																			<TableCell
																				key={column.id}
																				id={row.index}
																				onClick={handleOpenSafraDialog}
																			>
																				{row[column.id]}
																			</TableCell>
																		);
																	})}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRows.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
									pt: 2,
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseSafra}
										className={styles.ButtonCancelarModal}
									>
										Cancelar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Dialog
						open={openSafraDialog}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleCloseSafraDialog}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Deseja Selecionar esse item?
							</DialogContentText>
						</DialogContent>
						<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
							<button
								className={styles.ButtonCancelarModal}
								onClick={handleCloseSafraDialog}
							>
								Cancelar
							</button>

							<button
								className={styles.ButtonColherModal}
								onClick={handleCloseAddSafra}
							>
								Selecionar
							</button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		</Box>
	);
}

export default CadernoCampo;
