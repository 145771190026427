import { OccurrencesRepository } from "../repository/occurences_repository";

export class OccurrencesService {

	constructor() {
		this.occurencesRepository = new OccurrencesRepository();
	}

	async save(occurences) {
		const savedOccurrences = await this.occurencesRepository.save(occurences);
		return savedOccurrences;
	}

	async update(occurences) {
		const updatedOccurrences = await this.occurencesRepository.update(occurences);
		return updatedOccurrences;
	}

	async changeVisibility(id) {
		await this.occurencesRepository.changeVisibility(id);
	}

	async getByProperty(propertyId, params) {
		const occurencesData = await this.occurencesRepository.getByProperty(propertyId, params);
		return occurencesData;
	}

	async getByOccurrencesCropSeason(propertyId, params) {
		const occurencesData = await this.occurencesRepository.getByOccurrencesCropSeason(propertyId, params);
		return occurencesData;
	}

	async deleteIncidentCropSeason(cropSeasonId,incidentId) {
		const occurencesData = await this.occurencesRepository.deleteIncidentCropSeason(cropSeasonId,incidentId);
		return occurencesData;
	}

	async getById(occurencesId) {
		const occurences = await this.occurencesRepository.getById(occurencesId);
		return occurences;
	}

	async getImages(occurencesId) {
		const occurences = await this.occurencesRepository.getImages(occurencesId);
		return occurences;
	}

	async getAllCategories() {
		const occurences = await this.occurencesRepository.getAllCategories();
		return occurences;
	}

	async getAllTypes(params) {
		const occurences = await this.occurencesRepository.getAllTypes(params);
		return occurences;
	}

	async delete(occurencesId) {
		const occurences = await this.occurencesRepository.delete(occurencesId);
		return occurences;
	}
}