import axios from "axios";

export class ManagementRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async save(management) {
		try {
			const result = await this.axios.post("management", management, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async update(management) {
		try {
			const result = await this.axios.put("management", management, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async delete(id) {
		try {
			const result = await this.axios.delete(`management/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async changeVisibility(id) {
		try {
			const result = await this.axios.put(`management/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
	
	async getByProperty(propertyId, params) {
		try {
			const result = await this.axios.get(`management/property/${propertyId}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getById(managementId) {
		try {
			const result = await this.axios.get(`management/${managementId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}