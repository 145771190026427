import React, { useEffect, useState } from "react";

import Seta from "../../../../Assets/images/seta.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import JsBarcode from "jsbarcode";
import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { OrderService } from "../../../../service/order_service";
import { PropertyService } from "../../../../service/property_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingLeft: "10px",
		paddingRight: "10px"
	},
	body: {
		marginTop: "5px",
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	topEtiquetaContainer: {
		width: "100%",
		height: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		padding: "0",
		margin: "0",
	},
	logoEmpressaContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "50px",
		width: "90px",
	},
	codBarContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	bottonEtiquetaContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "start",
		justifyContent: "start",
		flexDirection: "column",
	},
	bottonRightTextEtiquetaContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
	},
	containerQrcode: {
		width: "100px",
		height: "300px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: 0,
		marginTop: "105px",
		padding: 0,
	},
	qrCode: {
		width: "55px",
		height: "55px",
	},
	barCode: {
		height: "25px",
		width: "90%",
	},
	qrCodeContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: "black",
	},
	qrCodeContainerTexTitle: {
		fontSize: "84px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	qrCodeContainerTextSubTitle: {
		fontSize: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "20px",
		bottom: 0,
	},

	containerFooter: {
		width: "100vw",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		width: "100%",
		backgroundColor: "black",
		height: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "8px",
		paddingHorizontal: "5px",
		fontFamily: "Roboto bold",
		color: "white",
	},
	imgFooterInvetida: {
		height: "100%",
		width: "auto",
		transform: "scaleX(-1)",
	},
	imgFooter: {
		height: "100%",
		width: "auto",
	},
	// textTransform
	TextBoldTitle: {
		fontFamily: "Roboto bold",
		fontSize: "6px",
	},
	TextBoldNumber: {
		fontFamily: "Roboto bold",
		fontSize: "12px",
	},
	TextBoldTitleRight: {
		fontFamily: "Roboto bold",
		fontSize: "6px",
	},
	TextBoldTitleNumber: {
		fontFamily: "Roboto bold",
		fontSize: "14px",
	},
	TextBoldSubTitle: {
		fontFamily: "Roboto bold",
		fontSize: "6px",
	},
	TextBold: {
		fontFamily: "Roboto bold",
		fontSize: "8px",
	},
	TextNormal: {
		fontSize: "5px",
	},
	TextNormalQrCode: {
		fontSize: "4px",
	},
});

const PdfHangTags12x5 = (props) => {
	const orderItemHarvestId = props.item;
	const orderItemId = props.pedido;
	const [property, setProperty] = useState(null);
	const [orderItem, setOrderItem] = useState(null);
	const [harvest, setHarvest] = useState(null);
	const [barCodeValor, setBarCodeValor] = useState("000000000000");
	const [loading, setLoading] = useState(true);
	const barCodeType =
		props.codBar1Type !== null ? props.codBar1Type : props.codBar2Type;

	const orderService = new OrderService();
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const formatCpfCnPJ = (value) => {
		if (value.length > 11) {
			value =
				value.substring(0,2) +
				"." +
				value.substring(2, 5) +
				"." +
				value.substring(5, 8) +
				"/" +
				value.substring(8, 12) +
				"." +
				value.substring(12);
		} else {
			value =
				value.substring(0,3) +
				"." +
				value.substring(3,6) +
				"." +
				value.substring(6,9) +
				"-" +
				value.substring(9);
		}
		return value;
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const propertyData = await propertyService.getById(propertyId);
		setProperty(propertyData);

		const orderItemData = await orderService.getOrderItem(orderItemId);
		setOrderItem(orderItemData);

		const harvestData = orderItemData.lotes.find((harvestItem) => harvestItem.loteId === orderItemHarvestId);
		setHarvest(harvestData);

		const barCodeCustomer = harvestData.lote.propriedadeCultivo.codigoDeBarraCultivoClientes.find((barCodeCustomerItem) => barCodeCustomerItem.clienteId === orderItemData.pedido.cliente.id);

		if (barCodeType === "CODE128") {
			if (harvestData.lote.propriedadeCultivo.codigoDeBarra.length < 12) {
				let codeValor = harvestData.lote.propriedadeCultivo.codigoDeBarra.padStart(12, "0");
				setBarCodeValor(codeValor);
			} else {
				setBarCodeValor(harvestData.lote.propriedadeCultivo.codigoDeBarra);
			}
		} else if (barCodeCustomer){
			if (barCodeCustomer.codigoDeBarra.length < 12) {
				let codeValor = barCodeCustomer.codigoDeBarra.padStart(12, "0");
				setBarCodeValor(codeValor);
			} else {
				setBarCodeValor(barCodeCustomer.codigoDeBarra);
			}
		}

		setLoading(false);
	};

	const barCodeCanvas = document.createElement("canvas");
	JsBarcode(barCodeCanvas, barCodeValor, {
		format: barCodeType, //
		width: 3,
		fontSize: 16,
		height: 120,
	});
	const barcode = barCodeCanvas.toDataURL();

	function checkValid() {
		return props.checkDist === "0" ? true : false;
	}

	function checkDoubleTag() {
		return props.doubleTag === "0" ? true : false;
	}
	//qrcode
	const ids = props.ids;
	function QRCodePage({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();

		return (
			<Page 
				size={{ width: 340.15, height: checkDoubleTag() ? 283.32 : 141.66 }}
				style={styles.page}
			>
				<View style={{height: checkDoubleTag() ? "50%" : "100%"}}>
					<View style={styles.body}>
						<View style={styles.qrCodeContainer}>
							{/* top etiqueta */}
							<View style={styles.topEtiquetaContainer}>
								<View style={{ height: "50x" }}>
									<View style={styles.logoEmpressaContainer}></View>
								</View>
								<View style={styles.codBarContainer}>
									<Text
										style={{
											alignItems: "center",
											justifyContent: "center",
											textTransform: "uppercase",
										}}
									>
										{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}
									</Text>
								</View>
								<View style={styles.containerQrcode}>
									<Text style={styles.TextNormalQrCode}>CONHEÇA ORIGEM</Text>
									<Text style={styles.TextNormalQrCode}>DESTE PRODUTO</Text>
									<Image src={dataUrl} style={styles.qrCode} />
									{barCodeType !== null && <Image src={barcode} style={styles.barCode} />}

									<Text style={styles.TextNormalQrCode}>POR: SISAGRI</Text>
								</View>
							</View>
							{/* botton etiqueta */}
							<View style={styles.bottonEtiquetaContainer}>
								{/* esquerda */}
								<View style={{ marginBottom: "2px" }}>
									<Text style={styles.TextBold}>
										Peso liquido Mínimo: <Text style ={styles.TextBoldNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
									</Text>
									<Text style={styles.TextBold}>Embalagem: {orderItem?.embalagem.nome}</Text>
									<Text style={styles.TextBold}>
										Código de Rastreio: {`${orderItem?.codigo}-${harvest.lote.codigo}`}
									</Text>
								</View>

								<View style={{ marginBottom: "2px" }}>
									<Text style={styles.TextNormal}>Produzido por {property.nome}</Text>
									<Text style={styles.TextNormal}>
										{property.endereco.endereco + 
											" " + property.endereco.bairro + 
											" - " + property.endereco.cidade}
									</Text>
									<Text style={styles.TextNormal}>
										{property.cpf_cnpj.length > 11
											? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
											: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
									</Text>
								</View>
								{checkValid() && (
									<View>
										<Text style={styles.TextNormal}>
											Distribuído por {orderItem.pedido.cliente.nome}
										</Text>
										<Text style={styles.TextNormal}>
											{orderItem.pedido.cliente.enderecoCliente.endereco + 
												" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
												" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
										</Text>
										<Text style={styles.TextNormal}>
											{orderItem.pedido.cliente.cpf_cnpj.length > 11
												? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
												: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}
										</Text>
									</View>
								)}

								<View style={{ marginTop: "2px" }}>
									{orderItem.data_embalagem && (
										<Text style={styles.TextNormal}>
											Data de Embalagem: {new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")}
										</Text>
									)}

									<Text style={styles.TextNormal}>Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}</Text>
									{/* <Text style={styles.TextNormal}>Data de Entrega: {dataEntrega}</Text>
									<Text style={styles.TextNormal}>
										Data de Embalagem: {dataEmbalagem} 
									</Text> */}
									<Text style={styles.TextBoldTitle}>{orderItem.especificacao}</Text>
								</View>
								{/* direita */}
							</View>
							{/* <View ref={ref} /> */}
						</View>
					</View>
					{/* roda pé  */}
					<View style={styles.footer} fixed>
						<View style={styles.paggingContainer}>
							<Image src={Seta} style={styles.imgFooter} />
							<View style={styles.paggingText}>
								<Text>PRODUTO COM ORIGEM RASTREADA</Text>
							</View>
							<Image src={Seta} style={styles.imgFooterInvetida} />
							<View style={styles.paggingText}>
								<Text>https://www.sisagri.com.br</Text>
							</View>
							<Image src={Seta} style={styles.imgFooterInvetida} />
						</View>
					</View>
				</View>
				{checkDoubleTag() && (
					<View style={{height: "50%"}}>
						<View style={styles.body}>
							<View style={styles.qrCodeContainer}>
								{/* top etiqueta */}
								<View style={styles.topEtiquetaContainer}>
									<View style={{ height: "50x" }}>
										<View style={styles.logoEmpressaContainer}></View>
									</View>
									<View style={styles.codBarContainer}>
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												textTransform: "uppercase",
											}}
										>
											{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}
										</Text>
									</View>
									<View style={styles.containerQrcode}>
										<Text style={styles.TextNormalQrCode}>CONHEÇA ORIGEM</Text>
										<Text style={styles.TextNormalQrCode}>DESTE PRODUTO</Text>
										<Image src={dataUrl} style={styles.qrCode} />
										{barCodeType !== null && <Image src={barcode} style={styles.barCode} />}

										<Text style={styles.TextNormalQrCode}>POR: SISAGRI</Text>
									</View>
								</View>
								{/* botton etiqueta */}
								<View style={styles.bottonEtiquetaContainer}>
									{/* esquerda */}
									<View style={{ marginBottom: "2px" }}>
										<Text style={styles.TextBold}>
											Peso liquido Mínimo: <Text style ={styles.TextBoldNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
										</Text>
										<Text style={styles.TextBold}>Embalagem: {orderItem?.embalagem.nome}</Text>
										<Text style={styles.TextBold}>
											Código de Rastreio: {`${orderItem?.codigo}-${harvest.lote.codigo}`}
										</Text>
									</View>

									<View style={{ marginBottom: "2px" }}>
										<Text style={styles.TextNormal}>Produzido por {property.nome}</Text>
										<Text style={styles.TextNormal}>
											{property.endereco.endereco + 
												" " + property.endereco.bairro + 
												" - " + property.endereco.cidade}
										</Text>
										<Text style={styles.TextNormal}>
											{property.cpf_cnpj.length > 11
												? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
												: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
										</Text>
									</View>
									{checkValid() && (
										<View>
											<Text style={styles.TextNormal}>
												Distribuído por {orderItem.pedido.cliente.nome}
											</Text>
											<Text style={styles.TextNormal}>
												{orderItem.pedido.cliente.enderecoCliente.endereco + 
													" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
													" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
											</Text>
											<Text style={styles.TextNormal}>
												{orderItem.pedido.cliente.cpf_cnpj.length > 11
													? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
													: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}
											</Text>
										</View>
									)}

									<View style={{ marginTop: "2px" }}>
										{orderItem.data_embalagem && (
											<Text style={styles.TextNormal}>
												Data de Embalagem: {new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")}
											</Text>
										)}

										<Text style={styles.TextNormal}>Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}</Text>
										{/* <Text style={styles.TextNormal}>Data de Entrega: {dataEntrega}</Text>
										<Text style={styles.TextNormal}>
											Data de Embalagem: {dataEmbalagem} 
										</Text> */}
										<Text style={styles.TextBoldTitle}>{orderItem.especificacao}</Text>
									</View>
									{/* direita */}
								</View>
								{/* <View ref={ref} /> */}
							</View>
						</View>
						{/* roda pé  */}
						<View style={styles.footer} fixed>
							<View style={styles.paggingContainer}>
								<Image src={Seta} style={styles.imgFooter} />
								<View style={styles.paggingText}>
									<Text>PRODUTO COM ORIGEM RASTREADA</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
								<View style={styles.paggingText}>
									<Text>https://www.sisagri.com.br</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
							</View>
						</View>
					</View>
				)}
			</Page>
		);
	}

	//bar code

	// const barCodeValorCODE128 = codBar1;
	// function validationValueCodBarCODE128() {
	// 	if (barCodeCODE128 === null) return false;
	// 	else return true;
	// }
	// const barCodeCanvasCODE128 = document.createElement("canvas");
	// JsBarcode(barCodeCanvasCODE128, barCodeValorCODE128, {
	// 	format: barCodeCODE128,
	// 	width: 3,
	// 	fontSize: 20,
	// 	height: 100,
	// });
	// const barcodeCODE128 = barCodeCanvasCODE128.toDataURL();

	// const barCodeValorEAN13 = codBar2;

	// function validationValueCodBarEAN13() {
	// 	if (barCodeEAN13 === null) return false;
	// 	else return true;
	// }
	// const barCodeCanvasEAN13 = document.createElement("canvas");
	// JsBarcode(barCodeCanvasEAN13, barCodeValorEAN13, {
	// 	format: barCodeEAN13,
	// 	width: 3,
	// 	fontSize: 20,
	// 	height: 120,
	// });
	// const barcodeEAN13 = barCodeCanvasEAN13.toDataURL();

	return (
		!loading ?
			<Document>
				{ids.map((id) => (
					<QRCodePage id={id} key={id} />
				))}
			</Document>
			:<Document></Document>
	);
};

export default PdfHangTags12x5;