import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import { Avatar, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logo from "../../Assets/images/logoHeaderPdf.png";
import Evaluation from "./ComponentesSettings/Evaluation";
import PlataformaRastreabilidade from "./ComponentesSettings/Rastreabilidade";
import SecurityPrivacy from "./ComponentesSettings/SecurityPrivacy";
import TermsContract from "./ComponentesSettings/TermsContract";
import YourPRoperty from "./ComponentesSettings/YourProperty";
import "./SettingsPag.module.css";
import styles from "./SettingsPag.module.css";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export default function VerticalTabs() {
	const [value, setValue] = React.useState(0);
	const [user, setUser] = React.useState(0);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: "#00A69A",
				display: "flex",
				height: "100%",
				minHeight: "100vh",
				// flexDirection: "column",
			}}
		>
			<Box
				sx={{
					p: 0,

					display: "flex",
					b: { xs: "row", sm: "column", md: "column" },
					justifyContent: "center",
					alignItems: "center",
					width: { xs: "100%", sm: "100vw" },
				}}
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={{ xs: 0, sm: 0, md: 0 }}
					sx={{
						width: { xs: "100%", sm: "100vw" },
						height: { xs: "100%", sm: "100%" },
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Grid
						item
						xs={0}
						sm={4}
						md={3}
						sx={{
							display: { xs: "none", sm: "flex" },
							flexDirection: "column",
							justifyContent: "start ",
							alignItems: "start",
							width: "100%",
							bgcolor: "white",
							borderRight: 1,
							borderColor: "#00A69A",
						}}
					>
						<Box sx={{ mt: 2 }}>
							<a className={styles.headerRegisterAreaButton} href={"/home"}>
								<ArrowBackIcon />
								Voltar
							</a>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "start",
								alignItems: "center",
								pb: 3,
								pt: 3,
								borderTop: 1,
								borderBottom: 1,
								borderColor: "#00A69A",
								width: "100%",
							}}
						>
							<Avatar
								alt="Remy Sharp"
								src={logo}
								sx={{
									mx: 1,
									border: "1px solid gray",
									boxShadow: " 0px 6px 10px 0px rgba(0,0,0,0.7)",
									width: 56,
									height: 56,
								}}
							/>

							<div>
								<h3>{user.name}</h3>
								<p>{user.username}</p>
							</div>
						</Box>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={value}
							onChange={handleChange}
							aria-label="Vertical tabs example"
							sx={{
								borderBottom: 1,
								borderColor: "#00A69A",
								width: "100%",
								maxWidth: "none",
							}}
							TabIndicatorProps={{
								sx: { backgroundColor: "#00ba9d" },
							}}
						>
							<Tab
								label="Sua conta"
								icon={<AccountCircleOutlinedIcon sx={{ mr: 1 }} />}
								{...a11yProps(0)}
								className={
									value === 0
										? styles.tab + " " + styles.focuzVertical
										: styles.tab
								}
								sx={{
									maxWidth: "none",
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
									alignItems: "center",
								}}
							/>
							<Tab
								label="Plataforma de rastreio"
								icon={<ContentPasteIcon sx={{ mr: 1 }} />}
								{...a11yProps(1)}
								className={
									value === 1
										? styles.tab + " " + styles.focuzVertical
										: styles.tab
								}
								sx={{
									maxWidth: "none",
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
									alignItems: "center",
								}}
							/>
							<Tab
								label="Login e Privacidade"
								icon={<LockOpenIcon sx={{ mr: 1 }} />}
								{...a11yProps(2)}
								className={
									value === 2
										? styles.tab + " " + styles.focuzVertical
										: styles.tab
								}
								sx={{
									maxWidth: "none",
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
									alignItems: "center",
								}}
							/>
							<Tab
								label="Termo do Contrato"
								icon={<ReceiptLongOutlinedIcon sx={{ mr: 1 }} />}
								{...a11yProps(3)}
								className={
									value === 3
										? styles.tab + " " + styles.focuzVertical
										: styles.tab
								}
								sx={{
									maxWidth: "none",
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
									alignItems: "center",
								}}
							/>
							<Tab
								label="Avaliações"
								icon={<StarOutlineOutlinedIcon sx={{ mr: 1 }} />}
								{...a11yProps(4)}
								className={
									value === 4
										? styles.tab + " " + styles.focuzVertical
										: styles.tab
								}
								sx={{
									maxWidth: "none",
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
									alignItems: "center",
								}}
							/>
						</Tabs>
					</Grid>
					<Grid
						item
						xs={12}
						sm={0}
						md={0}
						sx={{
							display: { xs: "flex", sm: "none" },
							justifyContent: "start",
							alignItems: "center",
							bgcolor: "white",
						}}
					>
						<a className={styles.headerRegisterAreaButton} href={"/home"}>
							<ArrowBackIcon />
						</a>
						<Avatar
							alt="Propriedade Logo"
							src={logo}
							sx={{
								mx: 1,
								mb: 0.5,
								border: "1px solid gray",
							}}
						/>
					
						<Tabs
							orientation="horizontal"
							variant="scrollable"
							value={value}
							onChange={handleChange}
							aria-label="horizontal tabs example"
							sx={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							TabIndicatorProps={{
								sx: { backgroundColor: "#00ba9d" },
							}}
						>
							<Tab
								icon={<AccountCircleOutlinedIcon />}
								aria-label="phone"
								value={0}
								className={
									value === 0
										? styles.tab + " " + styles.focuzHorizontal
										: styles.tab
								}
								sx={{
									minWidth: 0,
									width: "max-content",
								}}
							/>
							<Tab
								icon={<LockOpenIcon />}
								aria-label="phone"
								value={1}
								className={
									value === 1
										? styles.tab + " " + styles.focuzHorizontal
										: styles.tab
								}
								sx={{
									minWidth: 0,
									width: "max-content",
								}}
							/>
							<Tab
								icon={<ReceiptLongOutlinedIcon />}
								aria-label="phone"
								value={2}
								className={
									value === 2
										? styles.tab + " " + styles.focuzHorizontal
										: styles.tab
								}
								sx={{
									minWidth: 0,
									width: "max-content",
								}}
							/>
							<Tab
								icon={<StarOutlineOutlinedIcon />}
								aria-label="phone"
								value={3}
								className={
									value === 3
										? styles.tab + " " + styles.focuzHorizontal
										: styles.tab
								}
								sx={{
									minWidth: 0,
									width: "max-content",
								}}
							/>
						</Tabs>
					</Grid>
					<Grid
						item
						xs={12}
						sm={8}
						md={9}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							bgcolor: "#00A69A",
							height: "100%",
							width: "100%",
							maxWidth: "100vw",
							maxHeight: { xs: "90vh", sm: "100vh", md: "100vh" },
							p: 0,
						}}
					>
						<TabPanel value={value} index={0}>
							<YourPRoperty />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<PlataformaRastreabilidade />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<SecurityPrivacy />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<TermsContract />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<Evaluation />
						</TabPanel>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
