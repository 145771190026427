/* eslint-disable react/prop-types */
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
const listItemStyle = { pl: "12px" };

//import css modules
import styles from "./asideMenuListItem.module.css";

function AsideMenuListItem(props) {
	const focus = props.classItemStyle === true ? styles.focusPage : ""; //seleciona o item da lista

	return (
		<ListItemButton
			className={focus}
			href={props.href}
			sx={listItemStyle}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			<ListItemIcon sx={listItemStyle}>{props.icon}</ListItemIcon>
			<ListItemText primary={props.text} />
		</ListItemButton>
	);
}

export default AsideMenuListItem;
