import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Grid } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import caminhoproduto from "../../Assets/images/caminhoProduto.png";
import firstPageBg from "../../Assets/images/firstPagebackground.png";
import desfoque from "../../Assets/images/produtor.png";
// import * as queries from "../../graphql/queries";
import AboutOrigin from "./components/AboutOrigin";
import CompanyPresentation from "./components/CompanyPresentation";
import DidYouKnow from "./components/DidYouKnow";
import Evaluation from "./components/Evaluation";
import FirstPage from "./components/FirstPage";
import ProductPath from "./components/ProductPath";
import TraceabilityProdut from "./components/TraceabilityProdut";
import { PropertyService } from "../../service/property_service";
import { SettingsService } from "../../service/settings_service";

const styleFirstPage = {
	p: { xs: "5px", sm: "25px", md: "50px" },
	pt: { xs: "20px", sm: "40px", md: "80px" },
	height: "100vh",
	bgcolor: "#00A69A",

	backgroundImage: `url(${firstPageBg})`,
	backgroundSize: "cover",
	backgroundPosition: "center",
};
const styleWhiteAboutOrigin = {
	p: { xs: "5px", sm: "25px", md: "50px" },
	pt: { xs: "20px", sm: "40px", md: "80px" },
	height: "100%",
	minHeight: "100vh",
	bgcolor: "rgba(255,255,255,1)",
	background:
		"radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 35%, rgba(246,246,246,1) 47%, rgba(173,173,173,1) 100%)",

	color: "black",
};
const styleCompanyPresentation = {
	px: { xs: "5px", sm: "25px", md: "50px" },
	pt: 0,
	height: "100%",
	minHeight: "100vh",
	bgcolor: "rgba(255,255,255,1)",
	background:
		"radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 35%, rgba(246,246,246,1) 47%, rgba(173,173,173,1) 100%)",
	color: "black",
};
const styleEvaluation = {
	p: { xs: "5px", sm: "25px", md: "50px" },
	pt: { xs: "20px", sm: "40px", md: "80px" },
	height: "100vh",
	bgcolor: "#00A69A",

	backgroundImage: `url(${desfoque})`,
	backgroundSize: "cover",
	backgroundPosition: "center",
};

const styleDidYouKnow = {
	p: { xs: "5px", sm: "25px", md: "50px" },
	pt: { xs: "20px", sm: "40px", md: "80px" },
	height: "100%",
	minHeight: "100vh",
	bgcolor: "#009199",
};
const styleWhiteTraceabilityProdut = {
	px: { xs: "5px", sm: "25px", md: "50px" },
	pt: 0,
	display: "flex",
	height: "100%",
	minHeight: "100vh",
	bgcolor: "rgba(255,255,255,1)",
	background:
		"radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 35%, rgba(246,246,246,1) 47%, rgba(173,173,173,1) 100%)",
	color: "black",

};
const styleWhiteProductPath = {
	p: { xs: "5px", sm: "25px", md: "50px" },
	pt: { xs: "20px", sm: "40px", md: "80px" },
	height: "100%",
	minHeight: "100vh",
	backgroundImage: `url(${caminhoproduto})`,
	backgroundRepeat: "no-repeat",
	// backgroundSize: "100vw",
	backgroundPosition: "center",
	objectFit: "cover",
	color: "black",
};

function LandingPage() {
	const url = new URLSearchParams(window.location.search);
	const idItem = url.get("id");
	const idPropriedade = url.get("pId");
	const [pageYPosition, setPageYPosition] = useState(0);
	const [id, setId] = useState(0);
	const [itemPedido, setitemPedido] = useState("");
	const [propriedade, setPropriedade] = useState("");
	const [error,setError] = useState(false);
	const [lote, setLote] = useState("");
	const item = idItem !== null ? idItem.split("-") : "";
	const idItemPedido = item[0];
	const idLote = item[1];

	const propertyService = new PropertyService();
	const settingsService = new SettingsService();

	function getPageYAfterScroll() {
		setPageYPosition(window.scrollY);
	}
	useEffect(() => {
		init();
	}, []);
	useEffect(() => { 
		verificacao();
	}, [itemPedido]);

	async function init() {
		
		if(idItem && idItem !== "")
			getItemPedido();
		
		if(idPropriedade && idPropriedade !== "")
			getPropriedade();
		
		verificacao();
	}
	async function getPropriedade() {
		try {
			const response = await propertyService.getById(idPropriedade);	
			setPropriedade(response);

		} catch (error) {
			console.error("Erro na busca por ID propriedade:", error);
		}
	}
	
	async function getItemPedido() {
		try {
			const response = await settingsService.getTraceabilityByOrderItem(idLote, idItemPedido);
			setitemPedido(response.itemPedido);
			setLote(response.lote);		
		} catch (error) {
			console.error("Erro na busca por ID:", error);
		}
	}

	window.addEventListener("scroll", getPageYAfterScroll);

	function down() {
		if (id !== 6) setId(id + 1);
	}
	function up() {
		if (id !== 0) setId(id - 1);
	}
	function verificacao() {
		if ((idPropriedade !== "" && idPropriedade !== null && idPropriedade !== undefined))
		{ setError(true); }
		else{
			if(itemPedido === "" || itemPedido === null || itemPedido === undefined   )
				setError(false);
			else
				setError(true); }
	}
	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: "100vh",

				bgcolor: "#00A69A",
				color: "white",
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					position: "relative",
				}}
			>
				{pageYPosition > 400 && (
					<a
						href={`#${0}`}
						onClick={() => setId(0)}
						style={{
							position: "fixed",
							bottom: "120px",
							right: "10px",
							color: "aqua",
							zIndex: "99"
						}}
					>
						<KeyboardDoubleArrowUpIcon sx={{ width: "40px", height: "40px" }} />
					</a>
				)}

				<a
					href={`#${id}`}
					onClick={up}
					style={{
						position: "fixed",
						bottom: "80px",
						right: "10px",
						color: "#00ba9d",
						textDecoration: "none",
						zIndex:"99"
					}}
				>
					<ArrowCircleUpIcon sx={{ width: "40px", height: "40px" }} />
				</a>

				<a
					href={`#${id}`}
					onClick={down}
					style={{
						position: "fixed",
						bottom: "40px",
						right: "10px",
						color: "#00ba9d",
						zIndex:"99"
					}}
				>
					<ArrowCircleDownIcon sx={{ width: "40px", height: "40px" }} />
				</a>

				<Grid item xs={12} sm={12} md={12} sx={styleFirstPage} id="0">
					<FirstPage propriedade={(idPropriedade !== null && idPropriedade !== "") ? true : false} propertyName={propriedade !== ""? propriedade.nome : lote?.propriedadeCultivo?.propriedade?.nome} error={error} />
				</Grid>
				{error && (
					<>
						<Grid item xs={12} sm={12} md={12} sx={styleWhiteAboutOrigin} id="1">
							<AboutOrigin itemPedido={itemPedido} lote={lote} propriedade={propriedade} />
						</Grid>
						<Grid item xs={12} sm={12} md={12} sx={styleCompanyPresentation} id="2">
							<CompanyPresentation propriedade={propriedade !== ""? propriedade : lote?.propriedadeCultivo?.propriedade} />
						</Grid>
						{idPropriedade === null ?
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={styleWhiteTraceabilityProdut}
								id="3"
							>
								<TraceabilityProdut id={idItem} itemPedido={itemPedido} lote={lote} />
							</Grid>
							:
							""
						}
			
						<Grid item xs={12} sm={12} md={12} sx={styleEvaluation} id="4">
							<Evaluation  propriedade={propriedade !== ""? propriedade : lote?.propriedadeCultivo?.propriedade}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} sx={styleDidYouKnow} id="5">
							<DidYouKnow />
						</Grid>
						{idPropriedade === null ?
							<Grid item xs={12} sm={12} md={12} sx={styleWhiteProductPath} id="6">
								<ProductPath itemPedido={itemPedido}  lote={lote}/>
							</Grid>:""}
					</>)
				}
			</Grid>
		</Box>
	);
}
export default memo(LandingPage);
