/* eslint-disable react/prop-types */
import React from "react";
import {
	Gite as PropertyIcon,
	Forest as CultureIcon,
	Engineering as ServicesIcon,
	LocalShipping as InsumosIcon,
	Groups as ClientsIcon,
	CalendarMonth as SafrasIcon,
	ShoppingCart as BuyIcon,
	Delete as LostsIcon,
	Assignment as OccurrencesIcon,
	Agriculture as HarverstIcon,
	Equalizer as MovimentsIcon,
	QueryStats as ReportIcon,
	Inventory as InventoryIcon,
	Add as AddIcon,
	Edit as EditIcon,
	Search as SearchIcon,
	MoreVert as MoreVertIcon,
	BorderAll as BorderAllIcon,
	PieChart as PieChartIcon,
	Settings as SettingsIcon,
	ExitToApp as ExitToAppIcon,
	IosShare as IosShareIcon,
	DashboardCustomize as DashboardCustomizeIcon,
	SupervisedUserCircle as ActivitiesIcon,
	Group as UsersIcon,
	FmdGood as FmdGoodIcon,
	SatelliteAlt as SatelliteAltIcon,
	Map as MapIcon
} from "@mui/icons-material";

import atividade from "../../Assets/icons/atividade.png";
import clientes from "../../Assets/icons/clientes.png";
import colheita from "../../Assets/icons/colheita.png";
import cultura from "../../Assets/icons/cultura.png";
import estoque from "../../Assets/icons/estoque.png";
import fazendaInteligente from "../../Assets/icons/fazendaInteligente.png";
import insumo from "../../Assets/icons/insumos.png";
import manejo from "../../Assets/icons/manejo.png";
import pedidos from "../../Assets/icons/pedidos.png";

const style = { color: "white", "margin-right": 8 };

function IconAtividade() {
	return <img src={atividade} width="25px" height="100%" />;
}
function IconClientes() {
	return <img src={clientes} width="25px" height="100%" />;
}
function IconColheita() {
	return <img src={colheita} width="25px" height="100%" />;
}
function IconCultura() {
	return <img src={cultura} width="25px" height="100%" />;
}
function IconEstoque() {
	return <img src={estoque} width="25px" height="100%" />;
}
function IconFazendaInteligente() {
	return <img src={fazendaInteligente} width="25px" height="100%" />;
}
function IconInsumo() {
	return <img src={insumo} width="25px" height="100%" />;
}
function IconManejo() {
	return <img src={manejo} width="25px" height="100%" />;
}
function IconPedidos() {
	return <img src={pedidos} width="25px" height="100%" />;
}

function IconProperty() {
	return <PropertyIcon style={style} />;
}
function IconUsers() {
	return <UsersIcon style={style} />;
}
function IconCulture() {
	return <CultureIcon style={style} />;
}
function IconServices() {
	return <ServicesIcon style={style} />;
}
function IconInsumos() {
	return <InsumosIcon style={style} />;
}
function IconClients() {
	return <ClientsIcon style={style} />;
}
function IconSafras() {
	return <SafrasIcon style={style} />;
}
function IconBuy() {
	return <BuyIcon style={style} />;
}
function IconLosts() {
	return <LostsIcon style={style} />;
}
function IconDelet(props) {
	return <LostsIcon style={props.style} />;
}

function IconOccurrences() {
	return <OccurrencesIcon style={style} />;
}
function IconActivities() {
	return <ActivitiesIcon style={style} />;
}
function IconHarverst() {
	return <HarverstIcon style={style} />;
}
function IconMoviments() {
	return <MovimentsIcon style={style} />;
}
function IconReport() {
	return <ReportIcon style={style} />;
}
function IconInventory() {
	return <InventoryIcon style={style} />;
}
function IconAdd() {
	return <AddIcon style={style} />;
}
function IconEdit(props) {
	return <EditIcon style={props.style} />;
}
function IconSearch() {
	return <SearchIcon style={style} />;
}
function IconMoreVert(props) {
	return <MoreVertIcon style={props.style} />;
}
function IconBorderAll() {
	return <BorderAllIcon style={style} />;
}
function IconPieChart() {
	return <PieChartIcon style={style} />;
}
function IconSettings() {
	return <SettingsIcon style={style} />;
}
function IconExitToApp() {
	return <ExitToAppIcon style={style} />;
}
function IconIosShare() {
	return <IosShareIcon style={style} />;
}
function IconDashboardCustomize() {
	return <DashboardCustomizeIcon style={style} />;
}
function IconFmdGood() {
	return <FmdGoodIcon style={style} />;
}
function IconSatelliteAlt() {
	return <SatelliteAltIcon style={style} />;
}
function IconMap() {
	return <MapIcon style={style} />;
}
export {
	IconDashboardCustomize,
	IconIosShare,
	IconProperty,
	IconUsers,
	IconCulture,
	IconServices,
	IconInsumos,
	IconClients,
	IconSafras,
	IconBuy,
	IconLosts,
	IconOccurrences,
	IconActivities,
	IconHarverst,
	IconMoviments,
	IconReport,
	IconInventory,
	IconAdd,
	IconEdit,
	IconSearch,
	IconMoreVert,
	IconBorderAll,
	IconPieChart,
	IconSettings,
	IconExitToApp,
	IconDelet,
	IconFmdGood,
	IconAtividade,
	IconClientes,
	IconColheita,
	IconCultura,
	IconEstoque,
	IconFazendaInteligente,
	IconInsumo,
	IconManejo,
	IconPedidos,
	IconSatelliteAlt,
	IconMap
};
