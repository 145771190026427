import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Modal, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SearchHeader from "../../../components/SearchHeader";
import InputSearch from "../../../components/SearchHeader/inputSeach";
import Table from "../../../components/TableModalPedidos";
import TopMenu from "../../../components/TopMenu";
import styles from "./RequestItemDetalhe.module.css";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { OrderService } from "../../../service/order_service";
import { LossService } from "../../../service/loss_service";
import Loading from "../../../components/Loading";

const columns = [
	{
		id:"cultureName",
		label: "Cultura",
	},
	{
		id:"codigo",
		label: "Codigo",
	},
	{
		id:"lote",
		label: "Lote",
	},
	{
		id:"quant",
		label:"Quantidade",
	},
	{
		id:"unid",
		label: "Unidade de Medida",
	},
	{
		id:"embalagem",
		label:"Embalagem",
	},
	{
		id:"dataEmbalagem",
		label:"Data de embalagem",
	},
	{
		id:"especificacao",
		label:"Especificação do Pedido",
	},
	{
		id:"devolvido",
		label:"Devolvido",
	},
];

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};
function ResquestItemDetelhes() {
	const [search, setSearch] = useState(""); //search
	const [open, setOpen] = useState(false);
	const [borderRed] = useState("");
	const [returnDescription, setReturnDescription] = useState("");
	const [idLote, setIdLote] = useState(null);
	const [orderItemId, setOrderItemId] = useState(null);
	const { id } = useParams();
	const [allOrderItems, setAllOrderItems] = useState([]);
	const [orderNumber, setOrderNumber] = useState("não informado");
	const [isLoading, setIsLoading] = useState(true);

	const orderService = new OrderService();
	const lossService = new LossService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const order = await orderService.getOrder(id);

		const items = [];
		order.itemPedidos.forEach((item) => {
			item.lotes.forEach((harvest) => {
				items.push({
					id: item.id,
					codigo: item.codigo ? item.codigo : "",
					lote: harvest.codigo,
					quant: harvest.quantidade.toString().replace(".",","),
					unid: item.embalagem.unidadeMedida.sigla,
					loteID: harvest.loteId,
					cultureName: captalizeFirstLetterEacheWord(`${harvest.lote.propriedadeCultivo.cultivo.cultura} ${(harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}`),
					dataEmbalagem: item.data_embalagem ? new Date(item.data_embalagem).toLocaleDateString("pt-BR") : "Não Informado",
					embalagem: item.embalagem.nome ?? "Não informado",
					especificacao: order.observacao,
					nPedido: order.numeroPedido ?? "Não informado",
					devolvido:	harvest.devolvido !== true ? "Não" : "Sim",
				});
			});
		});

		setAllOrderItems(items);

		setOrderNumber(order.numeroPedido ?? "Não informado");

		setIsLoading(false);
	};

	const custonOp = [
		{
			item: "Devolução",
			func: function handleOpen(harvestId, idOrderItem) {
				setIdLote(harvestId);
				setOrderItemId(idOrderItem);
				setOpen(true);
			},
		},
	];

	const handleUpdateLoteItemPedido = async () => {
		const itemToUpdate = {
			harvestId: idLote,
			orderItemId: orderItemId,
			description: returnDescription,
		};
		await orderService.updateItemAsReturned(itemToUpdate);
		handleClose();
	};

	const handleSaveAsPerda = async () => {
		const propertyId = localStorage.getItem("propertyId");
		
		const orderItem = await orderService.getOrderItem(orderItemId);
		const harvest = orderItem.lotes.find((harvestItem) => harvestItem.loteId === idLote);

		const lossTypes = await lossService.getAllLossType();
		const lossType = lossTypes.find((lossTypeItem) => !lossTypeItem.preColheita);

		await handleUpdateLoteItemPedido();

		const lossToSave = {
			allotmentId: idLote,
			cropSeasonId: null,
			lossTypeId: lossType.id,
			description: returnDescription,
			amount: Number(harvest.quantidade),
			date: new Date(),
			propertyId,
		};
		
		const savedLoss = await lossService.save(lossToSave);
		handleClose();
		window.location.href = `/perdas/cadastroPerdas?id=${savedLoss.id}`;
	};

	function handleClick(e) {
		setSearch(e.target.value); //seta o valor do campo de busca
	}
	const dataFilter = allOrderItems.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	const handleDelete = async (id) => {
		await orderService.changeVisibilityItem(id);
		initData();
	};

	const handleClose = () => {
		setOpen(false);
		initData();
	};
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "requests"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<SearchHeader
										href={"/pedidos/detalhes/editarCadastro/?orderId=" + id}
										textButton="Adicionar Item ao pedido"
										headerComponents={
											<Link to={"/pedidos"} className={styles.headerRegisterAreaButton}>
												<ArrowBackIcon sx={{ mr: 2 }} />
												Pedido Nº {orderNumber}
											</Link>
										}
										help="requestsItemDetelhes" 
									>
										<InputSearch func={handleClick} />
									</SearchHeader>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Table
										data={dataFilter}
										keys={id}
										numberPedido={orderNumber}
										columns={columns}
										handleDelete={handleDelete}
										custonOpition={custonOp}
										hrefEdit={"editarCadastro/?orderId=" + id}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={{ xs: 0, sm: 0, md: 0 }}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										borde: "none",
										boxShadow: "none",
										display: "flex",
										justifyContent: "start",
										p: 0,
										m: 0,
									}}
								>
									<h2 className="modalContentTitle">Devolução</h2>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										borde: "none",
										boxShadow: "none",
										display: "flex",
										justifyContent: "start",
										px: 0,
										mx: 0,
									}}
								>
									<div className={styles.modalContentError}>
										<p>{borderRed === "" ? "" : "Especifique o motivo da devolução"}</p>
									</div>
								</Item>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									borde: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "start",
									px: 0,

									mx: 0,
								}}
							>
								<input
									type="text"
									placeholder="Motivo da devolução"
									id={borderRed}
									value={returnDescription}
									onChange={(event) => setReturnDescription(event.target.value)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={{ border: "none", boxShadow: "none" }}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button type="button" className={styles.registrationButtonCancelar} onClick={() => handleSaveAsPerda()}>
													registrar como perda
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={() => handleUpdateLoteItemPedido()}
												>
													retornar para estoque
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}
export default ResquestItemDetelhes;
