import { ApplicationModeRepository } from "../repository/application_mode_repository";

export class ApplicationModeService {

	constructor() {
		this.applicationModeRepository = new ApplicationModeRepository();
	}

	async get(params) {
		const applicationModeData = await this.applicationModeRepository.get(params);
		return applicationModeData;
	}

}