import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";
import { ReportService } from "../../../../service/report_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
	},

	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
	},
	textBoldRight: {
		textTransform: "uppercase",
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textNormalRight: {
		textTransform: "uppercase",
		fontSize: "10px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "2px 8px",
		fontFamily: "Roboto bold",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginVertical: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	Subtitle: {
		backgroundColor: "#E0DFDF",
		padding: "2px 8px",
		textTransform: "uppercase",
		color: "#03989E",
		borderRadius: "8px",
		letterSpacing: "1px",
		marginVertical: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		fontSize: "13px",
		fontFamily: "Roboto bold",
	},
	Body: {
		paddingBottom: "15px",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "1px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
		fontWeight: "hairline",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-start",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "100%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
		fontFamily: "Roboto bold",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
});

function PdfRelatorioProdutividade(props) {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [loading, setLoading] = useState(true);
	const [reportData, setReportData] = useState(null);
	const [user, setUser] = useState(null);
	
	const culturaID = props.culturaId;
	const talhaoID = props.talhaoId;
	const parcelaID = props.parcelaId;
	const logo = props.logo;

	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const params = {
			propertyFarm: String(culturaID) !== "-1" && String(culturaID) !== "0" ? culturaID : null,
			plot: String(talhaoID) !== "-1" ? talhaoID : null,
			subdivision: String(parcelaID) !== "-1" ? parcelaID : null,
			propertyId: propertyId,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};

		const data = await reportService.getProductivityData(params);
		setReportData(data);

		setLoading(false);
	};

	const getName = () => {
		if(reportData.talhao === null)
			return "Todos";

		let name = `Talhão ${reportData.talhao.nome}`;

		if(reportData.subdivisao)
			name += ` - ${reportData.subdivisao.categoria} ${reportData.subdivisao.complemento}`;

		return name;
	};

	return (
		! loading ?
			<Document>
				<Page size="A4" style={styles.page} wrap>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>produtividade</Text>
							<Text style={styles.textBoldFazenda}>
								{reportData.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{reportData.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>
					<View style={styles.line} fixed></View> {/* linaha cinza*/}
					<View
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row",
						}}
					>
						<View style={styles.containerSubHeader}>
							<Text style={styles.textBold}>Cultura:</Text>
							<Text style={styles.textNormal}>{reportData.propriedadeCultivo?.nome ?? "Todos"}</Text>
							<Text style={styles.textBold}>Área: </Text>
							<Text style={styles.textNormal}>
								{getName()}
							</Text>
							<Text style={styles.textBold}>Período: </Text>
							<Text style={styles.textNormal}>
								{new Date(props.startDate).toLocaleDateString("pt-BR")} a{" "}
								{new Date(props.finalDate).toLocaleDateString("pt-BR")}
							</Text>
						</View>
						<View style={styles.containerSubHeader}>
							<Text style={styles.textBoldRight}>Produtividade Média</Text>
							<Text style={styles.textNormalRight}>
								{reportData.produtividade} {reportData.unidadeMedida}/ha
							</Text>
						</View>
					</View>
					{reportData.colheitas.map((harvest, index) => {
						return (
							<View key={index} style={styles.Body}>
								<View style={styles.mainTitle} wrap={false}>
									<Text>{harvest.talhao.nome}</Text>
									<Text>
										Produtividade: {harvest.talhao.produtividade}{" "}
										{harvest.talhao.unidadeMedida}/ha
									</Text>
								</View>
								{/* <View style={styles.content}>
									<View style={styles.tableTitle}>
										<Text style={styles.larguraTexto}>NOME DA BUBDIVISÃO :</Text>
										<Text style={styles.larguraTexto}>TAMANHO ÁREA:</Text>
										<Text style={styles.larguraTexto}>PRODUÇÃO</Text>
										<Text style={styles.larguraTexto}>PRODUTIVIDADE</Text>
									</View>
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>nomeTalhao</Text>
										<Text style={styles.larguraTexto}>area_hectares</Text>
										<Text style={styles.larguraTexto}>produtividadeTalhao</Text>
										<Text style={styles.larguraTexto}>produtividadeTalhao</Text>
									</View>
								</View> */}
								{harvest.talhao.subdivisoes.map((subdivision, index) => {
									return (
										<View key={index} wrap={false}>
											<View key={index} style={styles.Subtitle} wrap={false}>
												<Text>{`${subdivision.categoria} ${subdivision.complemento}`}</Text>
											</View>
											<View style={styles.content}>
												<View style={styles.tableTitle}>
													<Text style={styles.larguraTexto}>
														NOME DA SUBDIVISÃO :
													</Text>
													<Text style={styles.larguraTexto}>TAMANHO ÁREA:</Text>
													<Text style={styles.larguraTexto}>PRODUÇÃO</Text>
													<Text style={styles.larguraTexto}>PRODUTIVIDADE</Text>
												</View>

												<View
													key={index}
													style={styles.tableContent}
													wrap={false}
												>
													<Text style={styles.larguraTexto}>
														{`Talhão ${harvest.talhao.nome} - ${subdivision.categoria} ${subdivision.complemento}`}
													</Text>
													<Text style={styles.larguraTexto}>
														{subdivision.area} HECTARES
													</Text>
													<Text style={styles.larguraTexto}>
														{subdivision.producao}
													</Text>
													<Text style={styles.larguraTexto}>
														{subdivision.produtividade}{" "}
														{subdivision.unidadeMedida}/HA
													</Text>
												</View>
											</View>
										</View>
									);
								})}
								<View style={styles.containerTotalTalhao} wrap={false}>
									<View style={styles.containerTotalTalhaoGrey}>
										<Text>Total {harvest.talhao.nome}:</Text>
										<View style={styles.containerNumberTotal}>
											<Text>{harvest.talhao.areaTotalSubdivisoes} HECTARES</Text>
											<Text>
												{harvest.talhao.producaoTotalSubdivisoes} {harvest.talhao.unidadeMedida}
											</Text>
											<Text>
												{harvest.talhao.produtividadeTotalSubdivisoes}{" "}
												{harvest.talhao.unidadeMedida}/HA
											</Text>
										</View>
									</View>
								</View>
							</View>
						);
					})}
					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
                                            (user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			:<Document></Document>
	);
}

export default PdfRelatorioProdutividade;
