import { Alert, Box, Grid, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import logoHorizontal from "../../../../Assets/images/logoMenu.png";
import styles from "../../LandingPage.module.css";
import { Typography } from "@mui/material";

function FirstPage(props) {
	const [goTo,setGoTo] = useState("");
	const [open, setOpen] = useState(false);
	
	function rastrear() {
		if(goTo !== "")
			return window.location = `/rastreabilidade?id=${goTo}`;
		else setOpen(true);
	}
	const handleClose = (event, reason) => {
		if (reason === "clickaway") return;

		setOpen(false);
	};
	return (
		<div>
			<Box
				component="main"
				sx={{
					width: "100%",
					height: "80vh",
					m: 0,
				}}
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={0}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						height: "100%",
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						<img src={logoHorizontal} className={styles.img} />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "start"
						}}
					>	
						{props.propriedade ?
							<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"flex-start" }}>
								<Typography
									variant="h4"
									sx={{
										fontWeight: "600",
										display: "flex",  justifyContent: "flex-start", alignItems: "center"
									}}
								>
									Rastreie aqui o seu produto! 

								</Typography>
								<Typography
									variant="h6"
									sx={{
										fontWeight: "300",
										display: "flex",  justifyContent: "flex-start", alignItems: "center"
									}}
								>
									Digite o código de rastreio que se encontra na embalagem do produto
								</Typography>
								<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
									<input type="text" value={goTo} onChange={e => setGoTo(e.target.value)} className={styles.inputFistPage} />
									<button className={styles.buttonFistPage} onClick={rastrear}>Rastrear</button>
								</Box>
							</Box> : ""
						}
					</Grid>
					{/* <Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							
						}}
					>	
						{!props.error ?
							<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
								<h1>Codigo do produto INVALIDO</h1>
								
							</Box> : ""
						}
					</Grid> */}
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "end",
							alignItems: "start",
						}}
					>
						<h1>Olá, eu sou a {props.propertyName}.</h1>
						<h2>Aqui vou te apresentar a origem do seu produto, vamos lá?</h2>
					</Grid>
				</Grid>
			</Box>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
					<Alert
						onClose={handleClose}
						severity={"error"}
						sx={{ width: "100%" }}
					>
							Campo vazio ou invalido...
					</Alert>
				</Snackbar>
			</Stack>
		</div>
	);
}
export default FirstPage;
