import { GroupInputRepository } from "../repository/group_input_repository";

export class GroupInputService {

	constructor() {
		this.groupInputRepository = new GroupInputRepository();
	}

	async get() {
		return await this.groupInputRepository.get();
	}

	async getInputs() {
		return await this.groupInputRepository.getInputs();
	}
}