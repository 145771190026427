//main
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import Logo from "../../Assets/images/logoMenu.svg";
import styles from "./PdfLayout.module.css";
import QRGenerator from "./QrCodeGeneration";
import PdfHangTags12x5 from "./pdfQrcodeComponents/PdfHangTags12x5";
import PdfHangTags16x5 from "./pdfQrcodeComponents/PdfHangTags16x5";
import PdfHangTags6x4 from "./pdfQrcodeComponents/PdfHangTags6x4";
import PdfHangTags9x3 from "./pdfQrcodeComponents/PdfHangTags9x3";
import PdfHangTagsSubdiv from "./pdfQrcodeComponents/PdfHangTagsSUbDiv";
import PdfHangTagsTalhao from "./pdfQrcodeComponents/PdfHangTagsTalhao";
import PdfQrOnly from "./pdfQrcodeComponents/PdfQronly";
import PdfRomaneio from "./pdfQrcodeComponents/PdfRomaneio";
import { PropertyService } from "../../service/property_service";
import { OrderService } from "../../service/order_service";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "100%",
	transform: "translate(-50%, -50%)",
};

function PdfQrcodeFile() {
	//url controls
	const url = new URLSearchParams(window.location.search);
	const pag = url.get("pag");
	const index = url.get("index");
	const itemUrl = url.get("item");
	const pedidoUrl = url.get("pedido");
	const checkUrl = url.get("checkDist");
	const doubleTag = url.get("doubleTag");
	const codBar1Type = url.get("codBar1Type");
	const codBar2Type = url.get("codBar2Type");
	const [relatorio, setRelatorio] = useState(pag);
	const [Components, setComponents] = useState("");
	const [hrefRetorno, setHrefREtono] = useState("");
	const [item, setItem] = useState("");
	const [pedido, setPedido] = useState("");
	const [talhao, setTalhao] = useState("");
	const [checkDist, setCheckDist] = useState("");
	const canvasRef = React.useRef();
	const [openModal, setOpenModal] = useState(false);
	const [qrCodeData, setQrCodeData] = useState([]);
	const [qrCodeDataRomaneio, setQrCodeDataRomaneio] = useState([]);
	const allCategories = {
		PARCELA: "Parcela",
		SETOR: "Setor",
		CANTEIRO: "Canteiro",
		BANCADA: "Bancada",
		BALCAO: "Balcão",
		BLOCO: "Bloco",
		GLEBA: "Gleba",
		QUADRA: "Quadra",
		LOTE: "Lote",
	};
	const propertyService = new PropertyService();
	const orderService = new OrderService();

	function pdfComponents() {
		if (relatorio === "Romaneio") {
			setRelatorio("Relatório de Romaneio");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			setHrefREtono("/pedidos");
			return PdfRomaneio;
		}

		if (relatorio === "GerarQrcodeTalhao") {
			setRelatorio("Qr code Talhão");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			getTalhaoName(itemUrl);
			setHrefREtono("/propriedade");
			return PdfHangTagsTalhao;
		}
		if (relatorio === "QrcodePropriedade") {
			setRelatorio("Qr code Propriedade");
			setHrefREtono("/pedidos");
			return PdfQrOnly;
		}
		if (relatorio === "GerarQrcodeSubdiv") {
			setRelatorio("Qr code SUbDivisão");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			getSUbDIvName(itemUrl);
			setHrefREtono("/propriedade");
			return PdfHangTagsSubdiv;
		}
		if (relatorio === "GerarQrcodeEtiqueta6x4") {
			setRelatorio("Etiqueta 6 x 4");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			setCheckDist(checkUrl);
			setHrefREtono(`/pedidos/detalhes/${index}`);
			return PdfHangTags6x4;
		}
		if (relatorio === "GerarQrcodeEtiqueta9x3") {
			setRelatorio("Etiqueta 9 x 3");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			setCheckDist(checkUrl);
			setHrefREtono(`/pedidos/detalhes/${index}`);
			return PdfHangTags9x3;
		}
		if (relatorio === "GerarQrcodeEtiqueta16x5") {
			setRelatorio("Etiqueta 16 x 5");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			setCheckDist(checkUrl);
			setHrefREtono(`/pedidos/detalhes/${index}`);
			return PdfHangTags16x5;
		}

		if (relatorio === "GerarQrcodeEtiqueta12x5") {
			setRelatorio("Etiqueta 12 x 5");
			setItem(itemUrl);
			setPedido(pedidoUrl);
			setCheckDist(checkUrl);
			setHrefREtono(`/pedidos/detalhes/${index}`);
			return PdfHangTags12x5;
		}
	}
	useEffect(() => {
		setComponents(pdfComponents);
		qrCodePedido(itemUrl, pedidoUrl);
	}, []);

	const getTalhaoName = async (item) => {
		const plot = await propertyService.getPlotById(item);
		setTalhao(plot?.nome);
	};
	const getSUbDIvName = async (item) => {
		const subDiv = await propertyService.getSubdivisionById(item);
		const category = allCategories[subDiv.categoriaSubdivisao];
		setTalhao(category + " " +subDiv.complemento);
	};
	const saveAs = (uri, filename) => {
		var link = document.createElement("a");

		if (typeof link.download === "string") {
			link.href = uri;
			link.download = filename;

			//Firefox requires the link to be in the body
			document.body.appendChild(link);

			//simulate click
			link.click();

			//remove the link when done
			document.body.removeChild(link);
		} else {
			window.open(uri);
		}
	};

	const printDocument = (domElement) => {
		html2canvas(domElement).then((canvas) => {
			saveAs(canvas.toDataURL(), `${talhao}QrCOde.png`);
		});
	};
	const handleOpenMOdal = () => {
		setOpenModal(true); //abre o modal
	};
	const handleCloseData = () => {
		setOpenModal(false); //fecha o modal
	};
	async function qrCodePedido(item, orderId) {
		const propertyId = localStorage.getItem("propertyId");
		const property = await propertyService.getById(propertyId);
		if (relatorio === "Relatório de Romaneio" || relatorio === "Romaneio") {
			const orderData = await orderService.getOrder(orderId);
			const orderItems = orderData?.itemPedidos;
			let auxData = [];
			for (let i = 0; i < orderItems.length; i++) {
				const item = orderItems[i];
				const orderItemsHarvests = item.lotes;
				for (let j = 0; j < orderItemsHarvests.length; j++) {
					const orderItemsHarvest = orderItemsHarvests[j];
					const harvest = await orderItemsHarvest.lote;

					auxData.push({
						id: harvest.id,
						value: `localhost:3001?id=${item.codigo}-${harvest.codigo}`,
					});
				}
			}
			setQrCodeDataRomaneio(auxData);
			setQrCodeData([
				...qrCodeData,
				{
					id: 1,
					value: `localhost:3001/rastreabilidade?id=${orderData.numeroPedido}`,
				},
			]);
		}
		else if (
			relatorio === "GerarQrcodeTalhao" ||
			relatorio === "Qr code Talhão"
		) {
			setQrCodeData([
				...qrCodeData,
				{
					id: 1,
					value: `talhao/${item}`,
				},
			]);
		}
		else if (
			relatorio === "QrcodePropriedade" ||
			relatorio === "Qr code Propriedade"
		) {

			setQrCodeData([
				...qrCodeData,
				{
					id: 1,
					value: `${process.env.REACT_APP_FRONTEND_URL}/rastreabilidade?pId=${property.id}`,
				},
			]);
		}
		else if (
			relatorio === "GerarQrcodeSubdiv" ||
			relatorio === "Qr code SUbDivisão"
		) {
			setQrCodeData([
				...qrCodeData,
				{
					id: 1,
					value: `subdivisao/${item}`,
				},
			]);
		} else {
			const orderItem = await await orderService.getOrderItem(orderId);
			const harvestData = orderItem.lotes.find((harvestItem) => harvestItem.loteId === itemUrl);
			setQrCodeData([
				...qrCodeData,
				{
					id: 1,
					value: `${process.env.REACT_APP_FRONTEND_URL}/rastreabilidade?id=${orderItem.codigo}-${harvestData.lote.codigo}`,
				},
			]);
		}
	}
	const qrCodeIds = qrCodeData.map((data) => data.id);
	const qrCodeIdsRomaneio = qrCodeDataRomaneio.map((data) => data.id);
	return (
		<div>
			<header className={styles.containerLogo}>
				<img className={styles.imgLogo} src={Logo} />
			</header>
			<Box hidden>
				{/* hidden */}
				{qrCodeData.map((data) => {
					if (
						relatorio === "Etiqueta 16 x 5" ||
						relatorio === "GerarQrcodeEtiqueta16x5"
					) {
						return (
							<div key={data.id}>
								<QRGenerator value={data.value} documentId={data.id} />
							</div>
						);
					} else {
						return (
							<div key={data.id}>
								<QRGenerator value={data.value} documentId={data.id} margin={true} />
							</div>
						);
					}
				})}
				{qrCodeDataRomaneio.map((data) => {
					return (
						<div key={data.id}>
							<QRGenerator value={data.value} documentId={data.id} margin={true} />
						</div>
					);
				})}
			</Box>
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 2 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<a className={styles.headerRegisterAreaButton} href={hrefRetorno}>
										<ArrowBackIcon />
									</a>
									<h1 className={styles.headerRegisterAreaTitle}>{relatorio}</h1>
								</Grid>
								{(relatorio === "GerarQrcodeTalhao" || relatorio === "Qr code Talhão" || relatorio === "GerarQrcodeSubdiv" || relatorio === "Qr code SUbDivisão")  && (
									<Grid
										item
										xs={6}
										sm={6}
										md={3}
										sx={{
											display: "flex",
											justifyContent: "end",
										}}
									>
										<button
											className={styles.buttonDownloadQrCode}
											onClick={handleOpenMOdal}
										>
												Somente Qr Code
										</button>
									</Grid>
								)}
								<Grid
									item
									xs={6}
									sm={6}
									md={
										relatorio === "GerarQrcodeTalhao" || relatorio === "Qr code Talhão" || relatorio === "GerarQrcodeSubdiv" ||	relatorio === "Qr code SUbDivisão"
											? 3
											: 6
									}
									sx={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									<div>
										<PDFDownloadLink
											document={
												<Components
													item={item}
													pedido={pedido}
													ids={qrCodeIds}
													codBar1Type={codBar1Type}
													codBar2Type={codBar2Type}
													IdsRomaneio={qrCodeIdsRomaneio}
													checkDist={checkDist}
													doubleTag={doubleTag}
													talhao={item}
												/>
											}
											fileName={relatorio}
											className={styles.buttonDownload}
										>
											{({ loading }) =>
												loading ? (
													<button className={styles.buttonDownload}>
														<div className={styles.ldsroller}>
															<div></div>
															<div></div>
															<div></div>
															<div></div>
															<div></div>
															<div></div>
															<div></div>
															<div></div>
														</div>
														Carregando documento...
													</button>
												) : (
													<button className={styles.buttonDownload}>Download Pdf</button>
												)
											}
										</PDFDownloadLink>
									</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12} sx={{ height: "80vh" }}>
							<PDFViewer
								style={{
									width: "100%",
									height: "100%",
									border: "none",
									boxShadow: "none",
									backgroundColor: "#525659",
								}}
							>
								{
									<Components
										item={item}
										pedido={pedido}
										ids={qrCodeIds}
										codBar1Type={codBar1Type}
										codBar2Type={codBar2Type}
										IdsRomaneio={qrCodeIdsRomaneio}
										checkDist={checkDist}
										doubleTag={doubleTag}
										talhao={item}
									/>
								}
							</PDFViewer>
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Modal
				open={openModal}
				onClose={handleCloseData}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
						m: 0,
					}}
				>
					<Grid container rowSpacing={3} columnSpacing={3} direction={"column"}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<p className={styles.registrationTitle}>Qr Code</p>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{qrCodeData.map((data) => {
								if (
									relatorio === "Etiqueta 16 x 5" ||
									relatorio === "GerarQrcodeEtiqueta16x5" ||
									relatorio === "Etiqueta 12 x 5" ||
									relatorio === "GerarQrcodeEtiqueta12x5" ||
									relatorio === "Etiqueta 9 x 3" ||
									relatorio === "GerarQrcodeEtiqueta9x3"
								) {
									return (
										<div
											key={data.id}
											ref={canvasRef}
											style={{
												width: 250,
												height: 250,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<p>
												<b>{talhao}</b>
											</p>
											<QRGenerator value={data.value} documentId={data.id} />
											<p>
												<b>Sisagri</b>
											</p>
										</div>
									);
								} else {
									return (
										<div
											key={data.id}
											ref={canvasRef}
											style={{
												width: 250,
												height: 250,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<p>
												<b>{talhao}</b>
											</p>
											<QRGenerator value={data.value} documentId={data.id} />
											<p>
												<b>Sisagri</b>
											</p>
										</div>
									);
								}
							})}
						</Grid>

						<Grid item xs={12} sm={12} md={12} sx={{ marginTop: 5 }}>
							<Grid
								container
								rowSpacing={{ xs: 2, sm: 1, md: 0 }}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								display="flex"
								alignItems="center"
								justifyContent="end"
								flexDirection={{
									xs: "column-reverse",
									sm: "column-reverse",
									md: "row",
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseData}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={() => printDocument(canvasRef.current)}
									>
										Baixar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default PdfQrcodeFile;
