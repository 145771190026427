import { ClientRepository } from "../repository/client_repository";

export class ClientService {

	constructor() {
		this.clientRepository = new ClientRepository();
	}

	async get(propertyId, params) {
		const clients = await this.clientRepository.get(propertyId, params);
		return clients.clients;
	}

}