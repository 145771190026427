import React, { useState } from "react";
import TopMenu from "../../components/TopMenu";
import InputSearch from "../../components/SearchHeader/inputSeach";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//column
const columns = {
	nome: "Nome",
	area: "Área",
};
const data = [
	{
		id: 1,
		nome: "Ethyl",
		area: "21,000ha",
	},
	{
		id: 2,
		nome: "Midge",
		area: "21,000ha",
	},
	{
		id: 3,
		nome: "Robinette",
		area: "21,000ha",
	},
	{
		id: 4,
		nome: "Zita",
		area: "21,000ha",
	},
	{
		id: 5,
		nome: "Giffer",
		area: "21,000ha",
	},
	{
		id: 6,
		nome: "Allison",
		area: "21,000ha",
	},
	{
		id: 7,
		nome: "Bartel",
		area: "21,000ha",
	},
	{
		id: 8,
		nome: "Gerrie",
		area: "21,000ha",
	},
	{
		id: 9,
		nome: "Alic",
		area: "21,000ha",
	},
	{
		id: 10,
		nome: "Normand",
		area: "21,000ha",
	},
	{
		id: 11,
		nome: "Cele",
		area: "21,000ha",
	},
	{
		id: 12,
		nome: "Renault",
		area: "21,000ha",
	},
	{
		id: 13,
		nome: "Matthaeus",
		area: "21,000ha",
	},
	{
		id: 14,
		nome: "Delainey",
		area: "21,000ha",
	},
	{
		id: 15,
		nome: "Hamilton",
		area: "21,000ha",
	},
	{
		id: 16,
		nome: "Janka",
		area: "21,000ha",
	},
	{
		id: 17,
		nome: "Etan",
		area: "21,000ha",
	},
	{
		id: 18,
		nome: "Norman",
		area: "21,000ha",
	},
	{
		id: 19,
		nome: "Jaimie",
		area: "21,000ha",
	},
	{
		id: 20,
		nome: "Yves",
		area: "21,000ha",
	},
	{
		id: 21,
		nome: "Lilly",
		area: "21,000ha",
	},
	{
		id: 22,
		nome: "Rea",
		area: "21,000ha",
	},
	{
		id: 23,
		nome: "Damon",
		area: "21,000ha",
	},
	{
		id: 24,
		nome: "Nanny",
		area: "21,000ha",
	},
	{
		id: 25,
		nome: "Ralph",
		area: "21,000ha",
	},
	{
		id: 26,
		nome: "Lara",
		area: "21,000ha",
	},
	{
		id: 27,
		nome: "Eirena",
		area: "21,000ha",
	},
	{
		id: 28,
		nome: "Constance",
		area: "21,000ha",
	},
	{
		id: 29,
		nome: "Robyn",
		area: "21,000ha",
	},
	{
		id: 30,
		nome: "Selie",
		area: "21,000ha",
	},
	{
		id: 31,
		nome: "Mortimer",
		area: "21,000ha",
	},
	{
		id: 32,
		nome: "Bertie",
		area: "21,000ha",
	},
	{
		id: 33,
		nome: "Nanette",
		area: "21,000ha",
	},
	{
		id: 34,
		nome: "Brigida",
		area: "21,000ha",
	},
	{
		id: 35,
		nome: "Eldon",
		area: "21,000ha",
	},
	{
		id: 36,
		nome: "Tallia",
		area: "21,000ha",
	},
	{
		id: 37,
		nome: "Skyler",
		area: "21,000ha",
	},
	{
		id: 38,
		nome: "Trula",
		area: "21,000ha",
	},
	{
		id: 39,
		nome: "Johannes",
		area: "21,000ha",
	},
	{
		id: 40,
		nome: "Florrie",
		area: "21,000ha",
	},
	{
		id: 41,
		nome: "Clementine",
		area: "21,000ha",
	},
	{
		id: 42,
		nome: "Muffin",
		area: "21,000ha",
	},
	{
		id: 43,
		nome: "Jose",
		area: "21,000ha",
	},
	{
		id: 44,
		nome: "Garold",
		area: "21,000ha",
	},
	{
		id: 45,
		nome: "Gregorio",
		area: "21,000ha",
	},
	{
		id: 46,
		nome: "Englebert",
		area: "21,000ha",
	},
	{
		id: 47,
		nome: "Kath",
		area: "21,000ha",
	},
	{
		id: 48,
		nome: "Dorolice",
		area: "21,000ha",
	},
	{
		id: 49,
		nome: "Chev",
		area: "21,000ha",
	},
	{
		id: 50,
		nome: "Binnie",
		area: "21,000ha",
	},
];

function Portion() {
	const [search, setSearch] = useState("");

	const dataFilter = data.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	return (
		<div className="genericPage">
			<TopMenu open={["register", "property"]} />
			<div className="genericPageContent">
				<Search title="Subdivisões" href="parcelaCad" textButton="Nova Parcela">
					<InputSearch func={handleClick} />
				</Search>
				<Table data={dataFilter} columns={columns} />
			</div>
		</div>
	);
}

export default Portion;
