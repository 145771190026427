import { Box, Grid, Modal } from "@mui/material";
import React, { memo } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};

function ModalC(props) {
  
	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			className={props.classN}
		>
			<Box
				sx={{
					...style,
					width: { xs: "90%", sm: "50%", md: "50%" },
					bgcolor: "white",
					borderRadius: "8px",
					p: 3,
					m: 0,
				}}
			>
				<Grid
					container
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					className={props.gridClassName}
					display="flex"
					justifyContent="center"
					alignItems="ccenter"
					width={"auto"}
					padding={0}
					margin={0}
				>
					{props.children}
					<Grid item xs={12} sm={12} md={12}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 1, md: 1 }}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "end",
								flexDirection: {
									xs: "column-reverse",
									sm: "column-reverse",
									md: "row",
								},
							}}
						>
							<Grid item xs={12} sm={12} md={3}>
								<button
									type="button"
									onClick={props.onClickButtonCancel}
									className={props.buttonCancelClassName}
								>
										Cancelar
								</button>
							</Grid>
							<Grid item xs={12} sm={12} md={3}>
								<button
									className={props.buttonCadastraClassName}
									type="button"
									onClick={props.onClickButtonCadastrar}
								>
										Cadastrar
								</button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
export default memo(ModalC);