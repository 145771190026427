import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { ReportService } from "../../../../service/report_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "10px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "12px",
		marginBottom: "3px",
	},
	textNormal: {
		fontSize: "12px",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		fontFamily: "Roboto bold",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
});

const PdfEstoqueInsumos = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);

	const reportService = new ReportService();

	const safraID = props.safraId;
	// const talhaoID = props.talhaoId;
	// const grupoInsumoID = props.grupoInsumoId;
	const insumoID = props.insumoId;
	const logo = props.logo;

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			propertyInput: String(insumoID) !== "-1" && String(insumoID) !== "0" ? insumoID : null,
			property: propertyId,
			cropSeason: String(safraID) !== "-1" &&  String(safraID) !== "0" ? safraID : null,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const stockData = await reportService.getStockData(reportProps);

		setData(stockData);
		setLoading(false);
	};

	return (
		!loading ? 
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>Estoque de Insumos</Text>
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>Safra:</Text>
							<Text style={styles.textNormal}>
								{
									data.safra ? 
										data.safra.nome :
										"Todas as safras"
								}
							</Text>

							<Text style={styles.textBold}>Produto:</Text>
							<Text style={styles.textNormal}>
								{data.propriedadeInsumo?.nome ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Grupo:</Text>
							<Text style={styles.textNormal}>
								{data.propriedadeInsumo?.grupo ?? "Todos"}
							</Text>
							<Text style={styles.textBold}>Período: </Text>
							<Text style={styles.textNormal}>
								{new Date(props.startDate).toLocaleDateString("pt-BR")} a{" "}
								{new Date(props.finalDate).toLocaleDateString("pt-BR")}
							</Text>
						</View>
						<View style={styles.containerTotal}>
							<Text style={styles.textBold}>Total Entrada</Text>
							<Text style={styles.textBoldBig}>{data.dados.totalEntrada ?? 0}</Text>
							<Text style={styles.textBold}>Total Saida</Text>
							<Text style={styles.textBoldBig}>{data.dados.totalSaida ?? 0}</Text>
							<Text style={styles.textBold}>Total em Saldo</Text>
							<Text style={styles.textBoldBig}>{data.dados.saldo ?? 0}</Text>
						</View>
					</View>

					<View style={styles.body}>
						<View style={styles.mainTitle}>
							<Text>Entrada</Text>
							<Text>
								{`Total de entradas: ${data.dados.totalEntrada ?? 0}`}
							</Text>
						</View>
						<View style={styles.content}>
							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Data Entrada</Text>
								<Text style={styles.larguraTexto}>Insumo</Text>
								<Text style={styles.larguraTexto}>Grupo</Text>
								<Text style={styles.larguraTexto}>Volume</Text>
								<Text style={styles.larguraTexto}>Capacidade</Text>
								<Text style={styles.larguraTexto}>Unidade</Text>{" "}
								<Text style={styles.larguraTexto}>Quant.:</Text>
							</View>
							{data.dados.input.map((entrada, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{entrada.date}</Text>
										<Text style={styles.larguraTexto}>{entrada.product.insumo.nome}</Text>
										<Text style={styles.larguraTexto}>{entrada.product.grupoInsumo.nome}</Text>
										<Text style={styles.larguraTexto}>{entrada.volume}</Text>
										<Text style={styles.larguraTexto}>{entrada.capacity}</Text>
										<Text style={styles.larguraTexto}>{entrada.unity.sigla}</Text>
										<Text style={styles.larguraTexto}>
											{entrada.quantity} {entrada.unity.sigla}
										</Text>
									</View>
								);
							})}
						</View>
					</View>
					<View style={styles.body}>
						<View style={styles.mainTitle}>
							<Text>Saida</Text>
							<Text>
								{`Total de saídas: ${data.dados.totalSaida ?? 0}`}
							</Text>
						</View>
						<View style={styles.content}>
							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Data Retirada</Text>
								<Text style={styles.larguraTexto}>Insumo</Text>
								<Text style={styles.larguraTexto}>Grupo</Text>
								<Text style={styles.larguraTexto}>Unidade</Text>{" "}
								<Text style={styles.larguraTexto}>Quant.:</Text>
							</View>
							{data.dados.output.map((s, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{s.date}</Text>
										<Text style={styles.larguraTexto}>{s.product.insumo.nome}</Text>
										<Text style={styles.larguraTexto}>{s.product.grupoInsumo.nome}</Text>
										<Text style={styles.larguraTexto}>{s.unity.sigla}</Text>
										<Text style={styles.larguraTexto}>
											{s.quantity} {s.unity.sigla}
										</Text>
									</View>
								);
							})}
						</View>
					</View>
					<View style={styles.body}>
						<View style={styles.mainTitle}>
							<Text>Saldo</Text>
							<Text>
								{`Total em estoque: ${data.dados.saldo ?? 0}`}
							</Text>
						</View>
						<View style={styles.content}>
							<View style={styles.tableTitle}>
								<Text style={styles.larguraTexto}>Última Retirada</Text>
								<Text style={styles.larguraTexto}>Insumo</Text>
								<Text style={styles.larguraTexto}>Grupo</Text>
								<Text style={styles.larguraTexto}>Em Estoque</Text>
								<Text style={styles.larguraTexto}>Retirada</Text>
								<Text style={styles.larguraTexto}>Unidade</Text>
								<Text style={styles.larguraTexto}>Saldo:</Text>
							</View>
							{data.dados.balance.map((saldo, index) => {
								return (
									<View key={index} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>
											{saldo.date}
										</Text>
										<Text style={styles.larguraTexto}>{saldo.product.insumo.nome}</Text>
										<Text style={styles.larguraTexto}>{saldo.product.grupoInsumo.nome}</Text>
										<Text style={styles.larguraTexto}>{saldo.input}</Text>
										<Text style={styles.larguraTexto}>{saldo.output}</Text>
										<Text style={styles.larguraTexto}>{saldo.unity.sigla}</Text>
										<Text style={styles.larguraTexto}>
											{saldo.quantity} {saldo.unity.sigla}
											{/* //saldo.unidEstoque - saldo.retirada < 0 ? 0 : saldo.unidEstoque - saldo.retirada  */}
										</Text>
									</View>
								);
							})}
						</View>
					</View>

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document> 
			: <Document></Document>
	);
};
export default PdfEstoqueInsumos;
