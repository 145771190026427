export const captalizeFirstLetterEacheWord = (phrase) => {
	if(phrase === null || phrase === undefined || phrase === "")
		return "";
	const phraseParts = phrase.split(" ");
	let captalizedPhrase = "";
	for(let i = 0; i < phraseParts.length; i++){
		const word = phraseParts[i];
		if(word.length > 0){
			const captalizedWord = word[0].toUpperCase() + word.substring(1).toLowerCase();
			captalizedPhrase += captalizedWord;
		}
		if(Number(i) !== Number(phraseParts.length - 1))
			captalizedPhrase += " ";
	}
	return captalizedPhrase;
};

export const getDateWithoutTimezone = (date) => {
	let dateWithoutTimezone = new Date(date);
	var userTimezoneOffset = dateWithoutTimezone.getTimezoneOffset() * 60000;
	dateWithoutTimezone = new Date(dateWithoutTimezone.getTime() + userTimezoneOffset);
	return dateWithoutTimezone;
};