import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "../../LandingPage.module.css";

import "../../LandingPage.module.css";
import { SettingsService } from "../../../../service/settings_service";

function CompanyPresentation(props) {
	const [files, setFiles] = useState([]);
	const propriedade = props.propriedade;

	const settingsService = new SettingsService();

	useEffect(() => {
		downloadFiles();
	}, [propriedade]);

	const downloadFiles = async () => {
		try {
			const initiFiles = [];
			
			if(propriedade?.id !== null){
				const filesInStorage = await settingsService.getPropertyGalleryImages(propriedade?.id);

				for (let i = 0; i < filesInStorage.length; i++) {
					const fileInStorage = filesInStorage[i];
					const blob = await fetch(fileInStorage).then(r => r.blob());
					const imgDataUrl = URL.createObjectURL(blob);

					initiFiles.push(
						{
							original: imgDataUrl,
							thumbnail: imgDataUrl,
							originalHeight:"500px",
							originalWidth: "500px",
							ffullscreen :imgDataUrl,
						}
					);
				}
			}

			setFiles(initiFiles);
		} catch (error) {
			console.error("Erro ao baixar os arquivos:", error);
		}
		
	};

	return (
		<div>
			<Box
				component="main"
				sx={{
					width: "100%",
					height: "80vh",
					m: 0,
					p: 0,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={0}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						minHeight: "80vh",
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "15%",
						}}
					>
						<Typography
							variant="h3"
							className={styles.titleTraceabilityProdut}
							sx={{
								fontWeight: "800",
								color: "#009199",
							}}
						>
							{propriedade?.nome}
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80%",
							minHeight:"320px",
							width: "100%",
							minWidth: "320px",
							maxWidth: "500px",
							mt: 9,
							zIndex:"1"
						}}
					>
						<ImageGallery 
							originalHeight={"500px"}
							originalWidth={"500px"}
							items={files}
							showBullets={true}
							showIndex={true}
							showThumbnails={true}
							lazyLoad={false}
							showPlayButton={true}
							showNav={true}
							showFullscreenButton={true}
							thumbnailPosition={"left"}
						/>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
export default CompanyPresentation;
