import { MeasurementUnitRepository } from "../repository/measurement_unit_repository";

export class MeasurementUnitService {

	constructor() {
		this.measurementUnitRepository = new MeasurementUnitRepository();
	}

	async get() {
		const measurementUnitData = await this.measurementUnitRepository.get();
		return measurementUnitData;
	}
}