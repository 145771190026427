import React from "react";
import styles from "./loading.module.css";
import logo from "../../Assets/images/logoHeaderPdf.png";

function Loading() {
	return (
		<div className={styles.container}>
			<img className={styles.logo} src={logo} alt="sisagri" />
			<h1 className={styles.text}>Carregando</h1>

			<div className={styles.ldsroller}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default Loading;
