import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";

//import components
import * as Btn from "../../components/Buttons";
import { IconIosShare } from "../../components/IconsImport";
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";
//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { PropertyInputService } from "../../service/property_input_service";
import Loading from "../../components/Loading";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
//column
const columns = [
	{
		id: "nome",
		label: "nome",
	},
	{
		id: "unidadeMedida",
		label: "Unidade",
	},
	{
		id: "grupo",
		label: "Grupo",
	},
	{
		id: "preco",
		label: "Preço",
	},
	{
		id: "intervalo",
		label: "Intervalo para nova aplicação",
	},
	{
		id: "carencia",
		label: "Carência para colheita",
	},
]; 

function Insumos() {
	const [search, setSearch] = useState("");
	const [insumos, setInsumos] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const propertyInputService = new PropertyInputService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const inputParams = {
			page: 1,
			limit: 100000,
		};
		let inputsInProperty = await propertyInputService.get(propertyId, inputParams);

		inputsInProperty = inputsInProperty.map((inputInProperty) => {
			const intervaloReaplicacao = inputInProperty.careanciaReaplicacaoDias ?? 0;
			const carenciaColheita = inputInProperty.carenciaColheitasDias ?? 0;
			return {
				id: inputInProperty.id,
				nome: inputInProperty.insumoEmGrupo.insumo.nome,
				grupo: inputInProperty.insumoEmGrupo.grupoInsumo.nome,
				unidadeMedida: inputInProperty.unidadeMedida.sigla,
				preco: (inputInProperty.custoUnitario ?? 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL",
				}),
				intervalo: `${intervaloReaplicacao} ${intervaloReaplicacao !== 1 ? "dias" : "dia"}`,
				carencia: `${carenciaColheita} ${carenciaColheita !== 1 ? "dias" : "dia"}`,
			};
		});
		setInsumos(inputsInProperty);
		setIsLoading(false);
	};

	const dataFilter = insumos.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	//check delete function
	const handleDelete = async (id) => {
		await propertyInputService.changeVisibility(id);
		initData();
	};

	const generateInputReport = () => {
		return (window.location.href = "/pdf/?pag=Insumos");
	};
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["register", "insumos"]} />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Insumos"
										href="/insumos/cadastroInsumo"
										textButton="Novo Insumo"
										help="insumos"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Table
										listSize={dataFilter.length}
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref="/insumos/cadastroInsumo?id="
									>
										<Btn.GenericButtonGreen
											text="Exportar lista de produtos"
											funcClick={generateInputReport}
											icon={
												<div>
													<IconIosShare />
												</div>
											}
										/>
									</Table>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default Insumos;
