import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import styles from "../../SettingsPag.module.css";
import { SettingsService } from "../../../../service/settings_service.js";

function Evaluation() {
	const [dataEvaluation, setDataEvaluation] = useState([]);

	const settingsService = new SettingsService();

	useEffect(() => {
		initData();
	}, []);
	
	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const reviews = await settingsService.getReviews(propertyId);
		setDataEvaluation(reviews);
	};
	return (
		<>
			<Box component="main">
				<Box
					sx={{
						width: "100%",
						minWidth: { xs: "100vw", sm: "66.666667vw", md: "75vw" },
						height: "90vh",
						bgcolor: "white",
						m: 0,
						mt: 2,
						borderRadius: 8,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={0}
						sx={{
							display: "flex",
							width: "100%",
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							p: 3,
						}}
					>
						<List
							sx={{
								width: "100%",
								bgcolor: "background.paper",
								overflowY: "auto",
								height: "100%",
								maxHeight: "80vh",
							}}
						>
							{dataEvaluation.map((item, index) => {
								return (
									<>
										<ListItem alignItems="flex-start" key={index}>
											<ListItemText
												primary={item.nome ?? "Não identificado"}
												secondary={
													<div className={styles.comentarioList}>
														<React.Fragment>
															<Typography
																sx={{ display: "inline" }}
																component="span"
																variant="body2"
																color="text.primary"
															></Typography>
															{(!item.comentario || item.comentario === "") ? (
																<Rating
																	name="text-feedback"
																	value={item.nota ?? 0}
																	readOnly
																	precision={0.5}
																	emptyIcon={
																		<StarIcon
																			style={{ opacity: 0.55 }}
																			fontSize="inherit"
																		/>
																	}
																/>
															) : (
																<>
																	Comentario:
																	{item.comentario}
																	<Rating
																		name="text-feedback"
																		value={item.nota ?? 0}
																		readOnly
																		precision={0.5}
																		emptyIcon={
																			<StarIcon
																				style={{ opacity: 0.55 }}
																				fontSize="inherit"
																			/>
																		}
																	/>
																</>
															)}
														</React.Fragment>
													</div>
												}
											/>
										</ListItem>
										<Divider variant="inset" component="li" />
									</>
								);
							})}
						</List>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default Evaluation;
