import { NotificationRepository } from "../repository/notification_repository";

export class NotificationService {

	constructor() {
		this.notificationRepository = new NotificationRepository();
	}

	async get(propertyId) {
		const notifications = await this.notificationRepository.get(propertyId);
		return notifications;
	}
}