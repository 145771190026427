import { GroupServiceRepository } from "../repository/group_service_repository";

export class GroupServiceService {

	constructor() {
		this.groupServiceRepository = new GroupServiceRepository();
	}

	async get() {
		return await this.groupServiceRepository.get();
	}

	async getServices() {
		return await this.groupServiceRepository.getServices();
	}
}