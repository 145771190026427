import AirIcon from "@mui/icons-material/Air";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import bigcloud from "../../Assets/images/cloudy 1.png";

//import css modules
/* import "./style.css"; */
import styles from "./Climate.module.css";

//material ul imports
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { PropertyService } from "../../service/property_service";
const apiKey = "1d6281103672ab5c8333c74688c6deef";
//API key: 1d6281103672ab5c8333c74688c6deef
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	height: "100%",		
	m: 0,
	p: 0,
};
export default function Climate() {
	const [clima, setClima] = useState({
		cidade: "-",
		temperatura: "-",
		vento: "-",
		umidade: "-",
		icon:  "-",
	});
	const [climaSemanal, setClimaSemanal] = useState([]);
	const [mensage, setMensage] = useState(false);

	const propertyService = new PropertyService();

	useEffect(() => {
		init();		
	}, []);

	const init = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const property = await propertyService.getById(propertyId);

		const coordenadas = property.propriedadeCoordenada;
		if (coordenadas[0]?.latitude) {
			const lat = coordenadas[0].latitude;
			const lon = coordenadas[0].longitude;
			const callHoje = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=pt_br&units=metric`;
			// const callHoje = `http://api.openweathermap.org/data/2.5/forecast/climate?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=pt_br&units=metric`;
			const callSemana = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&cnt=32&appid=${apiKey}&lang=pt_br&units=metric`;
			fetchData(callHoje);
			fetchData(callSemana);
			setMensage(true);
		} else { setMensage(false); }
	};

	const fetchData = async (call) => {
		try {
			const response = await axios.get(call);
			getClima(response.data);
			
		} catch (error) {
			console.error(error);
		}
	};
	
	const getClima = async (data) => {
		if(data.list === undefined){
			const clima = await data;
			setClima({
				cidade: clima.name,
				temperatura: Math.floor(clima.main.temp),
				vento: Math.floor(clima.wind.speed * 3.6),
				umidade: clima.main.humidity,
				icon:  `https://openweathermap.org/img/wn/${clima.weather[0].icon}@2x.png`,
			});
		} else {
			const climaSemana = await data.list;
			
			setClimaSemanal([
				{
					diaSemana:diaDaSemana(climaSemana[6].dt_txt),
					temperatura:  Math.floor(climaSemana[6].main.temp),					
					icon: `https://openweathermap.org/img/wn/${climaSemana[6].weather[0].icon}@2x.png`,
				},
				{
					diaSemana:diaDaSemana(climaSemana[15].dt_txt),
					temperatura:  Math.floor(climaSemana[15].main.temp),					
					icon: `https://openweathermap.org/img/wn/${climaSemana[15].weather[0].icon}@2x.png`,
				},
				{
					diaSemana:diaDaSemana(climaSemana[23].dt_txt),
					temperatura:  Math.floor(climaSemana[23].main.temp),					
					icon: `https://openweathermap.org/img/wn/${climaSemana[23].weather[0].icon}@2x.png`,
				},
				{
					diaSemana:diaDaSemana(climaSemana[31].dt_txt),
					temperatura: Math.floor(climaSemana[31].main.temp),					
					icon: `https://openweathermap.org/img/wn/${climaSemana[31].weather[0].icon}@2x.png`,
				}
			]);
		}
		
	};
	const diaDaSemana = (date) => {
		const dataAtual = new Date(date);
		const opcoesData = { weekday: "short"};
		const diaSemana = dataAtual.toLocaleString("pt-BR", opcoesData);
		return (diaSemana);
	};
	return (
		<Box sx={{ width: "100%" }}>
			{mensage ? (
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							alignItems: "stretch",
						}}
					>
						<Item
							sx={
								(stylesItem,
								{
									width: "100%",
								})
							}
						>
							<div className={styles.topContainer}>
								<LocationOnIcon /> <p>{clima.cidade}</p>
							</div>
							{/* <img className={styles.imagemContainer} src={bigcloud}/> */}
							<img  src={"https://openweathermap.org/img/wn/04d@2x.png"}/>
							<p className={styles.temperature}>{clima.temperatura}º</p>
						</Item>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							alignItems: "stretch",
						}}
					>
						<Item
							sx={
								(stylesItem,
								{
									width: "100%",
								})
							}
						>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Item sx={stylesItem}>
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid item xs={12} sm={4}>
												<Item sx={stylesItem}>
													<div className={styles.informationsContent}>
														<AirIcon />
														<p className={styles.fontBold}>{clima.vento}km/h</p>
														<p>Vento</p>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={4}>
												<Item sx={stylesItem}>
													<div className={styles.informationsContent}>
														<InvertColorsIcon />
														<p className={styles.fontBold}>{clima.umidade}%</p>
														<p>Umidade</p>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={4}>
												<Item sx={stylesItem}>
													<div className={styles.informationsContent}>
														<ThunderstormIcon />
														<p className={styles.fontBold}>-%</p>
														<p>Chuva</p>
													</div>
												</Item>
											</Grid>
										</Grid>
									</Item>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Item sx={stylesItem}>
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											{climaSemanal.map((i, index ) => {
												return (
													<Grid item xs={12} sm={6} md={3} key={index}>
														<Item
															sx={{
																borderRadius: "8px" ,
																p: 0,
																m: 0,
															}}
														>
															<p className={styles.fontBold}>{i.diaSemana}</p>
															<img src={i.icon} className={styles.imagemContainer} />
															<p className={styles.fontBold}>{i.temperatura}º</p>
														</Item>
													</Grid>
												);
											})}
										
										</Grid>
									</Item>
								</Grid>
							</Grid>
						</Item>
					</Grid>
				</Grid>
			) : (
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							alignItems: "stretch",
							justifyContent: "center"
						}}
					>
						<Item
							sx={
								(stylesItem,
								{
									width: "100%",
									minHeight:"50vh",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								})
							}
						>
							<Typography variant="h4" >É necessário cadastrar a área da propriedades para ter acesso ao clima.</Typography>
						</Item>
					</Grid>
				</Grid>
			) }
		
		</Box>
	);
}
