import React from "react";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "10px",
		paddingTop: "10px",
		paddingLeft: "10px",
		paddingRight: "10px",
	},
	body: {
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	containerText: {
		marginBottom: "5px",
	},
	containerQrcode: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	qrCode: {
		width: "auto",
	},
	barCode: {
		width: "70px",
	},
	qrCodeContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "black",
	},
	qrCodeContainerTexTitle: {
		fontSize: "84px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	qrCodeContainerTextSubTitle: {
		fontSize: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "20px",
		bottom: 0,
	},

	containerFooter: {
		width: "100vw",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		width: "100%",
		backgroundColor: "black",
		height: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "4px",
		paddingHorizontal: "5px",
		fontFamily: "Roboto bold",
		color: "white",
	},
	imgFooterInvetida: {
		height: "100%",
		width: "auto",
		transform: "scaleX(-1)",
	},
	imgFooter: {
		height: "100%",
		width: "auto",
	},
	// textTransform
	TextBoldTitle: {
		fontFamily: "Roboto bold",
		fontSize: "9px",
	},
	TextBoldSubTitle: {
		fontFamily: "Roboto bold",
		fontSize: "7px",
	},
	TextBold: {
		fontFamily: "Roboto bold",
		fontSize: "6px",
	},
	TextNormal: {
		fontSize: "6px",
	},
	TextNormalQrCode: {
		fontSize: "4px",
	},
});

const PdfQrOnly = (props) => {
	const ids =props.ids;

	function QRCodePage({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();

		return (
			<Page
				key={`page_${id}`}
				size={{ width: 	75.59, height: 	75.59 }}
				style={styles.page}
			>
				<View style={styles.body}>
					<View style={styles.qrCodeContainer}>
				
						{/* lado direito da etiqueta */}
						<View style={styles.containerQrcode}>
							<Text style={styles.TextNormalQrCode}>CONHEÇA A ORIGEM</Text>
							<Text style={styles.TextNormalQrCode}>DO PRODUTO</Text>
							<Image src={dataUrl} style={styles.qrCode} />
							<Text style={styles.TextNormalQrCode}>SISAGRI</Text>
						</View>
						{/* <View ref={ref} /> */}
					</View>
				</View>
			
			</Page>
		);
	}

	return (
		<Document>
			{ids.map((id) => (
				<QRCodePage id={id} key={id} />
			))}
		</Document>
	);
};

export default PdfQrOnly;
