import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Grid, Typography } from "@mui/material";
// import { Storage } from "aws-amplify";
import React, { memo, useEffect, useState } from "react";
import styles from "../../LandingPage.module.css";
import { SettingsService } from "../../../../service/settings_service";

const iconStyle = {
	height: " 45px",
	width: "45px",
	color: "#009199",
};

function AboutOrigin(props) {
	const [files, setFiles] = useState([]);
	const itemPedido = props.itemPedido;
	const lote = props.lote;
	const propriedade = (itemPedido!==null && itemPedido !== "" ) ? lote.propriedadeCultivo?.propriedade : props.propriedade;
	const dadosPortal = propriedade?.DadosPortalRastreabilidade;
	const redeSocial = propriedade?.redeSocial;
	const empresa = propriedade?.empresa;
	const endereco = propriedade?.endereco;

	const settingsService = new SettingsService();

	useEffect(() => {
		downloadFiles();
	}, [propriedade]);
	const downloadFiles = async () => {

		try {
			const base64 = await settingsService.getTraceabilityImage(propriedade?.id);

			if(base64 && base64 !== ""){
				const mimeType = getMimeType(base64);
				const binaryString = atob(base64, "base64");
	
				let bytes = new Uint8Array(binaryString.length);
				for (let i = 0; i < binaryString.length; i++)
					bytes[i] = binaryString.charCodeAt(i);
	
				const blob = new Blob([bytes], { type: mimeType });
				const imgDataUrl = URL.createObjectURL(blob);
	
				setFiles(imgDataUrl);
			}
		} catch (error) {
			console.error("Erro ao baixar os arquivos:", error);
		}
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: "100%",
				minHeight: "80vh",
				m: 0,
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				flexDirection={"row"}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					minHeight: "80vh",
					p: 0,
					m: 0,
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
							height: "100%",
							minHeight: "80vh",
							width: "350px",
							minWidth: "220px",
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							SX={{
								// display: "flex",	
								justifyContent: "center	",
								alignItems: "center",
								height: "90%",
								width: "100%",
							}}
						>
							<img src={files} className={styles.imgProdutor}  />
							<div className={styles.containerDescricao}>
								<p>
									<b>{dadosPortal?.tituloImagem}</b>
								</p>
								<p>{dadosPortal?.subtituloImagem}</p>
								<a href={redeSocial?.whatsapp} className={styles.buttonAboutOrigin}>
									<button  className={styles.buttonAboutOrigin}>
									Fale com o produtor
									</button>
								</a>
								
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							alignItems: "center",
							py: 5,
							px: { xs: 5, sm: 10 },
							height: "100%",
							minHeight: "80vh",
							width: "100%",
						}}
					>
						<Grid item xs={12} sm={12} md={12} sx={{ mb: "20px" }}>
							<Typography
								variant="body1"
								sx={{
									fontWeight: "600",
								}}
							>
								Sobre a origem
							</Typography>
							<Typography
								variant="h3"
								sx={{
									fontWeight: "700",
									color: "#009199",
								}}
							>
								{empresa?.nome}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="body1"
								sx={{
									mb: "25px",
									wordBreak: "break-word"
								}}
							>
								{dadosPortal?.descricaoPropriedade}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								textAlign: "end",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "end",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "600",
								}}
							>
								{endereco?.cidade +", "+ endereco?.uf}
							</Typography>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={0}
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "end",
									justifyContent: "center",
									height: "45px",
									m: 0,
									p: 0,
								}}
							>
								<Grid item xs={3} sm={3} md={3}>
									
									<a href={redeSocial?.instagram}><InstagramIcon className={styles.icon} sx={iconStyle} /></a>
								</Grid>
								<Grid item xs={3} sm={3} md={3}>
									<a href={redeSocial?.facebook}><FacebookIcon className={styles.icon} sx={iconStyle} /></a>									
								</Grid>
								<Grid item xs={3} sm={3} md={3}>
									<a href={redeSocial?.site}><LanguageIcon className={styles.icon} sx={iconStyle} /></a>
									
								</Grid>
								<Grid item xs={3} sm={3} md={3}>
									<a href={redeSocial?.linkedin}><LinkedInIcon className={styles.icon} sx={iconStyle} /></a>
									
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
export default memo(AboutOrigin);
