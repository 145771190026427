//main
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import Logo from "../../Assets/images/logoMenu.svg";
import styles from "./PdfLayout.module.css";
import PdfBalancoProducao from "./pdfComponents/PdfBalancoDeProducao";
import PdfCadernoCampo from "./pdfComponents/PdfCadernoCampo";
import PdfEstoqueInsumos from "./pdfComponents/PdfEstoqueInsumos";
import PdfInsumos from "./pdfComponents/PdfInsumos";
import PdfOcorrenciaDetalhada from "./pdfComponents/PdfOcorrenciaDetalhada";
import PdfOcorrencias from "./pdfComponents/PdfOcorrencias";
import PdfPerdas from "./pdfComponents/PdfPerdas";
import PdfRelatorioAtividade from "./pdfComponents/PdfRelatorioAtividade";
import PdfRelatorioProdutividade from "./pdfComponents/PdfRelatorioProdutividade";
import PdfVendas from "./pdfComponents/PdfVendas";
import PdfGeral from "./pdfComponents/PdfGeral";
import PdfVisualizacao from "./pdfComponents/PdfVisualizacao";
import PdfItemColheita from "./pdfComponents/pdfItemColheita";
import { SettingsService } from "../../service/settings_service";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DownloadButton({ document, fileName }) {
	return (
		<PDFDownloadLink document={document} fileName={fileName} className={styles.buttonDownload}>
			{({ loading }) =>
				loading ? (
					<button className={styles.buttonDownload}>
						<div className={styles.ldsroller}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
						Carregando documento...
					</button>
				) : (
					<button className={styles.buttonDownload}>Download Pdf</button>
				)
			}
		</PDFDownloadLink>
	);
}

function PdfFile() {
	//url controls
	const url = new URLSearchParams(window.location.search);
	const pag = url.get("pag");
	const index = url.get("index");
	const [relatorio, setRelatorio] = useState(pag);
	const [Components, setComponents] = useState("");
	const [hrefRetorno, setHrefREtono] = useState("");
	const [custo, setCusto] = useState(false);
	const [visualizacao, setVisualizacao] = useState(false);
	const [initialFilesLogo, setInitialFilesLogo] = useState(null);

	const settingsService = new SettingsService();

	function pdfComponents() {
		if (relatorio === "CadernoCampo") {
			setRelatorio("Relatório de Caderno de Campo");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfCadernoCampo;
		}
		if (relatorio === "CadernoCampoCusto") {
			setRelatorio("Relatório de Caderno de Campo com Custo");
			setCusto(true);
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfCadernoCampo;
		}
		if (relatorio === "BalancoProducao") {
			setRelatorio("Relatório de Balanço de Producao");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfBalancoProducao;
		}

		if (relatorio === "EstoqueInsumos") {
			setRelatorio("Relatório de Estoque de Insumos");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfEstoqueInsumos;
		}

		if (relatorio === "Ocorrencias") {
			setRelatorio("Relatório de Ocorrências");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfOcorrencias;
		}

		if (relatorio === "Perdas") {
			setRelatorio("Relatório de Perdas");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfPerdas;
		}

		if (relatorio === "Atividades") {
			setRelatorio("Relatório de Atividades");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfRelatorioAtividade;
		}

		if (relatorio === "Produtividades") {
			setRelatorio("Relatório de Produtividades");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfRelatorioProdutividade;
		}

		if (relatorio === "Vendas") {
			setRelatorio("Relatório de Vendas");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfVendas;
		}
		if (relatorio === "Geral") {
			setRelatorio("Relatório Geral");
			setHrefREtono(`/relatorios/?index=${index}`);
			return PdfGeral;
		}
		if (relatorio === "Insumos") {
			setRelatorio("Relatório de Insumos");
			setHrefREtono("/insumos");
			return PdfInsumos;
		}
		if (relatorio === "OcorrenciaDetalhada") {
			setRelatorio("Relatório de Ocorrência Detalhada");
			setHrefREtono("/ocorrencias");
			return PdfOcorrenciaDetalhada;
		}
		if (relatorio === "VisualizacaoReceituario") {
			setRelatorio("Visualização receituário");
			setHrefREtono(`/estoque/detalhes/${index}`);
			setVisualizacao("receituario");
			return PdfVisualizacao;
		}
		if (relatorio === "VisualizacaoNotaFiscal") {
			setRelatorio("Visualização nota fiscal");
			setHrefREtono(`/estoque/detalhes/${index}`);
			setVisualizacao("nota_fiscal");
			return PdfVisualizacao;
		}
		if (relatorio === "Colheita") {
			setRelatorio("Demanda de colheita");
			setHrefREtono("/colheita");
			return PdfItemColheita;
		}
	}

	useEffect(() => {
		try {
			setComponents(pdfComponents);
			downloadFilesLogo();
		} catch {
			console.log("error");
		}
	}, []);

	const dataInicio = url.get("startDate");
	const dataFim = url.get("finalDate");

	const getStartDate = () => {
		if (dataInicio !== null) {
			let data = dataInicio.split("/");
			return `${data[2]}-${data[1]}-${data[0]}`;
		} else {
			return "";
		}
	};

	const getFinalDate = () => {
		if (dataFim !== null) {
			let data = dataFim.split("/");
			return `${data[2]}-${data[1]}-${data[0]}`;
		} else {
			return "";
		}
	};

	const downloadFilesLogo = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		let initiFiles = null;

		const base64 = await settingsService.getBusinessLogo(user.businessId);

		if(base64 && base64 !== ""){
			const mimeType = getMimeType(base64);
			const binaryString = atob(base64, "base64");

			let bytes = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++)
				bytes[i] = binaryString.charCodeAt(i);

			const blob = new Blob([bytes], { type: mimeType });

			initiFiles = new File([blob], "0", {
				type: mimeType,
			});

			setInitialFilesLogo(initiFiles);
		} else {
			setInitialFilesLogo(null);
		}
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	const MyComponent = () => {
		return (
			<Components
				logo={initialFilesLogo}
				visualizacao={visualizacao}
				id={url.get("id")}
				custo={custo}
				safraId={url.get("safraId")}
				clienteId={url.get("clienteId")}
				talhaoId={url.get("talhaoId")}
				parcelaId={url.get("parcelaId")}
				grupoInsumoId={url.get("grupoInsumoId")}
				insumoId={url.get("insumoId")}
				culturaId={url.get("culturaId")}
				atividadeId={url.get("servicoId")}
				responsaveisId={url.get("responsaveisId")}
				startDate={getStartDate()}
				finalDate={getFinalDate()}
				talhao={url.get("talhao")}
			/>
		);
	};
	const pdfDocument = MyComponent();

	return (
		<div>
			<header className={styles.containerLogo}>
				<img className={styles.imgLogo} src={Logo} />
				
			</header>
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 2 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					{/* <img className={styles.imgLogo} src={img} /> */}
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									sx={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
										textAlign: "center",
									}}
								>
									<a className={styles.headerRegisterAreaButton} href={hrefRetorno}>
										<ArrowBackIcon />
									</a>
									<h1 className={styles.headerRegisterAreaTitle}>{relatorio}</h1>
								</Grid>								
								<Grid
									item
									xs={6}
									sm={6}
									md={
										relatorio === "GerarQrcodeTalhao" || relatorio === "Qr code Talhão"
											? 3
											: 6
									}
									sx={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									<DownloadButton document={pdfDocument} fileName={relatorio} />
								</Grid>
							</Grid>
						</Grid>								
						<Grid item xs={12} sm={12} md={12} sx={{ height: "80vh" }}>
							<PDFViewer
								style={{
									width: "100%",
									height: "100%",
									border: "none",
									boxShadow: "none",
									backgroundColor: "#525659",
								}}
							>
								{pdfDocument}
							</PDFViewer>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default PdfFile;
