import { Box, Grid, Rating, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
// import logoHorizontal from "../../../../Assets/images/logoMenu.png";

import styles from "../../LandingPage.module.css";
import { SettingsService } from "../../../../service/settings_service.js";

function Evaluation(props) {
	// const [value, setValue] = useState(0);
	const [review, setReview] = useState({
		nome: "",
		email: "",
		comentario: "",
		estrelas:0,
	});

	const settingsService = new SettingsService();

	async function saveReview() {
		const propriedade = props.propriedade;
		if(Number(review.estrelas) !== 0){
			try {
				const reviewToSave = {
					rating: Number(review.estrelas),
					name: review.nome,
					email: review.email,
					comment: review.comentario,
					propertyId: propriedade.id
				};
				await settingsService.saveReview(reviewToSave);
				setReview({
					nome: "",
					email: "",
					comentario: "",
					estrelas: 0,
				});
				alert("Obrigado pela Avaliação");
			} catch (e) {
				console.error(e);
			}
		} else {
			alert("Avaliação obrigatória para o envio");
		}
	}

	return (
		<div>
			<Box
				component="main"
				sx={{
					width: "100%",
					height: "100%",
					minHeight: "100vh",
					m: 0,
				}}
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={0}
					flexDirection={"row"}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						p: 0,
						m: 0,
						
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "70%",
							minWidth: "320px",
							
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={0}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								bgcolor: "rgba(0, 0, 0, 0.2	)",
								backdropFilter: "blur( 10px )",
								height: "100%",
								minHeight: "80vh",
								maxHeight: "100vh",
								textAlign: "center",
								py:5,	
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<h1>Avalie o nosso produto!</h1>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<p>Deixe seu feedback e contribua com nossa evolução.</p>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Rating
									name="simple-controlled"
									value={review.estrelas}
									precision={0.5}
									onChange={newValue => setReview({
										nome: review.nome,
										email:review.email,
										comentario: review.comentario,
										estrelas: newValue.target.value,
									})}
									size={"large"}
									sx={{
										color: "white",
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									
								}}
							>
								<input
									type={"text"}
									className={styles.input}
									value={review.nome}
									placeholder="[Opcional] Nome:"
									onChange={e => setReview({
										nome: e.target.value,
										email:review.email,
										comentario: review.comentario,
										estrelas: review.estrelas,
									})}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "8	0%",
								}}
							>
								<input
									type={"text"}
									value={review.email}
									className={styles.input}
									placeholder="[Opcional] Email:"
									onChange={e => setReview({
										nome: review.nome,
										email:e.target.value,
										comentario: review.comentario,
										estrelas: review.estrelas,
									})}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: { xs: "none", sm: "flex" },
									justifyContent: "center",
									alignItems: "center",
									pb: "30px",
									visibility: { xs: "hidden", sm: "visible" },
								}}
							>
								<TextareaAutosize
									aria-label="Comentário"
									minRows={0}
									placeholder="Comentário..."
									value={review.comentario}
									onChange={e => setReview({
										nome: review.nome,
										email: review.email,
										comentario: e.target.value ,
										estrelas: review.estrelas,
									})}
									style={{
										maxWidth: "50vw",
										bgcolor: "rgba(0, 0, 0, 0.2	)",
										maxHeight: 300,
										width: "45vw",
										height: "100%",
										minWidth: 215,
										minHeight: 200,
										borderRadius: 20,
										padding: 15,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: { xs: "flex", sm: "none" },
									justifyContent: "center",
									alignItems: "center",
									pb: "30px",
								}}
							>
								<TextareaAutosize
									aria-label="Comentário"
									minRows={0}
									placeholder="Comentário..."
									value={review.comentario}
									onChange={e => setReview({
										nome: review.nome,
										email: review.email,
										comentario: e.target.value ,
										estrelas: review.estrelas,
									})}
									style={{
										maxWidth: "50vw",
										bgcolor: "rgba(0, 0, 0, 0.2	)",
										maxHeight: 300,
										width: "45vw",
										height: "100%",
										minWidth: 215,
										minHeight: 50,
										borderRadius: 20,
										padding: 15,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									pb: "5px",
								}}
							>
								<button className={styles.button} onClick={saveReview}>
									<b>Enviar</b>
								</button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
export default Evaluation;
