export const data = [
	{
		id: 1,
		name: "Dillie",
		jobTitle: "Actuary",
		descricao: "benchmark B2C networks",
	},
	{
		id: 2,
		name: "Richmond",
		jobTitle: "GIS Technical Architect",
		descricao: "morph synergistic solutions",
	},
	{
		id: 3,
		name: "Anabella",
		jobTitle: "Administrative Assistant I",
		descricao: "implement distributed web-readiness",
	},
	{
		id: 4,
		name: "Austen",
		jobTitle: "Help Desk Operator",
		descricao: "synergize next-generation content",
	},
	{
		id: 5,
		name: "Caroline",
		jobTitle: "Electrical Engineer",
		descricao: "utilize wireless relationships",
	},
	{
		id: 6,
		name: "Farica",
		jobTitle: "Budget/Accounting Analyst III",
		descricao: "morph open-source models",
	},
	{
		id: 7,
		name: "Harlan",
		jobTitle: "Speech Pathologist",
		descricao: "syndicate customized e-commerce",
	},
	{
		id: 8,
		name: "Doyle",
		jobTitle: "Financial Analyst",
		descricao: "cultivate intuitive synergies",
	},
	{
		id: 9,
		name: "Maritsa",
		jobTitle: "Research Nurse",
		descricao: "incentivize dot-com e-services",
	},
	{
		id: 10,
		name: "Tally",
		jobTitle: "Nuclear Power Engineer",
		descricao: "reintermediate B2C infrastructures",
	},
	{
		id: 11,
		name: "Basil",
		jobTitle: "Speech Pathologist",
		descricao: "streamline vertical e-business",
	},
	{
		id: 12,
		name: "Jules",
		jobTitle: "Staff Accountant III",
		descricao: "extend open-source platforms",
	},
	{
		id: 13,
		name: "Terrence",
		jobTitle: "Civil Engineer",
		descricao: "target back-end bandwidth",
	},
	{
		id: 14,
		name: "Gigi",
		jobTitle: "Help Desk Operator",
		descricao: "harness revolutionary e-markets",
	},
	{
		id: 15,
		name: "Robin",
		jobTitle: "Desktop Support Technician",
		descricao: "deliver end-to-end deliverables",
	},
	{
		id: 16,
		name: "Tyrus",
		jobTitle: "Dental Hygienist",
		descricao: "generate sticky eyeballs",
	},
	{
		id: 17,
		name: "Tomlin",
		jobTitle: "Account Executive",
		descricao: "revolutionize integrated vortals",
	},
	{
		id: 18,
		name: "Afton",
		jobTitle: "Associate Professor",
		descricao: "visualize proactive solutions",
	},
	{
		id: 19,
		name: "Lenette",
		jobTitle: "Human Resources Manager",
		descricao: "reintermediate scalable platforms",
	},
	{
		id: 20,
		name: "Mellie",
		jobTitle: "Biostatistician I",
		descricao: "morph distributed technologies",
	},
	{
		id: 21,
		name: "Edin",
		jobTitle: "Media Manager IV",
		descricao: "exploit strategic channels",
	},
	{
		id: 22,
		name: "Claretta",
		jobTitle: "Quality Engineer",
		descricao: "synthesize B2B e-markets",
	},
	{
		id: 23,
		name: "Franklin",
		jobTitle: "Nuclear Power Engineer",
		descricao: "enhance user-centric technologies",
	},
	{
		id: 24,
		name: "Armstrong",
		jobTitle: "Community Outreach Specialist",
		descricao: "envisioneer global partnerships",
	},
	{
		id: 25,
		name: "Carolann",
		jobTitle: "Internal Auditor",
		descricao: "visualize 24/7 deliverables",
	},
	{
		id: 26,
		name: "Wally",
		jobTitle: "Internal Auditor",
		descricao: "monetize robust paradigms",
	},
	{
		id: 27,
		name: "Paquito",
		jobTitle: "Internal Auditor",
		descricao: "deliver best-of-breed e-commerce",
	},
	{
		id: 28,
		name: "Quinn",
		jobTitle: "Tax Accountant",
		descricao: "streamline 24/365 solutions",
	},
	{
		id: 29,
		name: "Velma",
		jobTitle: "Health Coach II",
		descricao: "expedite synergistic users",
	},
	{
		id: 30,
		name: "Catherin",
		jobTitle: "Engineer III",
		descricao: "brand cutting-edge niches",
	},
	{
		id: 31,
		name: "Rhonda",
		jobTitle: "Editor",
		descricao: "deliver world-class architectures",
	},
	{
		id: 32,
		name: "Alexina",
		jobTitle: "Environmental Tech",
		descricao: "mesh world-class architectures",
	},
	{
		id: 33,
		name: "Daniele",
		jobTitle: "Senior Cost Accountant",
		descricao: "envisioneer compelling models",
	},
	{
		id: 34,
		name: "Roz",
		jobTitle: "Software Consultant",
		descricao: "disintermediate best-of-breed schemas",
	},
	{
		id: 35,
		name: "Olivie",
		jobTitle: "Sales Associate",
		descricao: "target synergistic ROI",
	},
	{
		id: 36,
		name: "Donny",
		jobTitle: "Recruiter",
		descricao: "e-enable magnetic experiences",
	},
	{
		id: 37,
		name: "Paulita",
		jobTitle: "Geologist II",
		descricao: "engage bleeding-edge eyeballs",
	},
	{
		id: 38,
		name: "Dore",
		jobTitle: "Recruiter",
		descricao: "strategize strategic infrastructures",
	},
	{
		id: 39,
		name: "Raimondo",
		jobTitle: "Assistant Manager",
		descricao: "matrix intuitive functionalities",
	},
	{
		id: 40,
		name: "Madelon",
		jobTitle: "Staff Accountant III",
		descricao: "envisioneer sexy relationships",
	},
];
