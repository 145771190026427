import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Btn from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import * as I from "../IconsImport";

//import css modules
/* import "./style.css"; */
import styles from "./DropDownPropertySubItem.module.css";

import { Box, Modal, TextField } from "@mui/material";
//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "100%",
	transform: "translate(-50%, -50%)",
};
const styleMenu = { color: "white", margin: 0 };

function ButtonsDropdown(props) {
	const [subdivisions] = useState(props.subItem);
	const [currentSubdivision, setCurrentSubdivision] = useState({});
	const [newSubdivision, setNewSubdivision] = useState({
		complemento: "",
		ativo: true,
		area_hectares: 0,
		subdivisionCategory: "0",
		coordinates: [],
		plotId: null,
		parentSubdivision: null
	});
	// const [oldSubdivision, setOldSubdivision] = useState(props.subItem);
	const [anchorElSub, setAnchorElsub] = React.useState(null);
	const [saveNewSubdivision, setSaveNewSubdivision] = useState(false);
	const [openParcela, setOpenParcela] = useState(false);
	const [isPoint, setIsPoint] = useState(true);

	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState("");
	const [openSub, setOpenSub] = useState(true);
	const [openSubdivisionAreaEdit, setOpenSubdivisionAreaEdit] = useState(false);
	const openMenuSub = Boolean(anchorElSub);
	const openMenu = Boolean(anchorEl);
	const allCategories = {
		PARCELA: "Parcela",
		SETOR: "Setor",
		CANTEIRO: "Canteiro",
		BANCADA: "Bancada",
		BALCAO: "Balcão",
		BLOCO: "Bloco",
		GLEBA: "Gleba",
		QUADRA: "Quadra",
		LOTE: "Lote",
	};

	const handleClickMenu = (event, sub) => {
		setAnchorEl(event.currentTarget);
		setCurrentSubdivision(sub);
	};
	const handleClick = (newOpen) => {
		if (open !== newOpen)
			setOpen(newOpen); //se o item estiver aberto, fecha, se não, Abreve
		else setOpen("");
	};
	const handleClickSub = () => {
		setOpenSub(!openSub);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickMenuSub = (e, sub) => {
		setAnchorElsub(e.currentTarget);
		setCurrentSubdivision(sub);
	};

	const handleCloseSub = () => {
		setAnchorElsub(null);
	};

	const handleCloseSubdivisionAreaEdit = () => {
		setOpenSubdivisionAreaEdit(false); //fecha o modal
	};

	const handleDelete = () => {
		props.handleDelete(currentSubdivision);
	};
	
	const updateSubdivision = async () => {
		setSaveNewSubdivision(false);
		setIsPoint(true);
		setOpenParcela(true);
		handleCloseSub();
	};
	
	const saveSubdivision = () => {
		setOpenParcela(false);
		let subdivisionToSave = {};
		if (saveNewSubdivision) {
			subdivisionToSave = {
				complement: newSubdivision.complemento,
				active: newSubdivision.ativo,
				acre_area: Number(newSubdivision.area_hectares.replace(",", ".")),
				subdivisionCategory: newSubdivision.categoriaSubdivisao,
				coordinates: [],
				plotId: currentSubdivision.talhaoId,
				parentSubdivision: currentSubdivision.id
			};
		} else {
			subdivisionToSave = {
				id: currentSubdivision.id,
				complement: currentSubdivision.complemento,
				active: currentSubdivision.ativo,
				acre_area: Number(currentSubdivision.area_hectares.replace(",", ".")),
				subdivisionCategory: currentSubdivision.categoriaSubdivisao,
				coordinates: currentSubdivision.subdivisaoCoordenada ?? [],
				subdivisaoCoordenada: currentSubdivision.subdivisaoCoordenada,
				plotId: currentSubdivision.talhaoId,
				parentSubdivision: currentSubdivision.subdivisao_pai
			};
		}

		props.saveSubdivision(
			subdivisionToSave,
			isPoint
		);
	};

	const handleCloseParcela = () => {
		setOpenParcela(false); //fecha o modal
		handleCloseSub();
	};

	const handleEditArea = async () => {
		setAnchorEl(null);
		setAnchorElsub(null);
		await props.handleEditArea(currentSubdivision, isPoint);
		setSaveNewSubdivision(false);
		setIsPoint(true);
		setOpenSubdivisionAreaEdit(false);
	};

	const addSubdivision = () => {
		setSaveNewSubdivision(true);
		setAnchorEl(null);
		setAnchorElsub(null);
		setOpenParcela(true);
	};

	const handleClickQrCode = () => {
		window.location.href = "/pdfQrcode/?pag=GerarQrcodeSubdiv&item=" + currentSubdivision.id;
	};
	function returnComponentSubList(data, sub) {
		return (
			<List
				sx={{
					width: "100%",
					p: 0,
					color: "white",
					m: 0,
				}}
				component="nav"
				aria-labelledby="nested-list-subheader"
			>
				<Collapse
					in={open === sub.complemento ? open : false}
					timeout="auto"
					unmountOnExit
					className={styles.containerSubSubItem}
				>
					{data.map((item, index) => {
						if (
							(item.sub === undefined && item.sub !== null) ||
							item.sub.length === 0
						) {
							return returnComponentList(item, index);
						} else {
							return (
								<div key={index}>
									<List component="div" disablePadding>
										<ListItemButton
											className={
												openSub === true
													? styles.containerSubSubItemDropDown
													: styles.containerSubItemDropDown
											}
											sx={{
												color: "white",
												width: "100%",
												bgcolor: "red",
											}}
										>
											<ListItemIcon sx={{ color: "white" }}>
												<LocationOnIcon />
											</ListItemIcon>
											<ListItemText
												className={!sub.ativo && styles.ativo}
												onClick={() => handleClickSub(item.complemento, index)}
												primary={`${allCategories[item.categoriaSubdivisao]} ${item.complemento}`}
											/>

											{openSub === true ? (
												<ExpandLess onClick={() => handleClickSub(item.nome)} />
											) : (
												<ExpandMore onClick={() => handleClickSub(item.nome)} />
											)}
											<ListItemIcon sx={{ color: "white" }}>
												<Btn
													id={item.id}
													aria-controls={openMenu ? "basic-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={openMenu ? "true" : undefined}
													onClick={(e) => handleClickMenu(e, item)}
												>
													<I.IconMoreVert style={styleMenu} />
												</Btn>
											</ListItemIcon>
										</ListItemButton>
									</List>

									<List
										sx={{
											color: "white",
											width: "100%",
											pl: 0.5,
										}}
										component="div"
										disablePadding
										aria-labelledby="nested-list-subheader"
									>
										<Collapse in={openSub ? true : false} timeout="auto" unmountOnExit>
											{returnComponentSubList(item.sub, sub)}
										</Collapse>
									</List>
									<div>
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleClose}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}
										>
											<MenuItem onClick={addSubdivision}>
												Adicionar Subdivisão
											</MenuItem>
											<MenuItem onClick={handleClickQrCode}>Gerar QR code</MenuItem>
											{/* <MenuItem onClick={() => handleOpenItemName(item)}>
												Editar nome
											</MenuItem> */}
											<MenuItem onClick={() => setOpenSubdivisionAreaEdit(true)}>Editar área</MenuItem>
											{/* <MenuItem onClick={() => handleAtivar(props.row)}>
												{sub.ativo ? "Ativar" : "Desativar"}
											</MenuItem> */}
											<MenuItem onClick={handleDelete}>Excluir</MenuItem>
										</Menu>
									</div>
								</div>
							);
						}
					})}
				</Collapse>
			</List>
		);
	}
	function returnComponentList(sub) {
		return (
			<div key={sub.id}>
				<List component="div" disablePadding>
					<ListItemButton
						className={styles.containerSubItemDropDown}
						sx={{
							color: "white",
							width: "100%",
						}}
					>
						<ListItemIcon sx={{ color: "white" }}>
							<LocationOnIcon />
						</ListItemIcon>
						<ListItemText
							id={sub.id}
							primary={`${allCategories[sub.categoriaSubdivisao]} ${sub.complemento}`}
							className={!sub.ativo && styles.ativo}
						/>

						<ListItemIcon sx={{ color: "white" }}>
							<Btn
								id={sub.id}
								aria-controls={openMenuSub ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={openMenuSub ? "true" : undefined}
								onClick={(e) => handleClickMenuSub(e, sub)}
							>
								<I.IconMoreVert style={styleMenu} />
							</Btn>
						</ListItemIcon>
					</ListItemButton>
				</List>
			</div>
		);
	}

	function returnComponentListDropDown(sub, index) {
		return (
			<div key={index}>
				<List component="div" disablePadding>
					<ListItemButton
						className={
							open === sub.complemento
								? styles.containerSubItemDropDown
								: styles.containerSubItemDropDown
						}
						sx={{
							color: "white",
							width: "100%",
						}}
					>
						<ListItemIcon sx={{ color: "white" }}>
							<LocationOnIcon />
						</ListItemIcon>
						<ListItemText
							className={!sub.ativo && styles.ativo}
							onClick={() => handleClick(sub.complemento)}
							primary={`${allCategories[sub.categoriaSubdivisao]} ${sub.complemento}`}
						/>

						{open === sub.complemento ? (
							<ExpandLess onClick={() => handleClick(sub.complemento)} />
						) : (
							<ExpandMore onClick={() => handleClick(sub.complemento)} />
						)}
						<ListItemIcon sx={{ color: "white" }}>
							<Btn
								id={sub.id}
								aria-controls={openMenu ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={openMenu ? "true" : undefined}
								onClick={(e) => handleClickMenu(e, sub)}
							>
								<I.IconMoreVert style={styleMenu} />
							</Btn>
						</ListItemIcon>
					</ListItemButton>
				</List>
				<div>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						<MenuItem onClick={addSubdivision}>
							Adicionar Subdivisão
						</MenuItem>
						<MenuItem onClick={updateSubdivision}>Editar dados</MenuItem>
						{validationSubDiv() && (
							<>
								<MenuItem onClick={handleClickQrCode}>Gerar QR code</MenuItem>
								{/* <MenuItem onClick={() => handleAtivar(props.row)}>
									{sub.ativo ? "Ativar" : "Desativar"}
								</MenuItem> */}
							</>
						)}
						<MenuItem onClick={() => setOpenSubdivisionAreaEdit(true)}>Editar área</MenuItem>
						<MenuItem onClick={handleDelete}>Excluir</MenuItem>
					</Menu>
				</div>
				<List
					sx={{
						width: "100%",
						color: "white",
					}}
					component="div"
					disablePadding
					aria-labelledby="nested-list-subheader"
				>
					<Collapse
						in={open === sub.complemento ? open : false}
						timeout="auto"
						unmountOnExit
						className={styles.containerSubSubItem}
					>
						{returnComponentSubList(sub.subdivisoes, sub)}
					</Collapse>
				</List>
			</div>
		);
	}
	function generateList(data) {
		return data.map((sub, index) => {
			if (sub.subdivisoes === undefined || sub.subdivisoes.length === 0)
				return returnComponentList(sub);
			else return returnComponentListDropDown(sub, index);
		});
	}
	function validationSubDiv() {
		if (
			currentSubdivision.nome === "Sede" ||
			currentSubdivision.nome === "Reservatorio" ||
			currentSubdivision.nome === "Reserva legal" ||
			currentSubdivision.nome === "Corpo hidríco"
		)
			return false;
		else return true;
	}
	return (
		<List
			className={styles.containerItem}
			sx={{ width: "100%", p: 0, color: "white" }}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<Collapse
				in={
					subdivisions !== undefined && subdivisions !== null ? props.open : false
				}
				timeout="auto"
				unmountOnExit
			>
				{generateList(subdivisions)}
			</Collapse>

			<Modal
				open={openParcela}
				onClose={handleCloseParcela}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 8,
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Cadastrar subdivisão
							</p>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<div className={styles.registrationInput}>
									<select
										id="category"
										onChange={saveNewSubdivision ?
											(e) => setNewSubdivision({...newSubdivision, categoriaSubdivisao: e.target.value}):
											(e) => setCurrentSubdivision({...currentSubdivision, categoriaSubdivisao: e.target.value})}
										value={saveNewSubdivision ? newSubdivision.categoriaSubdivisao : currentSubdivision.categoriaSubdivisao}
										className={
											(saveNewSubdivision && newSubdivision.categoriaSubdivisao === "0") || (!saveNewSubdivision && currentSubdivision.categoriaSubdivisao === "0")
												? "selectInputPlaceholder"
												: "selectInputPlace"
										}
									>
										<option value={"0"}>
											{(saveNewSubdivision && newSubdivision.categoriaSubdivisao === "0") || (!saveNewSubdivision && currentSubdivision.categoriaSubdivisao === "0")
												? "--------------- *"
												: "Selecionar tipo de subdivisão *"}
										</option>
										{Object.keys(allCategories).map((key, index) => {
											return (
												<option value={key} key={index}>
													{allCategories[key]}
												</option>
											);
										})}
									</select>
								</div>
							</Item>
						</Grid>
						{saveNewSubdivision && (
							<>
								<Grid item xs={6} sm={3} md={3}>
									<input
										type="radio"
										name="tipoArea"
										id="ponto"
										value="ponto"
										checked={isPoint}
										onChange={() => setIsPoint(true)}
									/>
									<label htmlFor="ponto"> Ponto</label>
								</Grid>
								<Grid item xs={6} sm={3} md={3}>
									<input
										type="radio"
										name="tipoArea"
										id="area"
										value="area"
										checked={!isPoint}
										onChange={() => setIsPoint(false)}
									/>
									<label htmlFor="area"> Area *</label>
								</Grid>
							</>
						)}
						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<TextField
									id="filled-textarea"
									label="Identificação"
									placeholder="Identificação *"
									multiline
									variant="outlined"
									value={saveNewSubdivision ? newSubdivision.complemento : currentSubdivision.complemento}
									onChange={saveNewSubdivision ? 
										(e) => setNewSubdivision({...newSubdivision, complemento: e.target.value}):
										(e) => setCurrentSubdivision({...currentSubdivision, complemento: e.target.value})}
									sx={{ width: "100%", bgcolor: "white" }}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<TextField
									id="filled-textarea"
									label="Área em hectares"
									placeholder="Área em hectares *"
									multiline
									variant="outlined"
									onChange={saveNewSubdivision ?
										(e) => setNewSubdivision({...newSubdivision, area_hectares: e.target.value}):
										(e) => setCurrentSubdivision({...currentSubdivision, area_hectares: e.target.value})}
									value={saveNewSubdivision ? newSubdivision.area_hectares : currentSubdivision.area_hectares}
									sx={{ width: "100%", bgcolor: "white" }}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseParcela}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={saveSubdivision}
											>
												Salvar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openSubdivisionAreaEdit}
				onClose={handleCloseSubdivisionAreaEdit}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 8,
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Editar área
							</p>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<input
								type="radio"
								name="tipoArea"
								id="ponto"
								value="ponto"
								checked={isPoint}
								onChange={() => setIsPoint(true)}
							/>
							<label htmlFor="ponto"> Ponto</label>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<input
								type="radio"
								name="tipoArea"
								id="area"
								value="area"
								checked={!isPoint}
								onChange={() => setIsPoint(false)}
							/>
							<label htmlFor="area"> Area</label>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseSubdivisionAreaEdit}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={handleEditArea}
											>
												Salvar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<div>
				<Menu
					id={"basic-menu"}
					anchorEl={anchorElSub}
					open={openMenuSub}
					onClose={handleCloseSub}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{validationSubDiv() && (
						<MenuItem onClick={addSubdivision}>
							Adicionar Subdivisão
						</MenuItem>
					)}
					{subdivisions && currentSubdivision && (
						<>
							{/* <MenuItem onClick={() => handleAtivar(subItemIndex)}>
								{subItemIndex.ativo ? "Ativar" : "Desativar"}
							</MenuItem> */}
						</>
					)}

					{validationSubDiv() && (
						<>
							<MenuItem onClick={handleClickQrCode}>Gerar QR code</MenuItem>
							{/* <MenuItem onClick={() => handleAtivar(subItemIndex)}>
								{subItemIndex.ativo ? "Ativar" : "Desativar"}
							</MenuItem> */}
							<MenuItem onClick={updateSubdivision}>
								Editar dados
							</MenuItem>
						</>
					)}

					<MenuItem onClick={() => setOpenSubdivisionAreaEdit(true)}>Editar área</MenuItem>
					<MenuItem onClick={handleDelete}>Excluir</MenuItem>
				</Menu>
			</div>
		</List>
	);
}
export default ButtonsDropdown;
