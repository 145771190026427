import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logoHorizontal from "../../../../Assets/images/logoMenu.png";
import styles from "../../LandingPage.module.css";

const iconStyle = {
	height: " 45px",
	width: "45px",
	color: "white",
};
const balaoInfoStyle = {
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
	textAlign: "center",
	minHeight: "300px",
	height: "100%",
	p: 5,
	borderRadius: "30px",
	bgcolor: "white",
	color: "gray",
	boxShadow: " -7px 10px 43px -15px rgba(0,0,0,0.75)",
};
const balaoInfoTopStyle = {
	bgcolor: "gray",
	width: "max-content",
	borderRadius: "15px",
	p: 2,
	ml: "20px",
	mb: -3,
	position: "relative",
	color: "white",
	boxShadow: "-7px 10px 24px -2px rgba(0,0,0,0.75)",
	fontSize: "20px",
	fontWeight: "bold",
};
const balaoInfoBottonStyle = {
	bgcolor: "gray",
	width: "max-content",
	borderRadius: "15px",
	p: 1,
	ml: "20px",
	mt: -5,
	position: "relative",
	color: "white",
	fontWeight: "bold",
	fontSize: "36px",
	boxShadow: "0px 15px 24px -5px rgb(0 0 0 / 75%)",
};

function DidYouKnow() {
	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: "100%",
				minHeight: "80vh",
				m: 0,
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				flexDirection={"row"}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					minHeight: "80vh",
					p: 0,
					m: 0,
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							alignItems: "center",
							py: 2,
							px: 5,
							height: "100%",
							minHeight: "80vh",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="body1"
								sx={{
									fontWeight: "600",
								}}
							>
								Você sabia?
							</Typography>
							<Typography
								variant="h3"
								className={styles.titleTraceabilityProdut}
								sx={{
									fontWeight: "700",
									color: "white",
								}}
							>
								Rastreabilidade Vegetal
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant="body1"
								sx={{
									mb: "25px",
								}}
							>
								A rastreabilidade trata-se de um conjunto de procedimentos que
								permitem acompanhar e monitorar a movimentação dos produtos
								alimentícios ao longo da cadeia produtiva, ou seja, é possível
								identificar os responsáveis pelos produtos desde o
								estabelecimento do(a) agricultor(a) que os produziu, passando
								pelos intermediários até a venda final ao consumidor.
							</Typography>
							<Typography variant="body1">
								A rastreabilidade vegetal surgiu da necessidade de se conhecer a
								origem e todo o processo dos alimentos vegetais frescos ao longo
								da sua cadeia logística até a chegada ao consumidor final. Com a
								rastreabilidade, toda a trajetória em que o alimento é submetido
								fica resguardada de maneira a possibilitar identificação de
								todos os passos deste alimento ao longo de seu fluxo logístico,
								garantindo assim, a segurança e a qualidade do alimento.
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<img src={logoHorizontal} className={styles.imgDidYouKnow} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} sx={{
							display: "flex",
							flexDirection:"column",
							justifyContent: "center",
							alignItems: "flex-end",
						}}>
							<Typography
								variant="h4"
								sx={{
									fontWeight: "600",
									fontSize: { xs: "18px", sm: "18px", md: "12px", lg: "18px" },
								}}
							>
								www.sisagri.com.br
							</Typography>
							<Grid
								container
								rowSpacing={0}
								columnSpacing={0}
								sx={{
									width: "100%",
									display: "flex",
									height: "45px",
									m: 0,
									p: 0,
									justifyContent:  "flex-end",
									alignItems: "center",
								}}
							>
								<Grid
									item
									xs={"auto"}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<a href="https://instagram.com/sisagri?igshid=q59yh5dshe4p">
										<InstagramIcon sx={iconStyle} />
									</a>
								</Grid>
								<Grid
									item
									xs={"auto"}
									
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<a href="https://www.facebook.com/Sisagri-288800665408753/">
										<FacebookIcon className={styles.icon} sx={iconStyle} />
									</a>
								</Grid>
								<Grid
									item
									xs={"auto"}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<a href="https://www.sisagri.com.br/">
										<LanguageIcon className={styles.icon} sx={iconStyle} />
									</a>
								</Grid>
								<Grid
									item
									xs={"auto"}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<a href="https://www.linkedin.com/company/sisagri">
										<LinkedInIcon className={styles.icon} sx={iconStyle} />
									</a>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						minHeight: "80vh",
						m: 0,
						p: 0,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						xs={12}
						sm={12}
						md={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							p: { xs: 2, sm: 1, md: 0 },
							m: 0,
							height: "100%",
							minHeight: "80vh",
						}}
					>
						<Grid item xs={12} sm={12} md={6}>
							<Box sx={balaoInfoTopStyle}>Produtor</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<p>
										Com a rastreabilidade e um controle eficiente, o produtor
										pode alcançar outro patamar frente ao mercado e
										profissionalizar a sua unidade produtiva. Além de permitir a
										valorização o produto e de sua origem diante dos parceiro e
										do consumidor final.
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>01</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Box sx={balaoInfoTopStyle}>Distribuidor</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<p>
										Produtos devidamente identificados e rastreáveis facilitam o
										processo de controle de estoque. Com informações precisas
										dos lotes de produção é possível identificar o que sai e o
										que entra, melhorando o processo de venda dos produtos pelos
										entes.
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>02</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Box sx={balaoInfoTopStyle}>Varejista</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<p>
										Possibilita saber mais informações sobre os alimentos, a
										procedência e forma como foram produzidos (com ou sem
										agrotóxicos, ambientalmente sustentável). Trata-se de um
										direito do consumidor a escolha da qualidade dos alimentos
										que vão para a sua mesa. ‌
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>03</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Box sx={balaoInfoTopStyle}>Consumidor</Box>

							<Grid
								container
								rowSpacing={0}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={balaoInfoStyle}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									SX={{ border: "1px solid black" }}
								>
									<p>
										Com a tendência de
										melhores hábitos alimentares, o reforço de responsabilidade
										repassado pela garantia de origem, conquista e fideliza o
										cliente que busca mais segurança e qualidade alimentar.
									</p>
								</Grid>
							</Grid>
							<Box sx={balaoInfoBottonStyle}>04</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
export default DidYouKnow;
