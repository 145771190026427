import axios from "axios";

export class SettingsRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async getSocialNetwork(propertyId) {
		try {
			const result = await this.axios.get(`settings/social-network/${propertyId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveSocialNetwork(socialNetwork) {
		try {
			const result = await this.axios.post("settings/social-network", socialNetwork, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateSocialNetwork(socialNetwork) {
		try {
			const result = await this.axios.put("settings/social-network", socialNetwork, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getTraceabilityPortalDataByProperty(propertyId) {
		try {
			const result = await this.axios.get(`settings/property/${propertyId}/traceability-portal-data`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveTraceabilityPortalData(rastreabilityData) {
		try {
			const result = await this.axios.post("settings/traceability-portal-data", rastreabilityData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateTraceabilityPortalData(rastreabilityData) {
		try {
			const result = await this.axios.put("settings/traceability-portal-data", rastreabilityData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getPropertyGalleryImages(propertyId) {
		try {
			const result = await this.axios.get(`settings/property/${propertyId}/gallery`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async savePropertyGalleryImages(propertyId, images) {
		try {
			const result = await this.axios.post(`settings/property/${propertyId}/gallery`,
				images, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updatePropertyGalleryImages(propertyId, images) {
		try {
			const result = await this.axios.put(`settings/property/${propertyId}/gallery`,
				images, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getTraceabilityImage(propertyId) {
		try {
			const result = await this.axios.get(`settings/property/${propertyId}/traceability-image`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveTraceabilityImage(propertyId, image) {
		try {
			const result = await this.axios.post(`settings/property/${propertyId}/traceability-image`,
				image, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateTraceabilityImage(propertyId, image) {
		try {
			const result = await this.axios.put(`settings/property/${propertyId}/traceability-image`,
				image, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getBusinessLogo(businessId) {
		try {
			const result = await this.axios.get(`settings/business/${businessId}/logo`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveBusinessLogo(businessId, image) {
		try {
			const result = await this.axios.post(`settings/business/${businessId}/logo`,
				image, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateBusinessLogo(businessId, image) {
		try {
			const result = await this.axios.put(`settings/business/${businessId}/logo`,
				image, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				}
			);
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteBusinessLogo(businessId) {
		try {
			const result = await this.axios.delete(`settings/business/${businessId}/logo`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getReviews(propertyId) {
		try {
			const result = await this.axios.get(`settings/property/${propertyId}/review`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveReview(review) {
		try {
			const result = await this.axios.post("settings/review", review, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getTraceabilityByOrderItem(harvestCode, orderItemCode) {
		try {
			const result = await this.axios.get(`settings/traceability-by-code/${harvestCode}/${orderItemCode}`);
			return result.data;
		} catch (error) {
			console.log(error);

			throw new Error("Erro interno.");
		}
	}

	async getTraceabilityByProperty(propertyId) {
		try {
			const result = await this.axios.get(`settings/traceability-by-code/${propertyId}`);
			return result.data;
		} catch (error) {
			console.log(error);

			throw new Error("Erro interno.");
		}
	}
}