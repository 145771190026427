import { Box, Grid, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "../../SettingsPag.module.css";
import AvisoPrivacidade from "./Privacidade";
import Contract from "../../../../components/Contract";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TermsContract() {
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Box component="main">
				<Box
					sx={{
						width: "100%",
						minWidth: { xs: "100vw", sm: "66.666667vw", md: "75vw" },
						height: "90vh",
						bgcolor: "white",
						m: 0,
						mt: 2,
						borderRadius: 8,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={0}
						sx={{
							display: "flex",
							width: "100%",
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							p: 3,
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="basic tabs example"
								>
									<Tab
										label="Politica de Privacidade"
										{...a11yProps(0)}
										className={
											value === 0
												? styles.tab + " " + styles.focuzHorizontal
												: styles.tab
										}
									/>
									<Tab
										label="Termo de Contrato"
										{...a11yProps(1)}
										className={
											value === 1
												? styles.tab + " " + styles.focuzHorizontal
												: styles.tab
										}
									/>
								</Tabs>
							</Box>
							<TabPanel value={value} index={0}>
								<Box
									component="main"
									sx={{ overflowY: "auto", height: "100%", maxHeight: "60vh" }}
								>
									<AvisoPrivacidade />
								</Box>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<Contract />
							</TabPanel>
						</Grid>
						<Grid item xs={12} sm={12} md={12} hidden>
							<button className={styles.buttonConfig}>
								Confirmar Contrato
							</button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default TermsContract;
