import axios from "axios";

export class StockRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async changeVisibilityEntry(id) {
		try {
			const result = await this.axios.put(`stock/entry/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteEntry(stockId) {
		try {
			const result = await this.axios.delete(`stock/entry/${stockId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getById(stockId) {
		try {
			const result = await this.axios.get(`stock/entry/${stockId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getInputs(propertyId) {
		try {
			const result = await this.axios.get(`stock/input/${propertyId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async getEntries(propertyInputId) {
		try {
			const result = await this.axios.get(`stock/input/${propertyInputId}/detail`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async getProducts(propertyId) {
		try {
			const result = await this.axios.get(`stock/cultivation/${propertyId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async getPrescription(stockId) {
		try {
			const result = await this.axios.get(`stock/prescription/${stockId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async getInvoice(stockId) {
		try {
			const result = await this.axios.get(`stock/invoice/${stockId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}

	async save(stock) {
		try {
			const result = await this.axios.post("stock", stock, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async update(stock) {
		try {
			const result = await this.axios.put("stock", stock, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}