import axios from "axios";

export class ApplicationModeRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async get(params) {
		try {
			const result = await this.axios.get("application-mode", {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";
			throw new Error("Erro interno.");
		}
	}
}