import { BusinessRepository } from "../repository/business_repository";

export class BusinessService {

	constructor() {
		this.businessRepository = new BusinessRepository();
	}

	async getById(businessId) {
		const business = await this.businessRepository.getById(businessId);
		return business;
	}

	async update(businessId, business) {
		return await this.businessRepository.update(businessId, business);
	}

}