import axios from "axios";
export class PropertyRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async getByUser(userId) {
		try {
			const result = await this.axios.get(`property/user/${userId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async save(property) {
		try {
			const result = await this.axios.post("property", property, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async update(property) {
		try {
			const result = await this.axios.patch("property", property, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getById(id) {
		try {
			const result = await this.axios.get(`property/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getByBusiness(businessId) {
		try {
			const result = await this.axios.get(`property/business/${businessId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async delete(id) {
		try {
			const result = await this.axios.delete(`property/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getPlotsByProperty(propertyId) {
		try {
			const result = await this.axios.get(`property/${propertyId}/plot`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async savePlot(plot) {
		try {
			const result = await this.axios.post("property/plot", plot, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updatePlot(plot) {
		try {
			const result = await this.axios.patch("property/plot", plot, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveSubdivision(subdivision) {
		try {
			const result = await this.axios.post("property/plot/subdivision", subdivision, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateSubdivision(subdivision) {
		try {
			const result = await this.axios.patch("property/plot/subdivision", subdivision, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async saveOtherArea(otherArea) {
		try {
			const result = await this.axios.post("property/another-area", otherArea, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async updateOtherArea(otherArea) {
		try {
			const result = await this.axios.patch("property/another-area", otherArea, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getPlotById(plotId) {
		try {
			const result = await this.axios.get(`property/plot/${plotId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getPlots(propertyId) {
		try {
			const result = await this.axios.get(`property/${propertyId}/plot`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deletePlot(plotId) {
		try {
			const result = await this.axios.delete(`property/plot/${plotId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getSubdivisions(propertyId) {
		try {
			const result = await this.axios.get(`property/${propertyId}/plot/subdivision`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteSubdivision(subdivisionId) {
		try {
			const result = await this.axios.delete(`property/plot/subdivision/${subdivisionId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteOtherArea(otherAreaId) {
		try {
			const result = await this.axios.delete(`property/another-area/${otherAreaId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getSubdivisionById(subdivisionId) {
		try {
			const result = await this.axios.get(`property/plot/subdivision/${subdivisionId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}