import { UserRepository } from "../repository/user_repository";

export class UserService {

	constructor() {
		this.userRepository = new UserRepository();
	}

	async getByBusiness(businessId) {
		const users = await this.userRepository.getByBusiness(businessId);
		return users;
	}

	async getByProperty(propertyId) {
		const users = await this.userRepository.getByProperty(propertyId);
		return users;
	}

	async getById(id) {
		const user = await this.userRepository.getById(id);
		return user;
	}

	async save(user) {
		const savedUser = await this.userRepository.save(user);
		return savedUser;
	}

	async update(user) {
		const savedUser = await this.userRepository.update(user);
		return savedUser;
	}

	async updatePassword(body) {
		const savedUser = await this.userRepository.updatePassword(body);
		return savedUser;
	}
}