import React, { useState} from "react";
import TopMenu from "../../components/TopMenu";
import InputSearch from "../../components/SearchHeader/inputSeach";
import Search from "../../components/SearchHeader";
import TableManagAccomplished from "../../components/TableManagement/TableManagAccomplished";

//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
//tabs table imports
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useTheme, createTheme, ThemeProvider } from "@mui/material";
import * as locales from "@mui/material/locale";
import styles from "./Management.module.css";
import TableManagementDemand from "../../components/TableManagement/TableManagDemand";
import TableManagRequested from "../../components/TableManagement/TableManagRequested";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

// const columns = {
// 	safra: "Safra",
// 	servico: "Serviço",
// 	insumo: "Insumo",
// 	quantidade: "Quantidade/Dosagem",
// };
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",

	p: 0,
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "auto" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Application() {
	const [search, setSearch] = useState("");
	// const [allManejos, setAllManejos] = React.useState([]);

	//função pesquisa
	// const dataFilter = allManejos.filter((item) =>
	// 	JSON.stringify(item).toLowerCase().includes(search.toLowerCase()));
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);

	function handleClick(e) {
		setSearch(e.target.value);
	}

	return (
		<div>
			<TopMenu open={["launch", "management"]} />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Manejo"
									href="/manejo/cadastroManejo"
									textButton="Novo Manejo"
									help="management"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									pt: 3,
									p: 1,
									m: 0,
								}}
							>
								<ThemeProvider theme={themeWithLocale}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<Tabs
													value={value}
													onChange={handleChange}
													textColor="white"

													// className="tabs"
												>
													<Tab
														className={
															value === 0
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Demanda"
														{...a11yProps(0)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "30%",
														}}
													/>
													<Tab
														className={
															value === 1
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Realizadas "
														{...a11yProps(1)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "30%",
														}}
													/>
													<Tab
														className={
															value === 2
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Solicitadas "
														{...a11yProps(2)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "30%",
														}}
													/>
												</Tabs>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={0}
												>
													<TableManagementDemand search={search} />
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={1}
												>
													<TableManagAccomplished search={search} />
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={2}
												>
													<TableManagRequested search={search} />
												</TabPanel>
											</Item>
										</Grid>
									</Grid>
								</ThemeProvider>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Application;
