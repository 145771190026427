import {
	Box,
	Container,
	Radio,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BusinessService } from "../../service/business_service";

export default function Contract() {
	const [razaoSocial, setRazaoSocial] = useState("");
	const [cpfCnpj, setCpfCnpj] = useState("");
	const [rua, setRua] = useState("");
	const [cep, setCep] = useState("");
	const [uf, setUf] = useState("");
	const [cidade, setCidade] = useState("");
	const [complemento, setComplemento] = useState("");
	const [bairro, setBairro] = useState("");
	const [email, setEmail] = useState("");

	const businessService = new BusinessService();

	const rows = [
		createData("Dashboard com calendário de atividades", true),
		createData("Previsão de clima", false),
		createData("Cadastro georreferenciado da propriedade", true),
		createData("Cadastro de Usuários", true),
		createData("Cadastro de Culturas", false),
		createData("Cadastro de Serviços", true),
		createData("Cadastro de Clientes", false),
		createData("Cadastro de Insumos", true),
		createData("Lançamentos de Safras", false),
		createData("Lançamentos de Manejo", false),
		createData("Lançamentos de Pedidos", true),
		createData("Lançamentos de Perdas", false),
		createData("Módulo Extra: Lançamentos de Ocorrências", false),
		createData("Lançamentos de Colheitas", true),
		createData("Relatórios", false),
		createData("Gestão de Estoque de produtos", false),
		createData("Módulo Extra: Gestão de Estoque de Insumos", true),
		createData("Integração com outros sistemas", false),
		createData("Plataforma de rastreio personalizável", true),
	];

	function createData(funcionalidade, habilitação) {
		return { funcionalidade, habilitação };
	}

	useEffect(() => {
		initData();
	});

	const initData = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const company = businessService.getById(user.businessId);
		const address = company.endereco;
		buildCompanyInfo(company);
		if(address !== null)
			buildAddress(address);
	};

	const buildCompanyInfo = async (company) => {
		const { nome, cpf_cnpj, email } = company;
		setRazaoSocial(nome);
		setCpfCnpj(cpf_cnpj);
		setEmail(email);
	};

	const buildAddress = async (address) => {
		const { bairro, cep, cidade, uf, endereco, observacao } = address;
		setBairro(bairro);
		setCep(cep);
		setCidade(cidade);
		setUf(uf);
		setRua(endereco);
		setComplemento(observacao);
	};
	if (razaoSocial === ""){
		return (<></>);
	} else {
		return (
			<Box
				component="main"
				sx={{ overflowY: "auto", height: "100%", maxHeight: "60vh" }}
			>
				<Typography sx={{ textAlign: "justify", fontSize: "11px" }}>
					<Container sx={{ textAlign: "center", marginBottom: "1em" }}>
						<Typography
							sx={{
								fontWeight: "bold",
								textDecoration: "underline",
								fontSize: "11px",
							}}
						>
							CONTRATO DE LICENÇA DE USO DE SOFTWARE E
						</Typography>
						<Typography
							sx={{
								fontWeight: "bold",
								textDecoration: "underline",
								fontSize: "11px",
							}}
						>
							PRESTAÇÃO DE SERVIÇOS DE MANUTENÇÃO RELACIONADOS
						</Typography>
					</Container>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						Pelo presente instrumento particular de Contrato de Licença de Uso de
						Software e Prestação de Serviços de Manutenção relacionados
						(“Contrato”), fazem entre si:
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						1.{" "}
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							<strong>
								J&T SOLUÇÕES EM AGRONOMIA E INFORMÁTICA LTDA - SISAGRI
							</strong>
							, inscrita no CNPJ sob nº 33.508.601/0001-69, com endereço no Sítio
							Limoeiro dos Pompeus, S/N, Zona Rural, CEP: 62380-000, Guaraciaba do
							Norte, e-mail{" "}
							<a href="mailto:suportesisagri@gmail.com">
								suportesisagri@gmail.com
							</a>{" "}
							e whatsapp (85) 98131-0242, doravante denominada “CONTRATADA”,
							“LICENCIANTE” ou “SISAGRI”; e
						</Typography>
					</Typography>
					<br />
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						2.{" "}
						<Typography
							sx={{ marginLeft: "1em", marginBottom: "1em", fontSize: "11px" }}
						>
							<strong>{razaoSocial}</strong>, inscrita no CNPJ sob o nº {cpfCnpj},
							com endereço à {rua}, {bairro}, {complemento && `${complemento} ,`}{" "}
							CEP: {cep}, {cidade}/{uf}, e-mail {email}, doravante denominada
							“CONTRATANTE” ou “LICENCIADA”.
						</Typography>
					</Typography>
					<Typography sx={{ fontSize: "11px" }}>
						<strong>CONSIDERANDO QUE:</strong>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						a){" "}
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							A “<strong>SISAGRI</strong>” é a empresa criadora e responsável pela
							Plataforma “SISAGRI”, tecnologia especializada no setor agrícola,
							possibilitando a rastreabilidade na propriedade/empresa agrícola.
							Neste Contrato, quando nos referirmos à “<strong>Plataforma</strong>
							”, estaremos disciplinando sobre Site ou App com tecnologia SISAGRI;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						b){" "}
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Ao utilizar a SISAGRI, a CONTRATANTE reconhece que a
							plataforma/tecnologia não presta assessoramento relacionado à
							regulamentação de cultivos de qualquer natureza e que a CONTRATADA
							não se responsabiliza e não atua com a regulamentação de quaisquer
							produções junto a órgãos regulatórios, cabendo tal responsabilidade
							aos proprietários de cada cultivo, em cada propriedade produtiva;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						c)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Aplicam-se ao presente Contrato as disposições das Leis n° 9.609/98
							e 9.610/98, que tratam da proteção da propriedade intelectual do
							software e da proteção dos direitos autorais;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						d)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							“Usuário Contratante” (ou “Usuário”) é toda e qualquer pessoa,
							natural ou jurídica, que aceita as condições deste Contrato, com
							ciência dos direitos e obrigações inerentes;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						e)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							“Usuário Administrador” é aquele usuário que tem acesso a mais
							funcionalidades e consegue cadastrar outros usuários para acesso à
							Plataforma, podendo escolher as funcionalidades que serão
							compartilhadas para cada um deles. Geralmente, este usuário coincide
							com o Usuário Contratante, mas o Usuário contratante pode eleger
							outro para exercer tais funções;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						f)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							“Usuário Comum” é aquele que tem acesso à Plataforma por meio do
							convite do Usuário Administrador, e terá acesso apenas às
							funcionalidades liberadas por este e à área de login; e
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						g)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							O cliente do Usuário Contratante poderá visualizar, mas nunca editar
							ou alterar, informações (apenas sobre ele e dentro das permissões
							feitas pelo Usuário Administrador) da Plataforma.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						Têm, entre si, justo e acordado o presente{" "}
						<strong>
							CONTRATO DE LICENÇA DE USO DE SOFTWARE E PRESTAÇÃO DE SERVIÇOS DE
							MANUTENÇÃO RELACIONADOS (“Contrato”)
						</strong>
						, o qual se regerá pela legislação vigente e pelas cláusulas e
						condições a seguir estipuladas:
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>1. DO PRAZO E DA VIGÊNCIA</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>1.1. Vigência -</strong> O presente Contrato tem vigência
							iniciada na data de sua assinatura e perdurará pelo tempo inserido
							no Anexo I deste Contrato.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>1.1.1. Renovação -</strong> O presente Contrato será renovado
						por períodos iguais, de forma sucessiva e ilimitada, caso nenhuma das
						Partes manifeste, durante o período de 30 (trinta) dias corridos e
						anteriores ao término da vigência, o interesse de encerramento da
						relação.
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>1.1.2. Obrigações Permanentes -</strong> Todas as disposições
						deste Contrato que prevejam a observância de obrigações ou
						responsabilidades após o encerramento do referido, subsistirão a sua
						rescisão ou extinção e continuação em pleno e efeito vigor, em
						especial no que tange à propriedade intelectual, confidencialidade,
						não concorrência e proteção de dados.
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>2. DO OBJETO: LICENÇA E SERVIÇOS CONTRATADOS</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>2.1. Objeto -</strong> O presente Instrumento tem como
							objeto o Licenciamento não exclusivo da Plataforma/Software
							“SISAGRI”, com tecnologia que possibilita a rastreabilidade na
							propriedade/empresa agrícola, e a prestação de serviços de
							implantação, suporte e manutenção relacionados, pela CONTRATADA em
							benefício da CONTRATANTE, nos termos descritos nos capítulos abaixo.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>2.1.2. Serviços Não Incluídos -</strong> Não estão incluídos
						no presente Contrato os serviços de que não estejam especificados
						neste Instrumento, conforme o caput, nos Anexos e/ou Aditivos, bem
						como condições comerciais específicas, as quais serão considerados
						serviços extraordinários, não estando sujeitos, pelo menos
						inicialmente, aos termos e condições deste Contrato.
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>2.1.3. Serviços Extraordinários -</strong> A execução de
						serviços extraordinários fica condicionada à aceitação, pela
						CONTRATANTE, do orçamento apresentado pela CONTRATADA para a prestação
						desses serviços, e deverá ser objeto de prévio ajuste entre as Partes
						e consolidado por meio de Termo Aditivo a ser devidamente firmado
						pelos seus respectivos representantes legais.
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>3. DA LICENÇA E DA PROPRIEDADE INTELECTUAL</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>3.1. -</strong> A LICENCIANTE concede à LICENCIADA uma ou
							mais licença(s) não-exclusiva(s) e não-transferível(eis) para
							utilizar os MATERIAIS LICENCIADOS sujeitos aos termos e condições
							constantes deste Contrato, subordinados às restrições abaixo:
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								a)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									a licença permite que a LICENCIADA utilize os MATERIAIS
									LICENCIADOS (Anexo II):
								</Typography>
							</Typography>
							<Typography
								sx={{ marginLeft: "2em", fontSize: "11px", display: "flex" }}
							>
								i)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									somente quando a LICENCIADA tiver pago a taxa da licença e de
									prestação de serviços (“preço”) previstas neste Contrato; e
								</Typography>
							</Typography>
							<Typography
								sx={{
									marginLeft: "2em",
									marginBottom: "1em",
									fontSize: "11px",
									display: "flex",
								}}
							>
								ii)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									somente se a LICENCIADA não estiver em falta com relação a este
									Contrato.
								</Typography>
							</Typography>
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>3.2. -</strong> A licença que se autoriza por meio da
							assinatura/aceite deste Contrato se dá por prazo determinado, para
							utilização na forma de produto de plataforma online (in cloud), para
							uso exclusivo do Usuário Contratante, mediante contraprestação da
							mensalidade relacionada ao número de acessos (logins) permitidos,
							para que o utilize via internet, de acordo com as condições
							estipuladas neste Contrato, observados os limites e características
							do ambiente operacional do Usuário Contratante, a quem é vedado:
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								a)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									Ceder, sublicenciar, vender, arrendar, dar em locação ou em
									garantia, alienar de qualquer outra forma, transferir, total ou
									parcialmente, sob quaisquer modalidades, gratuita ou
									onerosamente, provisória ou permanentemente, a quaisquer
									terceiros, sem a prévia e expressa autorização da SISAGRI, o
									Software, objeto do licenciamento de direito de uso,
									sujeitando-se a SISAGRI, em caso de violação desta previsão, às
									sanções estabelecidas em lei;
								</Typography>
							</Typography>
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								b)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									Traduzir, fazer engenharia reversa, decompilar, copiar imagens,
									códigos ou quaisquer partes do software da plataforma para
									utilização fora dele;
								</Typography>
							</Typography>
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								c)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									Alugar, arrendar, atribuir ou transferir o produto licenciado;
								</Typography>
							</Typography>
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								d)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									Modificar o produto ou mesclar todas ou qualquer de suas partes
									com outra plataforma ou software semelhante; e
								</Typography>
							</Typography>
							<Typography
								sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
							>
								e)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									Remover ou alterar qualquer aviso de copyright, marca
									registrada, ou outro aviso de direitos de propriedade colocados
									na plataforma ou em parte desta.
								</Typography>
							</Typography>
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>3.3. -</strong> As metodologias, ideias, conceitos, know-how
							ou técnicas originais, impressos ou registrados, utilizados na
							Plataforma ou tecnologia, e na sua execução, são de propriedade
							exclusiva da SISAGRI.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>3.4. -</strong> Toda e qualquer cópia, alteração, reprodução
							ou duplicação, ainda que parcial, da Plataforma, implicará, para
							todos os efeitos, em violação aos direitos autorais, sujeitando-se,
							o Usuário, bem como os demais co-responsáveis, às sanções cíveis e
							criminais pertinentes.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>3.5. -</strong> A cessão da propriedade da tecnologia
							SISAGRI não é objeto deste Contrato, sendo a referida de propriedade
							exclusiva da J&T SOLUÇÕES EM AGRONOMIA E INFORMÁTICA LTDA - SISAGRI,
							nos termos dos direitos protegidos pela Legislação Brasileira e
							Internacional aplicável à propriedade intelectual e aos direitos
							autorais, especificamente no Brasil, pela Lei n° 9.609 (Lei do
							Software) e Lei n° 9.610 (Lei de Direitos Autorais).
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>
							4. DOS SERVIÇOS DE IMPLANTAÇÃO E SUPORTE DA PLATAFORMA/SOFTWARE
							SISAGRI
						</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.1.</strong> A primeira etapa dos serviços contratados por
							meio deste Contrato é, portanto, a IMPLANTAÇÃO do
							Plataforma/Software SISAGRI que, por sua vez, possibilitará ao
							Usuário:
							<Typography
								sx={{ fontSize: "11px", marginLeft: "2em", display: "flex" }}
							>
								a)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									A organização básica dos dados da unidade produtiva;
								</Typography>
							</Typography>
							<Typography
								sx={{ fontSize: "11px", marginLeft: "2em", display: "flex" }}
							>
								b)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									o armazenamento de informações de cultivo;
								</Typography>
							</Typography>
							<Typography
								sx={{ fontSize: "11px", marginLeft: "2em", display: "flex" }}
							>
								c)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									a gestão de insumos agrícolas;
								</Typography>
							</Typography>
							<Typography
								sx={{ fontSize: "11px", marginLeft: "2em", display: "flex" }}
							>
								d)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									a rastreabilidade na propriedade/empresa; e
								</Typography>
							</Typography>
							<Typography
								sx={{ fontSize: "11px", marginLeft: "2em", display: "flex" }}
							>
								e)
								<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
									a geração de relatórios diversos;
								</Typography>
							</Typography>
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.2. </strong> No que diz respeito ao Suporte técnico, será
							ele obrigatoriamente realizado pela SISAGRI, que se compromete,
							ainda, a auxiliar o Usuário Contratante nos atendimentos aos seus
							clientes, sempre que se fizer necessário, observando os horários de
							disponibilidade informados neste Contrato.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.2.1 </strong> Caso verifique a necessidade de auxílio da
							SISAGRI no suporte ao cliente, o Usuário Contratante deverá abrir um
							chamado por meio do e-mail{" "}
							<a href="mailto:suportesisagri@gmail.com">
								suportesisagri@gmail.com
							</a>{" "}
							ou whatsapp (85) 98131-0242, e o tempo de resposta não será superior
							a 24h. Para tanto, a SISAGRI manterá disponível o canal de
							recebimento de chamados dos Usuários Contratantes em dias úteis e
							horários comerciais.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.3 </strong> Durante a vigência deste Contrato, a
							LICENCIANTE obriga-se a manter os softwares em bom estado de
							funcionamento, disponibilizando sempre as versões mais atualizadas
							aos seus Usuários.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.3.1 </strong> Manutenções, personalizações, melhorias,
							atualizações próprias e de terceiros não estão inclusas no serviço
							de suporte/manutenção, independente de terem sido solicitadas pela
							CONTRATANTE ou mesmo por necessidade técnica.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.4 </strong> Os serviços aos quais se refere este Contrato,
							serão realizados remotamente e em casos específicos em endereço
							fornecido pelo Usuário, conforme necessário para o bom andamento dos
							trabalhos. Custos de deslocamento e diárias de serviços extras não
							estão previstos nos valores previstos nestes Termos, devendo ser
							apresentados pela SISAGRI e aprovados para execução pelo Usuário
							Contratante.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>4.4.1 </strong> O Usuário Contratante terá autonomia para
							definir os horários em que os Serviços serão utilizados tendo em
							vista o uso através de força tecnológica online, ficando a seu
							critério o seu manuseio pelas pessoas que esta indicar como
							usuárias.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>5. DISPONIBILIDADE DA PLATAFORMA</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>5.1.</strong> A SISAGRI manterá a disponibilidade da
							Plataforma em 97% (noventa e sete por cento) do tempo, a cada mês,
							exceto nas situações de caso fortuito, interrupções de manutenção
							para atualização, correção de falhas ou força maior.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>
							6. DAS OBRIGAÇÕES E RESPONSABILIDADES DO USUÁRIO CONTRATANTE
						</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>6.1.</strong> Além das demais obrigações previstas neste
							Contrato, constituem-se obrigações do Usuário Contratante:
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							a)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Efetuar a contraprestação pecuniária pelo licenciamento de que
								trata estes Termos, e no prazo estabelecido, conforme consta no
								Título “DO PAGAMENTO”, bem como a efetuar o pagamento de custos
								adicionais que possam surgir durante o andamento do serviço;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							b)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Enviar todos os documentos e informações requisitadas pela SISAGRI
								no prazo por esta estipulado, sob pena de atraso ou não entrega do
								serviço pela SISAGRI, sem que isso implique em multa ou reparação
								judicial por esta em favor do Usuário Contratante;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							c)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Cadastrar na Plataforma todos os usuários (comuns) a quem ele
								autoriza a utilização da Plataforma;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							d)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Providenciar os materiais e equipamentos necessários para a
								execução dos Serviços atrelados à licença;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							e)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Manter equipamento próprio e acesso à internet banda larga, sob
								suas expensas, para acesso à Plataforma SISAGRI, sob pena de
								comprometer o funcionamento do sistema; e
							</Typography>
						</Typography>
						<Typography
							sx={{
								marginLeft: "1em",
								marginBottom: "1em",
								fontSize: "11px",
								display: "flex",
							}}
						>
							f)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Responsabilizar-se, integralmente, pela guarda e utilização das
								senhas/ID (login), arcando com todas as consequências do uso da
								senha por pessoas não autorizadas.
							</Typography>
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.2.</strong> O Usuário Contratante é o único responsável
							pela veracidade das informações incluídas na Plataforma, não sendo
							encargo da SISAGRI verificar sua veracidade.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.2.1.</strong> O Usuário Contratante responderá
							integralmente por eventuais imprecisões ou falsidades nos dados
							informados à SISAGRI.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.2.2.</strong> O Usuário Contratante autoriza a SISAGRI a
							solicitar documentos para a comprovação da veracidade dos dados
							informados e, ainda, a fazer checagem por outros meios,
							sujeitando-se às sanções estipuladas nestes Termos na hipótese de
							falsidade dos dados cadastrados.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.2.3.</strong> O Usuário Contratante concorda em manter
							corretas, completas e atualizadas as informações fornecidas no
							registro de sua Conta.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.3.</strong> O Usuário Contratante é integralmente
							responsável pelo cadastramento, permissões, senhas e modo de
							utilização de seus usuários, não sendo a revisão de tais informações
							de responsabilidade da SISAGRI.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>6.3.1</strong> Não obstante, a SISAGRI pode, a qualquer
							tempo, suspender ou retirar as licenças de uso cujo conteúdo esteja
							desrespeitando as regras aqui estabelecidas ou as normas legais em
							vigor, sem qualquer devolução de quantias pagas pelo Usuário
							Contratante. Ademais, sendo desrespeitada qualquer regra aqui
							estabelecida ou sendo verificada ilegalidade, a SISAGRI se reserva
							no direito de cancelar a licença de uso da plataforma e suspender
							todos os logins ativos/disponibilizados.
						</Typography>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>6.4.</strong> O Usuário Contratante é responsável, dentre
							outras questões já citadas neste Contrato:
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							a)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Por todos os atos praticados na (e por) sua Conta;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							b)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Pela proteção de suas informações, contra acesso não autorizado ao
								seu computador, conta ou senha, devendo sempre clicar em “sair” ou
								“logout” ao encerrar sua navegação;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							c)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Pelos equipamentos necessários para a utilização da Plataforma; e
							</Typography>
						</Typography>
						<Typography
							sx={{
								marginLeft: "1em",
								marginBottom: "1em",
								fontSize: "11px",
								display: "flex",
							}}
						>
							d)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Pelo acesso e conexão contínua, rápida e de qualidade à internet,
								reconhecendo que a qualidade da conexão da Internet depende, entre
								outros requisitos, do provedor de acesso e do provedor de
								infraestrutura de Internet, do volume de outros usos, etc.
							</Typography>
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>7. DAS OBRIGAÇÕES DA SISAGRI</strong>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>7.1.</strong> Pelo presente Termo, a SISAGRI se obriga a:
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							a)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Disponibilizar ao Usuário Contratante o Software, utilizando as
								melhores práticas, os melhores métodos e técnicas aplicáveis ao
								funcionamento, manutenção e desenvolvimento da Plataforma;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							b)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Executar os serviços com integral observância das disposições
								destes Termos, em estrita conformidade com o disposto na
								legislação aplicável, respondendo diretamente por sua qualidade e
								adequação, bem como pelos danos em casos de infração ou falha
								decorrente da prestação de serviços;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							c)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Agir com boa-fé, de acordo com as melhores técnicas profissionais
								e estrita observância aos princípios éticos e legais; e
							</Typography>
						</Typography>
						<Typography
							sx={{
								marginLeft: "1em",
								marginBottom: "1em",
								fontSize: "11px",
								display: "flex",
							}}
						>
							d)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Oferecer suporte técnico, sem custo adicional, ao Usuário, para
								resolução de dúvidas e/ou problemas de caráter
								técnico/procedimental, o qual deve ser realizado por meio do canal
								de suporte (e-mail ou whatsapp): de segunda à sexta-feira, das 8h
								às 17h, conforme o horário de Brasília, excetuando-se os feriados
								nacionais.{" "}
							</Typography>
						</Typography>
					</Typography>
					<Typography sx={{ fontSize: "11px" }}>
						<strong>
							7.2. <span style={{ textDecoration: "underline" }}>Não</span>
						</strong>{" "}
						são obrigações da SISAGRI, dentre outras previstas ao longo destes
						Termos:
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						a)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Correção de erros de operação ou de uso indevido da Plataforma;
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						b)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Recuperação de arquivos e de dados eventualmente corrompidos ou
							perdidos por utilização indevida dos Usuários;
						</Typography>
					</Typography>
					<Typography
						sx={{
							marginLeft: "1em",
							marginBottom: "1em",
							fontSize: "11px",
							display: "flex",
						}}
					>
						c)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Manter possibilidade de backup (cópia de segurança) ao Usuário por
							mais de 30 (trinta) dias após a exclusão ou alteração de dados por
							parte dos Usuários na Plataforma.
						</Typography>
					</Typography>
					<Typography sx={{ fontSize: "11px" }}>
						<strong>7.3. </strong> Apesar de a SISAGRI utilizar todos os esforços
						para melhorar a experiência, armazenamento e a segurança dos dados,{" "}
						<span style={{ textDecoration: "underline", fontWeight: "bolder" }}>
							não
						</span>{" "}
						é possível garantir que jamais aconteçam situações do tipo:
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						a)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Acidentais ou ilícitas de destruição, perda, alteração, comunicação
							e difusão; e
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						b)
						<Typography
							sx={{ marginLeft: "1em", marginBottom: "1em", fontSize: "11px" }}
						>
							“Bugs do sistema”, ou seja, falhas nos processos, lentidão e a
							impossibilidade de uso do sistema por algumas horas.
						</Typography>
					</Typography>
					<Typography sx={{ fontSize: "11px" }}>
						<strong>7.4. </strong> A SISAGRI não se responsabilizará, em nenhuma
						hipótese, por danos decorrentes de:
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						a)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Mau uso da Plataforma por parte do Usuário;
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						b)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Falhas e/ou problemas na utilização da plataforma atribuídos a uma
							conexão de Internet defeituosa, interrompida ou lenta ou a quaisquer
							outros problemas com a conexão ou serviço de Internet do Usuário;
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						c)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Interrupção da transmissão, difusão, disponibilização, recepção,
							obtenção e acesso à informação;{" "}
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						d)
						<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
							Perdas ocorridas em virtude de bugs imprevistos e/ou por conta de
							ocorrências externas a que toda empresa está sujeita; e
						</Typography>
					</Typography>
					<Typography
						sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
					>
						e)
						<Typography
							sx={{ marginLeft: "1em", marginBottom: "1em", fontSize: "11px" }}
						>
							Quaisquer outras interrupções de acesso à Plataforma,
							independentemente do que ou de quem lhe deu causa.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>8. DO PREÇO</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>8.1. Preço -</strong> Em virtude do licenciamento e serviços
							a que se refere o presente Contrato, o Usuário deverá à SISAGRI uma
							contraprestação em dinheiro, que irá variar de acordo com o plano e
							proposta aceita, de acordo com o disposto no{" "}
							<span style={{ textDecoration: "underline" }}>Anexo III</span> deste
							Contrato, e será pago no dia 05 ou dia 10 de cada mês, a depender do
							combinado com cada Usuário Contratante. A SISAGRI enviará a Nota
							Fiscal, em que constará os valores a serem pagos, com antecedência
							mínima de 05 (cinco) dias úteis da data de vencimento da parcela.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>8.1.2. Atualização do Preço -</strong> O valor da
							contraprestação pecuniária será corrigido monetariamente de forma
							automática na periodicidade anual (a cada 12 meses), pelo índice
							IGPM-FGV Positivo ou outro que venha a substituí-lo.
						</Typography>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>8.2. Impostos -</strong> Serão de exclusiva responsabilidade
							da SISAGRI os impostos, taxas, emolumentos e tributos em geral,
							devidos por força deste Contrato.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>
							9. DAS CONSEQUÊNCIAS DO NÃO PAGAMENTO - OU PAGAMENTO EM ATRASO
						</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>9.1.</strong> Deixando o Usuário Contratante de efetuar o
							pagamento da mensalidade dentro do prazo estipulado, a ele será
							imposta multa de 2% (dois por cento) do valor, mais juros de 1% (um
							por cento) ao mês e atualização pelo IGP-M Positivo, podendo ser
							cobradas despesas cartoriais, custas judiciais e 10% (dez por cento)
							de honorários advocatícios no caso de cobrança em cartório das
							mensalidades vencidas há mais de 45 (quarenta e cinco) dias.
						</Typography>
						<Typography
							sx={{ fontSize: "11px", marginBottom: "1em", marginLeft: "1em" }}
						>
							<strong>9.1.1.</strong> Caso não haja o pagamento da mensalidade
							depois de transcorridos 15 (quinze) dias da data de seu vencimento,
							poderá a SISAGRI, após prévia Notificação de 10 (dez) dias, não
							havendo o pagamento, suspender os serviços contratados, que somente
							serão restabelecidos 48h (quarenta e oito horas) após a comprovação
							da quitação do débito pelo Usuário Contratante.
						</Typography>
						<Typography
							sx={{ fontSize: "11px", marginBottom: "1em", marginLeft: "1em" }}
						>
							<strong>9.1.2.</strong> O inadimplemento da mensalidade, nos termos
							do item anterior, poderá implicar na rescisão do Contrato e/ou
							inclusão do nome do Usuário Contratante na relação do Serviço de
							Proteção ao Crédito (SPC) e SERASA, bem como em eventual Protesto de
							Título.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>10. DO CANCELAMENTO DO LICENCIAMENTO</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>10.1.</strong> Caso o Usuário deseje interromper a
							utilização da Plataforma, deverá enviar uma comunicação à SISAGRI,
							por meio do e-mail{" "}
							<a href="mailto:suportesisagri@gmail.com">
								suportesisagri@gmail.com
							</a>{" "}
							ou do whatsapp (85) 98131-0242, com antecedência mínima de 30
							(trinta) dias, sob pena de aplicação de multa correspondente ao
							valor de 01 (uma) mensalidade. Durante o prazo de aviso prévio, as
							Partes deverão continuar a cumprir todas as obrigações
							contratualmente assumidas.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>10.2.</strong> Caso a SISAGRI pretenda o encerramento do
							funcionamento da Plataforma, o Usuário será avisado com antecedência
							mínima de 30 (trinta) dias, não havendo que se falar em qualquer
							tipo de indenização devida pela SISAGRI ao Usuário ou a quaisquer
							terceiros.
						</Typography>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>10.3.</strong> Além da hipótese de encerramento não motivado
							prevista na Cláusula 10.1, O presente Contrato restará rescindido de
							pleno direito, mediante prévia comunicação escrita de 10 (dez) dias
							à outra Parte:
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							a)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Ocorrendo o descumprimento de qualquer cláusula contratual, não
								sanado no prazo de 10 (dez) dias, sem o prejuízo de perdas e
								danos, nos termos da legislação brasileira;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							b)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Na hipótese de liquidação ou recuperação judicial ou
								extrajudicial, ou convocação de credores para propor ou negociar
								plano de recuperação extrajudicial ou homologação, requerimento ou
								decretação de falência, insolvência civil ou medidas judiciais,
								desde tais acontecimentos impossibilitem ou afetem
								substancialmente a continuidade operacional deste Contrato; e
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							c)
							<Typography
								sx={{ marginLeft: "1em", marginBottom: "1em", fontSize: "11px" }}
							>
								Ocorrendo a cessão ou transferência, a qualquer título, dos
								direitos e obrigações assumidos neste Instrumento, a terceiros
								pela CONTRATADA, sem a expressa anuência prévia e por escrito da
								CONTRATANTE.
							</Typography>
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>11. DO CANCELAMENTO DA CONTA</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>11.1.</strong> Em qualquer tempo, caso o Usuário Contratante
							já tenha concluído ou cancelado seu contrato perante a SISAGRI,
							poderá também solicitar o encerramento do seu cadastro na
							Plataforma, enviando um e-mail para{" "}
							<a href="mailto:suportesisagri@gmail.com">
								suportesisagri@gmail.com
							</a>{" "}
							ou para o whatsapp (85) 98131-0242, com a referida solicitação.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>11.1.2.</strong> Após a rescisão ou cancelamento da conta, a
							SISAGRI se reserva ao direito de excluir todos os dados do Usuário
							Contratante no curso normal de operação, os quais não poderão ser
							recuperados após o cancelamento da conta.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>11.3.</strong> Após o cancelamento deste Contrato, a SISAGRI
							também se reserva ao direito de suspender ou encerrar a conta do
							Usuário Cliente da Plataforma após o prazo de 1 (um) ano e 05
							(cinco) meses, enviando e-mail ao Usuário e disponibilizando o prazo
							de 30 (trinta) dias para download do backup dos dados.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>12 DEVER DE CONFIDENCIALIDADE</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>12.1.</strong> As Partes se comprometem a, pelo prazo de 48
							(quarenta e oito) meses a contar do encerramento deste Contrato,
							manter o mais absoluto sigilo sobre as informações confidenciais que
							tiverem acesso em virtude da execução do objeto do presente
							Contrato, sob pena de extinção imediata do Contrato e indenização
							por todos os danos eventualmente causados à Parte prejudicada.
						</Typography>
						<Typography sx={{ fontSize: "11px" }}>
							<strong>12.2.1.</strong> Consideram-se informações confidenciais
							toda e qualquer informação de natureza sigilosa que seja de uso e/ou
							propriedade de qualquer uma das Partes, incluindo, mas não se
							limitando, a:
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							a)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Softwares, em forma de fonte ou de objeto, suas tecnologias,
								ideias, algoritmos relacionados ou informações neles contidas,
								incluindo, sem limitações, quaisquer segredos empresariais
								relacionados a qualquer um dos itens precedentes;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							b)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Planos de produtos de uma das Partes, seus projetos, custos,
								preços e nomes; informações financeiras não publicadas na mídia,
								planos de marketing, oportunidades de negócios, modelos ou planos
								de negócios, recursos humanos, pesquisa, desenvolvimento ou
								know-how;
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							c)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Qualquer informação que deva, por boa razão, ser reconhecida pela
								Parte como sendo confidencial ou de propriedade privada e
								exclusiva da referida; e
							</Typography>
						</Typography>
						<Typography
							sx={{ marginLeft: "1em", fontSize: "11px", display: "flex" }}
						>
							d)
							<Typography sx={{ marginLeft: "1em", fontSize: "11px" }}>
								Qualquer informação designada por escrito pela Parte como sendo
								confidencial ou, se designada de forma verbal quando de sua
								emissão, devidamente confirmada por escrito à Contraparte dentro
								de 10 (dez) dias após sua emissão.
							</Typography>
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>13. NÃO EXCLUSIVIDADE</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>13.1.</strong>Por tratar-se o presente Contrato de Licença
							de Uso, a SISAGRI é livre para oferecer serviços similares ou de
							qualquer outra espécie a terceiros, bem como celebrar acordos,
							contratos e parcerias com terceiros como bem considerar apropriado.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>14. DO TRATAMENTO DE DADOS PESSOAIS PERANTE TERCEIROS</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>14.1.</strong> A SISAGRI não comercializa, em hipótese
							alguma, dados ou informações dos seus usuários com terceiros,
							respeitados os limites e diretrizes da Lei Geral de Proteção de
							Dados (LGPD), brasileira, e da General Data Protection Regulation
							(GDPR), europeia.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>14.2</strong> Além disso, visando aos cumprimentos legais
							perante os entes públicos ou privados, a SISAGRI, quando solicitada,
							poderá compartilhar os dados pessoais sobre os quais tenha controle
							ou guarda, desde que respeitados os limites e diretrizes das
							legislações anteriormente citadas.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>15. DAS MEDIDAS DE SEGURANÇA </strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>15.1.</strong> A SISAGRI adota medidas técnicas e
							administrativas aptas a proteger os dados pessoais de acessos não
							autorizados e de situações acidentais ou ilícitas de destruição,
							perda, alteração, comunicação ou difusão.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>15.2</strong> Apesar das medidas de segurança adotadas, a
							SISAGRI não pode garantir que jamais aconteçam falhas ou situações
							ilícitas causadas por terceiros. O uso não autorizado de contas,
							falha de hardware ou software e outros fatores podem comprometer a
							segurança dos Dados Pessoais a qualquer momento.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>16. COMUNICAÇÃO ENTRE AS PARTES</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>16.1.</strong> Qualquer aviso, requisição ou outra
							comunicação sob este Contrato deverá ser efetuada por escrito, seja
							pelos meios eletrônicos (e-mail ou whatsapp) ou físico.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>16.2</strong> As mensagens em meio físico deverão ser
							enviadas, com comprovante de recebimento, para o endereço da Parte
							destinatária cadastrado no preâmbulo deste Contrato ou para qualquer
							outro endereço que referida Parte venha a indicar por escrito e as
							mensagens eletrônicas deverão ser enviadas aos endereços eletrônicos
							cadastrados no preâmbulo deste Contrato ou àquele posteriormente
							indicado por qualquer das Partes, desde que seguidas as formalidades
							de comunicação dispostas nesta cláusula.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>16.3</strong> A Parte que tiver alterado o(s) endereço(s)
							cadastrado no preâmbulo deste Contrato deverá comunicar
							imediatamente o novo endereço à outra Parte. Até que seja feita essa
							comunicação, serão válidos e eficazes os avisos, as comunicações, as
							notificações e as interpelações enviadas ao(s) endereço(s)
							cadastrados no preâmbulo deste Contrato.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>16.4</strong> Além do contato por meio do e-mail acima
							citado, os usuários terão disponíveis dentro da Plataforma, suporte,
							em texto, em vídeo e direcionamento para dúvidas, problemas, e
							requisições.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>17. DISPOSIÇÕES GERAIS</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>17.1.</strong> O presente Contrato exclui qualquer vínculo
							empregatício entre as partes, uma vez que é regido nos termos dos
							artigos 593 a 609 do Código Civil, no capítulo em que trata da
							prestação de serviços.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>17.2</strong> Caso qualquer das disposições deste Contrato
							venha a ser julgada ilegal, inválida ou ineficaz, as demais
							disposições não serão afetadas por tal julgamento, comprometendo-se
							as Partes, em boa-fé, a substituírem a disposição afetada por outra
							que, na medida do possível, produza o mesmo efeito.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>17.3</strong> A eventual tolerância ao descumprimento de
							qualquer obrigação prevista neste instrumento, a falta da aplicação
							de qualquer sanção ou abstenção do exercício de qualquer direito
							aqui conferido às Partes será considerada mera liberalidade, não
							implicando em transação, novação ou renúncia aos respectivos
							direitos e condições.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>17.4</strong> Eventuais alterações do presente Instrumento,
							bem como de seus eventuais aditivos, adiantamentos e prorrogações,
							somente terão validade se promovidas de comum acordo entre as
							Partes, através de instrumento escrito, rubricado e assinado.
						</Typography>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>17.5</strong> A CONTRATANTE não poderá ceder ou transferir
							os direitos e obrigações do presente Contrato, seja a que título
							for, sem a expressa concordância da CONTRATADA.
						</Typography>
					</Typography>
					<Typography sx={{ marginBottom: "1em", fontSize: "11px" }}>
						<strong>18. DO FORO</strong>
						<Typography sx={{ fontSize: "11px", marginBottom: "1em" }}>
							<strong>18.1.</strong> O presente documento possui vigência em total
							consonância com a legislação ao tempo da adesão do Usuário
							Contratante, elegendo o foro da cidade de Fortaleza/CE para dirimir
							quaisquer dúvidas ou litígios decorrentes do presente Instrumento.
						</Typography>
					</Typography>
					<Container sx={{ textAlign: "center", marginBottom: "1em" }}>
						<Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
							ANEXO I - PRAZO DO CONTRATO
						</Typography>
					</Container>
					<li style={{ marginBottom: "1em" }}>
						O presente contrato vigorará pelo prazo mínimo de 12 (doze) meses, a
						partir da data de assinatura deste contrato, podendo ser prorrogado,
						mediante instrumento próprio, pelo tempo necessário à completa
						execução dos serviços ora contratados desde que de comum acordo entre
						as partes contratantes.
					</li>
					<Container sx={{ textAlign: "center", marginBottom: "1em" }}>
						<Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
							ANEXO II - MATERIAIS LICENCIADOS
						</Typography>
					</Container>
					<li style={{ marginBottom: "1em" }}>
						O presente contrato tem com matéria MATERIAL LICENCIADO, a prestação,
						pelo CONTRATADO, de licença da Plataforma Web e Mobile - Caderno de
						Campo Digital, ao CONTRATANTE, que apresenta as seguintes
						funcionalidades:
					</li>
					<div style={{ marginBottom: "1em" }}>
						<TableContainer sx={{ display: "flex", placeContent: "center" }}>
							<Table
								sx={{ maxWidth: 400 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												fontSize: "11px",
												border: "1px solid black",
												padding: 0,
												textAlign: "center",
											}}
										>
											{" "}
											<strong>Funcionalidades</strong>{" "}
										</TableCell>
										<TableCell
											sx={{
												fontSize: "11px",
												border: "1px solid black",
												padding: 0,
												textAlign: "center",
											}}
										>
											<strong> Habilitação </strong>{" "}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody sx={{ fontSize: "11px" }}>
									{rows.map((row) => (
										<TableRow key={row.funcionalidade} sx={{ fontSize: "11px" }}>
											<TableCell
												sx={{
													fontSize: "11px",
													border: "1px solid black",
													padding: 0,
													textAlign: "center",
												}}
												component="th"
												scop
												e="row"
											>
												{row.funcionalidade}
											</TableCell>
											<TableCell
												component="th"
												sx={{
													fontSize: "11px",
													border: "1px solid black",
													padding: 0,
													textAlign: "center",
												}}
												scope="row"
											>
												<Radio size="11" checked={row.habilitação} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<Container sx={{ textAlign: "center", marginBottom: "1em" }}>
						<Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
							ANEXO III - PREÇO
						</Typography>
					</Container>
					<li style={{ marginBottom: "1em" }}>
						A contraprestação pecuniária a ser realizada pela CONTRATANTE em favor
						da CONTRATADA pela prestação dos serviços e pelo licenciamento do
						Software tratados neste Contrato será de R${" "}
						<strong>200,00(duzentos reais),</strong> a serem pagos a cada dia 05
						(cinco), ou o dia útil anterior mais próximo, do mês subsequente à
						prestação.
					</li>
					<li style={{ marginBottom: "1em" }}>
						O Contratante terá direito a um desconto especial de R$ de{" "}
						<strong>150,00 (cento de cinquenta reais)</strong> mensais, reduzindo
						o valor mensal de R${" "}
						<strong>350,00 (trezentos e cinquenta reais)</strong> para{" "}
						<strong>R$ 200,00 (duzentos reais)</strong> mensais pelo licenciamento
						do Software, durante o período de fidelidade de 12(doze) meses. Caso o
						Contrato seja renovado após referido período, a atualização do seu
						preço será feita considerando o valor ordinário, e não o valor com
						desconto.
					</li>
				</Typography>
			</Box>
		);
	}
}
