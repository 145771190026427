/* eslint-disable indent */
import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as I from "../IconsImport";
import Btn from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DropDownPropertySubItem from "../DropDownPropertySubItem";
import { Box, Modal, TextField } from "@mui/material";

//import css modules
/* import "./style.css"; */
import styles from "./DropDownProperty.module.css";

//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "100%",
	transform: "translate(-50%, -50%)",
};
const styleMenu = { color: "white", margin: 0 };

export default function ButtonsDropdown(props) {
	const [open, setOpen] = useState(true);
	const [openPlotRegister, setOpenPlotRegister] = useState(false);
	const [openSubdivisionRegister, setOpenSubdivisionRegister] = useState(false);
	const [openSubdivisionAreaEdit, setOpenSubdivisionAreaEdit] = useState(false);
	const [subdivisionArea, setSubdivisionArea] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState("0");
	const [subdivisionComplement, setSubdivisionComplement] = useState("");
	const [newPlot, setNewPlot] = useState(props.row);
	const [isPoint, setIsPoint] = useState(true);
	const allCategories = {
		PARCELA: "Parcela",
		SETOR: "Setor",
		CANTEIRO: "Canteiro",
		BANCADA: "Bancada",
		BALCAO: "Balcão",
		BLOCO: "Bloco",
		GLEBA: "Gleba",
		QUADRA: "Quadra",
		LOTE: "Lote",
	};
	const openMenu = Boolean(anchorEl);
	const [data] = useState(props.subItem);
	const plot = props.row;

	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEditArea = () => {
		props.handleEditArea(plot);
		setAnchorEl(null);
	};

	const handleEditOthersArea = () => {
		props.handleEditOthersArea(plot);
		setAnchorEl(null);
	};

	const handleClick = () => {
		setOpen(!open);
	};
	const handleOpenItemName = () => {
		setOpenPlotRegister(true); //abre o modal
		handleClose();
	};
	const cancelPlotUpdate = () => {
		//vira uma function para ca
		setNewPlot(plot);
		setOpenPlotRegister(false); //fecha o modal
	};

	const savePlot = () => {
		//vira uma function para ca
		props.updatePlot(newPlot);
		setOpenPlotRegister(false); //fecha o modal
	};

	const handleCloseParcela = () => {
		//vira uma function para ca
		// props.SalvNameTalhao(areaItem, nameItem);
		setOpenSubdivisionRegister(false); //fecha o modal
	};

	const handleCloseSubdivisionAreaEdit = () => {
		//vira uma function para ca
		// props.SalvNameTalhao(areaItem, nameItem);
		setOpenSubdivisionAreaEdit(false); //fecha o modal
	};

	const updateSubdivisionArea = () => {
		//vira uma function para ca
		// props.SalvNameTalhao(areaItem, nameItem);
		setOpenSubdivisionAreaEdit(false); //fecha o modal
	};

	const handleDelete = (item) => {
		//vira uma function para ca
		props.handleDelete(item);
		setAnchorEl(null);
	};

	const [anchorElAdd, setAnchorElAdd] = React.useState(null);
	const openAdd = Boolean(anchorElAdd);

	const handleClickAdd = (event) => {
		setAnchorElAdd(event.currentTarget);
	};
	const handleCloseAdd = () => {
		setAnchorElAdd(null);
	};
	const openSubdivisionToRegister = () => {
		setAnchorEl(null);
		setAnchorElAdd(null);
		setOpenSubdivisionRegister(true);
	};

	const handleSaveParcela = () => {
		setOpenSubdivisionRegister(false);
		// let tipoArea = "";
		// var tiposArea = document.getElementsByName("tipoArea");
		// for (let i = 0; i < tiposArea.length; i++) {
		// 	if (tiposArea[i].type === "radio")
		// 		if (tiposArea[i].checked) tipoArea = tiposArea[i].value;
		// }

		const subdivisionToSave = {
			complement: subdivisionComplement,
			active: true,
			acre_area: Number(subdivisionArea.replaceAll(",", ".")),
			subdivisionCategory: selectedCategory,
			coordinates: [],
			plotId: plot.id,
			parentSubdivision: null
		};

		props.saveSubdivision(
			subdivisionToSave,
			isPoint
		);
	};
	const handleClickQrCode = () => {
		window.location.href =
			"/pdfQrcode/?pag=GerarQrcodeTalhao&item=" + props.idItem;
	};
	function validationPlot() {
		if (
			plot.nome === "Sede" ||
			plot.nome === "Reservatorio" ||
			plot.nome === "Reserva legal" ||
			plot.nome === "Corpo hidríco"
		)
			return false;
		else return true;
	}
	return (
		<List
			className={styles.containerItem}
			sx={{
				width: "100%",
				p: 0,
				px: 2,
				color: "white",
				maxHeight: 300,
				overflowY: "auto",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<ListItemButton>
				<ListItemIcon sx={{ color: "white" }}>
					<LocationOnIcon />
				</ListItemIcon>

				{props.subItem !== undefined &&
				props.subItem !== null &&
				props.subItem.length !== 0 ? (
					<>
						<div className={styles.itemListWithSubList} onClick={handleClick}>
							<p>{`Talhão ${props.item}`}</p>
						</div>
						{open ? (
							<ExpandLess onClick={handleClick} />
						) : (
							<ExpandMore onClick={handleClick} />
						)}

						<div>
							<Btn
								aria-controls={openMenu ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={openMenu ? "true" : undefined}
								onClick={handleClickMenu}
							>
								<I.IconMoreVert style={styleMenu} />
							</Btn>
						</div>
						<div>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={openMenu}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={handleEditArea}>Editar área</MenuItem>
								{/* <MenuItem onClick={() => handleAtivar(props.row)}>Ativar</MenuItem> */}
								<MenuItem onClick={() => handleDelete(props.row)}>Excluir</MenuItem>
								<MenuItem onClick={() => handleOpenItemName("item")}>
									Editar dados
								</MenuItem>
								<MenuItem onClick={handleClickQrCode}>Gerar QR code</MenuItem>

								<MenuItem onClick={handleClickAdd}>Adicionar +</MenuItem>
							</Menu>
							<Menu
								id="basic-menu"
								anchorEl={anchorElAdd}
								open={openAdd}
								onClose={handleCloseAdd}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={openSubdivisionToRegister}>Subdivisão</MenuItem>
							</Menu>
						</div>
					</>
				) : (
					<>
						<ListItemText primary={validationPlot() ?`Talhão ${plot.nome}`: plot.nome} />
						<ListItemIcon sx={{ color: "white" }}>
							<Btn
								aria-controls={openMenu ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={openMenu ? "true" : undefined}
								onClick={handleClickMenu}
							>
								<I.IconMoreVert style={styleMenu} />
							</Btn>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={openMenu}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={validationPlot() ? handleEditArea : handleEditOthersArea}>Editar área</MenuItem>
								{/* {validationSubDiv() && (
									<MenuItem onClick={() => handleAtivar(props.row)}>Ativar</MenuItem>
								)} */}
								<MenuItem onClick={() => handleDelete(props.row)}>Excluir</MenuItem>

								{validationPlot() && (
									<>
										<MenuItem onClick={() => handleOpenItemName("item")}>
											Editar dados
										</MenuItem>
										<MenuItem onClick={handleClickQrCode}>Gerar QR code</MenuItem>
										<MenuItem onClick={handleClickAdd}>Adicionar +</MenuItem>
									</>
								)}
							</Menu>
							<Menu
								id="basic-menu"
								anchorEl={anchorElAdd}
								open={openAdd}
								onClose={handleCloseAdd}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={openSubdivisionToRegister}>Subdivisão</MenuItem>
							</Menu>
						</ListItemIcon>
					</>
				)}
			</ListItemButton>
			{data !== null ? (
				<DropDownPropertySubItem
					subItem={data}
					open={open}
					areaId={plot.itemId}
					handleDelete={props.handleDeleteItem}
					handleAtivar={props.handleAtivar}
					handleEditArea={props.handleEditItemArea}
					saveSubdivision={props.saveSubdivision}
					row={props.row}
				/>
			) : null}

			<Modal
				open={openPlotRegister}
				onClose={cancelPlotUpdate}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Editar dados de talhão
							</p>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<TextField
									id="filled-textarea"
									label="Identificação"
									placeholder="Identificação"
									multiline
									variant="outlined"
									value={newPlot.nome}
									onChange={(e) => setNewPlot({...newPlot, nome: e.target.value})}
									sx={{ width: "100%", bgcolor: "white" }}
								/>
							</Item>
						</Grid>

						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<div className={styles.registrationInput}>
									<input
										type="number"
										placeholder="Área em hectares"
										id="hectare"
										onChange={(e) => setNewPlot({...newPlot, area_hectares: e.target.value})}
										value={newPlot.area_hectares}
									/>
								</div>
							</Item>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={cancelPlotUpdate}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={savePlot}
											>
												Salvar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Modal>

			<Modal
				open={openSubdivisionRegister}
				onClose={handleCloseParcela}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={styles}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Cadastrar subdivisão
							</p>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={styles.registrationInput}>
								<select
									id="category"
									onChange={(e) => setSelectedCategory(e.target.value)}
									value={selectedCategory}
									className={
										selectedCategory === "0"
											? styles.selectInputPlaceholder
											: styles.selectInputPlace
									}
								>
									<option value={"0"}>
										{selectedCategory !== "0"
											? "--------------- *"
											: "Selecionar tipo de subdivisão *"}
									</option>
									{Object.keys(allCategories).map((key, index) => {
										return (
											<option value={key} key={index}>
												{allCategories[key]}
											</option>
										);
									})}
								</select>
							</div>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<input
								type="radio"
								name="tipoArea"
								id="ponto"
								value="ponto"
								checked={isPoint}
								onChange={() => setIsPoint(true)}
							/>
							<label htmlFor="ponto"> Ponto</label>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<input
								type="radio"
								name="tipoArea"
								id="area"
								value="area"
								checked={!isPoint}
								onChange={() => setIsPoint(false)}
							/>
							<label htmlFor="area"> Area</label>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={styles.registrationInput}>
								<input
									type="text"
									placeholder="Identificação *"
									value={subdivisionComplement}
									onChange={(e) => setSubdivisionComplement(e.target.value)}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
								}}
							>
								<div className={styles.registrationInput}>
									<input
										type="text"
										placeholder="Área em hectares *"
										value={subdivisionArea}
										onChange={(e) => setSubdivisionArea(e.target.value)}
									/>
								</div>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseParcela}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleSaveParcela}
									>
										Salvar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>

			<Modal
				open={openSubdivisionAreaEdit}
				onClose={handleCloseSubdivisionAreaEdit}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={styles}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Editar área
							</p>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "row",
										p: 0,
										mx: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={6}>
										<input type="radio" name="tipoArea" id="ponto" value="ponto" defaultChecked={true} />
										<label htmlFor="ponto"> Ponto</label>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<input type="radio" name="tipoArea" id="area" value="area" />
										<label htmlFor="area"> Area</label>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 1, md: 1 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseSubdivisionAreaEdit}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={updateSubdivisionArea}
									>
										Salvar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</List>
	);
}
