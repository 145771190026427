import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./InputSearch.module.css";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: 8,
	backgroundColor: alpha(theme.palette.common.white, 0.8),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 1),
	},
	width: "100%",
	height: "100%",
	[theme.breakpoints.up("sm")]: {
		width: "100%",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: "rgba(91, 91, 93, 0.9);",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		height: "100%",
		width: "100%",
		borderRadius: "8px",
	},
}));

function InputSearch(props) {
	return (
		<Search>
			<SearchIconWrapper>
				<SearchIcon />
			</SearchIconWrapper>
			<StyledInputBase
				className={styles.inputSearch}
				placeholder="Faça uma Pesquisa"
				inputProps={{ "aria-label": "search" }}
				// eslint-disable-next-line react/prop-types
				onChange={props.func}
			/>
		</Search>
	);
}

export default InputSearch;
