import React, { useState, useEffect } from "react";
import styles from "./LoginRequest.module.css";
import logo from "../../../../Assets/images/logo.svg";

// icons import
import HttpsIcon from "@mui/icons-material/Https";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
// import { Auth } from "aws-amplify";

// import { API, Auth } from "aws-amplify";
// import { useParams } from "react-router-dom";
// import * as queries from "../../../../graphql/queries";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function LoginFinalizeRegistration() {
	const [showPassword, setShowPassword] = useState(true);
	const [password, setPassword] = useState("");
	const [login, setLogin] = useState("");
	// const [email, setEmail] = useState("");
	// const [valuePhone, setvaluePhone] = useState(null);
	const [errorMsg, setErrorMsg] = useState("");
	const [code, setCode] = useState("");
	const [sentCode, setSentCode] = useState(false);
	// const { id } = useParams();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		// let user = await API.graphql({
		// 	// query: queries.getUsuario,
		// 	variables: { id: id },
		// });
		let user = null;
		user = user.data.getUsuario;
		setLogin(user.login.toLowerCase().trim());
		// setEmail(user.email);
		// if(user.telefon !== null && user.telefon !== undefined)
		// 	setvaluePhone(user.telefon.substring(3));
	};

	function handleClick() {
		setShowPassword(!showPassword);
		if (showPassword) document.getElementById("password").type = "text";
		else document.getElementById("password").type = "password";
	}

	const SaveDataAndSendCode = async (e) => {
		e.preventDefault();
		if (password && password !== "") {
			try {
				// await Auth.signUp({
				// 	username: login,
				// 	password,
				// 	attributes: {
				// 		email,
				// 		phone_number: valuePhone !== null && valuePhone !== undefined && valuePhone !== "" ? `+55${valuePhone}` : null,
				// 	},
				// 	autoSignIn: {
				// 		enabled: false,
				// 	},
				// });
				setSentCode(true);
			} catch (error) {
				if (error.code === "UsernameExistsException")
					setErrorMsg("Usuário já existe");
				// setConfirmationNeeded(true);
				// else if(err.code === "UserNotFoundException") {
				// 	setErrorMsg("Usuário não encontrado");
				// } else if (err.code === "NotAuthorizedException"){
				// 	setErrorMsg("Senha incorreta");
				// }
				if (error.code === "InvalidPasswordException")
					setErrorMsg("A senha deve ter pelo menos 8 digitos");
			}
		} else {
			setErrorMsg("Senha deve ser preenchida");
		}
	};

	const resendCode = async (e) => {
		e.preventDefault();
		try {
			// await Auth.resendSignUp(login);
		} catch (error) {
			setErrorMsg("Não foi possível reenviar o código. Se o erro persistir entre em contato com o suporte.");
		}
	};

	const validateCode = async (e) => {
		e.preventDefault();
		try {
			// await Auth.confirmSignUp(login, code);
			window.location.href = "/";
		} catch (error) {
			setErrorMsg("Houve um erro durante a validação do código.");
		}
	};

	//hltm login
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "ceter",
					alignItems: "center",
					bgcolor: "white",
					p: 0,
					m: 0,
				}}
			>
				<Box
					sx={{
						p: 0,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "ceter",
							alignItems: "center",
							flexDirection: {
								xs: "column-reverse",
								sm: "column-reverse",
								md: "row",
							},
						}}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								justifyContent: "ceter",
								alignItems: "center",
							}}
						>
							<Item
								sx={{
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100%",
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									sx={{ px: { xs: 3, sm: 3, md: 2 } }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											sx={{
												with: "100%",
												height: "100% ",
												border: "none",
												boxShadow: "none",
											}}
										>
											<img className={styles.logo} src={logo} />
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Item
											className={styles.loginTextContainer}
											sx={{ width: "100%", border: "none", boxShadow: "none" }}
										>
											<h2>Tecnologia com soluções para o agronegocio</h2>
											<p>
												Facilitamos a adequação ás legislações referentes a
												rastreabiidade vegetal, garantindo a segurança dos
												alimentos.
											</p>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
						{/* form  */}
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								background: "linear-gradient(180deg, #1A877F 0%, #12B6AA 100%)",
							}}
						>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									display: "flex",
									justifyContent: "ceter",
									alignItems: "center",
									height: "100vh",
									p: { xs: 3, sm: 15, md: 15 },
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											m: 0,
											height: "100%",
										}}
									>
										<Item sx={{ border: "none", boxShadow: "none" }}>
											<h2 className={styles.title}>Finalizar Cadastro</h2>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<p className={styles.mensagemError}>{errorMsg}</p>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<PersonIcon className={styles.icon} />
														<input
															id="user"
															type="text"
															className={styles.input}
															value={login}
															disabled={true}
															placeholder="Login"
														/>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													>
														<HttpsIcon className={styles.icon} />
														<input
															id="password"
															type="password"
															className={styles.input}
															placeholder="Senha"
															value={password}
															onChange={(e) => setPassword(e.target.value)}
														/>
														<div onClick={handleClick}>
															{showPassword ? (
																<VisibilityIcon
																	className={styles.iconPassword}
																/>
															) : (
																<VisibilityOffIcon
																	className={styles.iconPassword}
																/>
															)}
														</div>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={{ border: "none", boxShadow: "none" }}>
														<button
															className={styles.buttonLogin}
															type="submit"
															onClick={SaveDataAndSendCode}
														>
															Cadastrar
														</button>
													</Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													></Item>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													<Item
														className={styles.formInput}
														sx={{ border: "none", boxShadow: "none" }}
													></Item>
												</Grid>
												{sentCode && (
													<>
														<Grid item xs={12} sm={12} md={12}>
															<Item
																className={styles.formInput}
																sx={{ border: "none", boxShadow: "none" }}
															>
																<input
																	id="user"
																	type="text"
																	className={styles.input}
																	placeholder="Código de confirmação"
																	value={code}
																	onChange={(e) => setCode(e.target.value)}
																	disabled={!sentCode}
																/>
															</Item>
														</Grid>
														<Grid
															item
															xs={12}
															sm={12}
															md={12}
															sx={{ mt: -3, mb: 3 }}
														>
															<p className={styles.reenviarCodText} onClick={resendCode}>
																Reenviar código de confirmação
															</p>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<Item sx={{ border: "none", boxShadow: "none" }}>
																<button
																	className={styles.buttonLogin}
																	type="submit"
																	onClick={validateCode}
																>
																	Enviar código
																</button>
															</Item>
														</Grid>
													</>
												)}
											</Grid>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default LoginFinalizeRegistration;
