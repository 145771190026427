import { StockRepository } from "../repository/stock_repository";

export class StockService {

	constructor() {
		this.stockRepository = new StockRepository();
	}

	async save(stock) {
		const savedStock = await this.stockRepository.save(stock);
		return savedStock;
	}

	async update(stock) {
		const savedStock = await this.stockRepository.update(stock);
		return savedStock;
	}

	async getInputs(propertyId) {
		const stocks = await this.stockRepository.getInputs(propertyId);
		return stocks;
	}

	async getProducts(propertyId) {
		const stocks = await this.stockRepository.getProducts(propertyId);
		return stocks;
	}

	async getEntries(propertyInputId) {
		const stocks = await this.stockRepository.getEntries(propertyInputId);
		return stocks;
	}

	async getPrescription(stockId) {
		const stocks = await this.stockRepository.getPrescription(stockId);
		return stocks;
	}

	async getInvoice(stockId) {
		const stocks = await this.stockRepository.getInvoice(stockId);
		return stocks;
	}

	async deleteEntry(stockId) {
		const stocks = await this.stockRepository.deleteEntry(stockId);
		return stocks;
	}

	async getById(stockId) {
		const stocks = await this.stockRepository.getById(stockId);
		return stocks;
	}

	async changeVisibilityEntry(id) {
		await this.stockRepository.changeVisibilityEntry(id);
	}
}