import axios from "axios";

export class OccurrencesRepository {

	constructor() {
		this.axios = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 30000,
		});
	}

	async save(occurrences) {
		try {
			const result = await this.axios.post("incident", occurrences, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
					"Content-Type": "multipart/form-data"

				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async update(occurrences) {
		try {
			const result = await this.axios.put("incident", occurrences, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
					"Content-Type": "multipart/form-data"
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async delete(id) {
		try {
			const result = await this.axios.delete(`incident/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async changeVisibility(id) {
		try {
			const result = await this.axios.put(`incident/visibility/${id}`, {},  {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});

			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
	
	async getByProperty(propertyId, params) {
		try {
			const result = await this.axios.get(`incident/property/${propertyId}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getByOccurrencesCropSeason(propertyId, params) {
		try {
			const result = await this.axios.get(`incident/crop-season/${propertyId}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async deleteIncidentCropSeason(cropSeasonId,incidentId) {
		try {
			const result = await this.axios.delete(`incident/crop-season/${cropSeasonId}/${incidentId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getById(occurrencesId) {
		try {
			const result = await this.axios.get(`incident/${occurrencesId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getImages(occurrencesId) {
		try {
			const result = await this.axios.get(`incident/${occurrencesId}/images`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getAllCategories() {
		try {
			const result = await this.axios.get("incident/category/all", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}

	async getAllTypes(params) {
		try {
			const result = await this.axios.get("incident/type/all", {
				params: params,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			});
			return result.data;
		} catch (error) {
			console.log(error);
			if(error.response.status === 401) 
				window.location.href = "/";

			throw new Error("Erro interno.");
		}
	}
}