import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TopMenu from "../../../components/TopMenu";
import styles from "./serviceCad.module.css";

//material ul imports
import { Autocomplete, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ptBR } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItems = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "stretch",
	justifyContent: "stretch",
	widths: "auto",
	m: 0,
	p: 0,
	minHeight: "45px",
	minWidth: "40vw",
};

//components
import HelpButtonInput from "../../../components/HelpButtonInput";
import RegisterArea from "../../../components/RegistrationArea";
import { GroupServiceService } from "../../../service/group_service_service";
import { PropertyServiceService } from "../../../service/property_service_service";
import { PropertyService } from "../../../service/property_service";
import Loading from "../../../components/Loading";

function ServiceCad() {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const [serviceInProperty, setServiceInProperty] = useState({grupoServico: null, servico: null});
	const [allServices, setAllServices] = useState([]);
	const [servicesToShow, setServicesToShow] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [propriedades, setPropriedades] = useState([]);
	const [errorMessageService, setErrorMessageService] = useState(false);
	const [errorMessageHour, setErrorMessageHour] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const serviceRef = useRef(null);

	const groupServiceService = new GroupServiceService();
	const propertyServiceService = new PropertyServiceService();
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {

		let groups = await groupServiceService.get();
		groups = groups.map((item, index) => {
			return {...item,
				index: index,
				firstLetter: item.nome[0].toUpperCase(),
			};
		});
		setAllGroups(groups);

		let services = await groupServiceService.getServices();
		services = services.map((item, index) => {
			return {...item,
				index: index,
				firstLetter: item.nome[0].toUpperCase(),
			};
		});
		setAllServices(services);
		setServicesToShow(services);

		if(id) {
			let service = await propertyServiceService.getById(id);

			const selectedService = services.find((serv) => serv.id === service.servico.id);
			const selectedGroup = groups.find((group) => group.id === service.servico.grupoServicoId);

			service = {...service,
				servico: selectedService,
				servicoId: selectedService.id,
				grupoServico: selectedGroup,
				grupoServicoId: selectedGroup.id
			};

			setServicesToShow(services.filter((serv) => serv.grupoServicoId === selectedGroup.id));

			setServiceInProperty(service);

			const properties = await getPropertiesForService(service);
			setPropriedades(properties);
		} else {
			const properties = await getPropertiesForBusiness();
			setPropriedades(properties);
		}
		setIsLoading(false);
	};

	const getPropertiesForBusiness = async () => {
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);
		const propertyId = localStorage.getItem("propertyId");
		const properties = businessProperty.map((property, i) => {
			const selected = property.id === propertyId;
			return {
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: selected,
				changed: selected,
			};
		});
		return properties;
	};

	const getPropertiesForService = async (service) => {
		const properties = [];
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);

		for (let i = 0; i < businessProperty.length; i++) {
			const property = businessProperty[i];
			const servicePropertyIndex = service.propriedades.findIndex((prop) => prop.propriedade.id === property.id);
			properties.push({
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: servicePropertyIndex >= 0,
				changed: false,
			});
		}
		return properties;
	};

	const validationSave = async () => {
		if (serviceInProperty.servicoId) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageService(false);
			setErrorMessageHour(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!serviceInProperty.servicoId) {
				message += "Serviço, ";
				const input = serviceRef.current?.querySelector("input");
				input.focus();
				setErrorMessageService(true);
			} else {
				setErrorMessageService(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const propertiesToSave = propriedades.filter((prop) => prop.changed && prop.selected);
		const propertiesToSaveId = propertiesToSave.map((prop) => prop.propertyId);

		let propertyServiceToSave = {
			justification: serviceInProperty.justificativa,
			serviceId: serviceInProperty.servicoId,
			hourValue: serviceInProperty.valorHora,
			includeProperties: propertiesToSaveId,
		};

		if (!id){
			await propertyServiceService.save(propertyServiceToSave);
		} else {
			const propertiesToRemove = propriedades.filter((prop) => prop.changed && !prop.selected);
			const propertiesToRemoveId = propertiesToRemove.map((prop) => prop.propertyId);

			propertyServiceToSave.id = id;
			propertyServiceToSave.removeServiceProperties = propertiesToRemoveId;
			await propertyServiceService.update(propertyServiceToSave);
		}
	};

	const handlePropertyChangeAll = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			let tempPropriedade = propriedades.map((propriedades) => {
				let propried = propriedades;
				propried.selected = checked;
				propried.changed = checked;
				return propried;
			});
			setPropriedades(tempPropriedade);
		}
	};

	const handlePropertyChange = (item) => {
		const newPropriedades = propriedades.slice();
		const property = propriedades[item.id];
		property.selected = !property.selected;
		property.changed = !property.changed;
		newPropriedades[item.id] = property;
		setPropriedades(newPropriedades);
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu
					open={["register", "services"]}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											id
												? "Editar serviço"
												: "Cadastro de serviço"
										}
										href="/servicos"
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
										help={id ? "servicesEdit" : "servicesCad"}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItems}>
													<div
														className={
															errorMessageService
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageService
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography 
																variant="caption" 
																display="block" 
																gutterBottom 
																sx={{
																	top:-11,
																	position:"absolute",
																	px:1,
																	backgroundColor:"white",
																	visibility:(serviceInProperty.servicoId ? "visible" : "hidden"),
																	transition:"all 0.5s ease"}} >
																Serviço *
															</Typography>
															<ThemeProvider theme={theme}>
																<Autocomplete
																	ref={serviceRef}
																	options={servicesToShow.sort(
																		(a, b) =>
																			-b.firstLetter.localeCompare(a.firstLetter)
																	)}
																	groupBy={(option) => option.firstLetter}
																	getOptionLabel={(option) => option.nome}
																	sx={{ width: "100%", border: "none" }}
																	value={serviceInProperty.servico}
																	onChange={(event, newValue) => {
																		const groupService = allGroups.find((group) => group.id === newValue.grupoServicoId);
																		setServiceInProperty({
																			...serviceInProperty, 
																			grupoServicoId: newValue.grupoServicoId,
																			servicoId: newValue.id, 
																			servico: newValue,
																			grupoServico: groupService
																		});
																	}}
																	renderInput={(params) => (
																		<div ref={params.InputProps.ref}>
																			<input
																				type="text"
																				{...params.inputProps}
																				placeholder="Serviço *"
																				className={styles.inputServico}
																			/>
																	
																		</div>
																	)}
																/>
															</ThemeProvider>
														</Box>
													</div>
													<HelpButtonInput
														title={"SERVIÇO"}
														text={
															"Selecione um grupo de serviço para filtrar a lista de serviços (opcional), ou escolha diretamente um serviço."
														}
													/>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												
												<div
													className={
														errorMessageHour
															? styles.errControl
															: styles.noErrControl
													}
												><	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "row",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(serviceInProperty.valorHora?"visible":"hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Valor por hora
														</Typography>

														<p
															className={
																errorMessageHour
																	? styles.errText
																	: styles.noErrText
															}
														>
															*
														</p>

														<input
															value={serviceInProperty.valorHora}
															onChange={e => setServiceInProperty({...serviceInProperty, valorHora: Number(e.target.value)})}
															type="number"
															placeholder="Valor por hora"
															id="valorHora"
															className={styles.inputServico}
														/>
													</Box>
													<HelpButtonInput
														title={"VALOR POR HORA"}
														text={
															"Registre o custo por hora de serviço. Se não possuir um valor fixo faça uma estimativa baseada no salário e nas horas de trabalho de um período (mensal, quinzenal, semanal ou diária)."
														}
													/>
												</div>											
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<div className={styles.noErrControl}>
													<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(serviceInProperty.grupoServicoId ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Grupo de serviço *
														</Typography>

														<ThemeProvider theme={theme}>
															<Autocomplete
																options={allGroups.sort(
																	(a, b) =>
																		-b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.nome}
																sx={{ width: "100%", border: "none" }}
																value={ serviceInProperty.grupoServico ?? null}
																onChange={(event, newValue) => {
																	setServiceInProperty({...serviceInProperty, grupoServicoId: newValue.id, grupoServico: newValue});
																	if(newValue.id)
																		setServicesToShow(allServices.filter((service) => service.grupoServicoId === newValue.id));
																	else
																		setServicesToShow(allServices);
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Grupo de serviço *"
																			className={styles.inputServico}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</Box>
													<HelpButtonInput
														title={"GRUPO DE SERVIÇO"}
														text={
															"Ao selecionar um serviço, ele estará vinculado a um grupo predefinido para melhor organização dos seus dados. Fique atento, pois alguns serviços pertencem a mais de um grupo. Você pode escolher o grupo ao qual deseja associá-los, se preferir."
														}
													/>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(serviceInProperty.justificativa ?"visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Observações
													</Typography>
													<input
														value={serviceInProperty.justificativa}
														onChange={e => setServiceInProperty({...serviceInProperty, justificativa: e.target.value})}
														type="text"
														placeholder="Observações"
														id="justificativa"
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item
													sx={{
														// border: "1px solid #b1b1b1",
														boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
														display: "flex",
														flexDirection: "column",
														alignItems: "flex-start",
														height: "100%",
													}}
												>
													<p className={styles.labelTitle}>
														<b>Propriedades *</b>
													</p>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="allSelect"
															id="propriedade"
															checked={
																!propriedades.some(
																	(propriedade) => propriedade.selected !== true
																)
															}
															onChange={(e) => handlePropertyChangeAll(e)}
														/>
														<label
															htmlFor="propriedade"
															className={styles.labelTitle}
														>
															<b>
																Cadastre o serviço simultaneamente na suas outras
																propriedades
															</b>
														</label>
													</div>
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 1, sm: 2, md: 3 }}
													>
														{propriedades.map((propriedade, index) => (
															<Grid
																item
																xs={"auto"}
																key={index}
																sx={{ minWidth: "250px" }}
															>
																<Item
																	htmlFor={propriedade.id}
																	sx={{
																		display: "flex",
																		justifyContent: "start",
																		p: 0,
																		m: 0,
																	}}
																>
																	<div className={styles.checkboxContainer}>
																		<input
																			type="checkbox"
																			className={styles.inputCheckbox}
																			id={propriedade.id}
																			name={propriedade.nome}
																			checked={propriedade.selected}
																			onChange={() =>
																				handlePropertyChange(propriedade)
																			}
																		/>
																		<label
																			htmlFor={propriedade.id}
																			className={styles.CheckPropriedadeLabel}
																		>
																			{propriedade.nome}
																		</label>
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}
export default ServiceCad;
