import React from "react";
import { Route, Routes } from "react-router-dom";
import PermissaoRoute from "./permissaoRoute";
//pags
import Client from "./pags/Client";
import Insumos from "./pags/Insumos";
import Losses from "./pags/Losses";
import Application from "./pags/Management";
import Occurrences from "./pags/Occurrences";
import Property from "./pags/Property";
import Services from "./pags/Services";
import Stock from "./pags/Stock";
import StockInsumoDetailed from "./pags/Stock/StockInsumoDetailed";
import Culture from "./pags/culture";
// import Activities from "./pags/Activities";
import Harvest from "./pags/Harvest";
import Home from "./pags/Home";
import LandingPage from "./pags/LandingPage";
import Portion from "./pags/Portion";
import Report from "./pags/Report";
import Requests from "./pags/Requests";
import CropSeason from "./pags/CropSeason";
import Users from "./pags/Users";

//tela de cadastro
import ClientCad from "./pags/Client/ClientCad";
import HarvestCad from "./pags/Harvest/HarvestCad";
import HarvestForecast from "./pags/Harvest/HarvestForecast";
import InsumosCad from "./pags/Insumos/InsumosCad";
import LossesCad from "./pags/Losses/LossesCad";
import ManagementCad from "./pags/Management/ManagementCad";
import OccurrencesCad from "./pags/Occurrences/OccurrencesCad";
import PartionCad from "./pags/PartionCad";
import RequestsCad from "./pags/Requests/RequestsCad";
import CropSeasonCad from "./pags/CropSeason/CropSeasonCad";
import ServiceCad from "./pags/Services/serviceCad";
import StockCad from "./pags/Stock/StockCad";
import UsersCad from "./pags/Users/UsersCad";
import CultureCad from "./pags/culture/CultureCad";
//login
import Login from "./pags/Login";
import LoginFinalizeRegistration from "./pags/Login/pages/LoginFinalizeRegistration";
import LoginOptionsNewPass from "./pags/Login/pages/LoginOptionsNewPass";
import LoginRequestNewPassword from "./pags/Login/pages/LoginRequestNewPassword";

//pdf test imports
import PdfLayout from "./components/PdfLayout/";
import PdfQrcodeLayout from "./components/PdfQrcodeLayout/";
import ResquestItemDetelhesCad from "./pags/Requests/RequestsItemDetalhesCad";
import ResquestItemDetelhes from "./pags/Requests/ResquestItemDetalhes";
import CultureEmbalagens from "./pags/culture/CultureEmbalagens";
import CultureEmbalagensCad from "./pags/culture/CultureEmbalagensCad";

//import menu
import SettingsPag from "./pags/SettingsPag";

function Rout() {
	return (
		<Routes>
			<Route path="/" element={<Login />} />

			<Route path="/Login/recuperar-senha" element={<LoginOptionsNewPass />} />
			<Route
				path="/Login/nova-senha/:id"
				element={<LoginRequestNewPassword />}
			/>
			<Route
				path="/Login/FinalizeRegistration/:id"
				element={<LoginFinalizeRegistration />}
			/>

			<Route path="/rastreabilidade" element={<LandingPage />} />

			<Route
				path="/propriedade"
				element={
					<PermissaoRoute route={"propriedade"}>
						<Property />
					</PermissaoRoute>	
				}
			/>
			<Route
				path="/culturas"
				element={
					<PermissaoRoute route={"cultura"}>
						<Culture />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/culturas/Embalagens/:id"
				element={
					<PermissaoRoute route={"cultura"}>
						<CultureEmbalagens />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/culturas/Embalagens/cadastroEmbalagem/"
				element={
					<PermissaoRoute route={"cultura"}>
						<CultureEmbalagensCad />
					</PermissaoRoute>
				}
			/>

			<Route
				path="/manejo"
				element={
					<PermissaoRoute route={"manejo"}>
						<Application />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/servicos"
				element={
					<PermissaoRoute route={"servico"}>
						<Services />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/clientes"
				element={
					<PermissaoRoute route={"cliente"}>
						<Client />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/perdas"
				element={
					<PermissaoRoute route={"perda"}>
						<Losses />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/estoque"
				element={
					<PermissaoRoute route={"estoque"}>
						<Stock />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/estoque/detalhes/:id"
				element={
					<PermissaoRoute route={"estoque"}>
						<StockInsumoDetailed />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/insumos"
				element={
					<PermissaoRoute route={"insumo"}>
						<Insumos />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/ocorrencias"
				element={
					<PermissaoRoute route={"ocorrencia"}>
						<Occurrences />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/usuarios"
				element={
					<PermissaoRoute route={"usuario"}>
						<Users />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/parcela"
				element={
					<Portion />
				}
			/>
			<Route
				path="/parcelaCad"
				element={
					<PartionCad />
				}
			/>
			<Route
				path="/pedidos"
				element={
					<PermissaoRoute route={"pedido"}>
						<Requests />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/pedidos/detalhes/:id"
				element={
					<PermissaoRoute route={"pedido"}>
						<ResquestItemDetelhes />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/colheita"
				element={
					<PermissaoRoute route={"colheita"}>
						<Harvest />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/safra"
				element={
					<PermissaoRoute route={"safra"}> 
						<CropSeason />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/home"
				element={
					<Home />
				}
			/>
			<Route
				path="/relatorios"
				element={
					<PermissaoRoute route={"relatorio"}>
						<Report />
					</PermissaoRoute>
				}
			/>

			{/* cadastros  */}
			<Route
				path="/servicos/cadastroServico"
				element={
					<PermissaoRoute route={"servico"}>
						<ServiceCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/insumos/cadastroInsumo"
				element={
					<PermissaoRoute route={"insumo"}>
						<InsumosCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/culturas/cadastroCultura"
				element={
					<PermissaoRoute route={"cultura"}>
						<CultureCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/clientes/cadastroCliente"
				element={
					<PermissaoRoute route={"cliente"}>
						<ClientCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/usuarios/cadastroUsuario"
				element={
					<PermissaoRoute  route={"usuario"}>
						<UsersCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/safra/cadastroSafra"
				element={
					<PermissaoRoute route={"safra"}>
						<CropSeasonCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/perdas/cadastroPerdas"
				element={
					<PermissaoRoute route={"perda"}>
						<LossesCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/pedidos/cadastroPedido"
				element={
					<PermissaoRoute route={"pedido"}>
						<RequestsCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/manejo/cadastroManejo"
				element={
					<PermissaoRoute route={"manejo"}>
						<ManagementCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/estoque/cadastroEstoque"
				element={
					<PermissaoRoute route={"estoque"}>
						<StockCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/estoque/cadastroEstoque/:id"
				element={
					<StockCad />
				}
			/>
			<Route
				path="/ocorrencias/cadastroOcorrencia"
				element={
					<PermissaoRoute route={"ocorrencia"}>
						<OccurrencesCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/colheita/cadastroColheita"
				element={
					<PermissaoRoute route={"colheita"}>
						<HarvestCad />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/colheita/previssaoColheita"
				element={
					<PermissaoRoute route={"colheita"}>
						<HarvestForecast />
					</PermissaoRoute>
				}
			/>

			{/* rota de visual para pdf */}
			<Route
				path="/pdf"
				element={
					<PermissaoRoute route={"relatorio"}>
						<PdfLayout />
					</PermissaoRoute>
				}
			/>
			<Route
				path="/pdfQrcode"
				element={
					<PermissaoRoute route={"relatorio"}>
						<PdfQrcodeLayout />
					</PermissaoRoute>
				}
			/>
			{/* rota de edição de cadastro */}
			<Route
				path="/pedidos/detalhes/editarCadastro"
				element={
					<PermissaoRoute route={"pedido"}>
						<ResquestItemDetelhesCad />
					</PermissaoRoute>
				}
			/>
			<Route path="/configuracao" element={<SettingsPag />} />
		</Routes>
	);
}
export default Rout;
