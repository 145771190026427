/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ButtonExportChart from "../../ButtonExportChart";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";

// import "./style.css";
import styles from "./RelatorioGeral.module.css";
// import { DataStore } from "aws-amplify";
// import { Cliente, Pedido, PropriedadeCultivo } from "../../../models";
import InputData from "../../InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
// import { CropSeasonService } from "../../../service/crop_season_service";
import { ClientService } from "../../../service/client_service";

import { FarmService } from "../../../service/farm_service";
import { ReportService } from "../../../service/report_service";
// import { BusinessService } from "../../../service/business_service";
// import { PropertyService } from "../../../service/property_service";
// import { PropertyServiceService } from "../../../service/property_service_service";
// import { ReportService } from "../../../service/report_service";
// import { PropertyInputService } from "../../../service/property_input_service";
// import { ManagementService } from "../../../service/management_service";
// import { UserService } from "../../../service/user_service";
const optionsProduto = {
	title: "Produto",
	legend: { position: "top" },
	hAxis: {
		title: "Mês/Ano",
	},
	vAxis: {
		title: "Quantidade",
	},
};
const optionsCliente = {
	title: "Cliente",
	curveType: "function",
	legend: { position: "top" },
	hAxis: {
		title: "Produtos",
	},
	vAxis: {
		title: "Quantidade",
	},
};
function RelatorioGeral(props) {
	const [allCultivos, setAllCultivos] = useState([]);
	const [disable, setDisable] = useState(true);
	const [selectedCultivo, setSelectedCultivo] = useState(-1);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");

	const month = [
		"JANEIRO",
		"FEVEREIRO",
		"MARÇO",
		"ABRIL",
		"MAIO",
		"JUNHO",
		"JULHO",
		"AGOSTO",
		"SETEMBRO",
		"OUTUBRO",
		"NOVEMBRO",
		"DEZEMBRO",
	];

	const farmService = new FarmService();
	const reportService = new ReportService();

	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};
	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const farms = await farmService.get({}, propertyId);
		setAllCultivos(getCultivos(farms));
	};

	const getCultivos = (cultivosInProperty) => {
		const cultivos = [];
		for (let i = 0; i < cultivosInProperty.length; i++) {
			const cultivoInProperty = cultivosInProperty[i];

			const name = getCultivoName(cultivoInProperty);
			cultivos.push({
				id: cultivoInProperty.id,
				item: cultivoInProperty,
				name: name,
			});
		}
		return cultivos;
	};
	const getCultivoName = (cultivoInProperty) => {
		const cultivo = cultivoInProperty.cultivo;

		return captalizeFirstLetterEacheWord(cultivo.cultura + " " + (cultivo.variedade ?? ""));
	};

	const getClientes = (clientesInProperty) => {
		const clientes = [];
		for (let i = 0; i < clientesInProperty.length; i++) {
			const cliente = clientesInProperty[i];
			clientes.push({
				id: cliente.id,
				item: cliente,
				name: cliente.nome,
			});
		}
		return clientes;
	};

	function setDataInico(e) {
		if (e !== "" && selectedDateFim !== "") setDisable(false);
		else setDisable(true);

		setPersonalizedStartDate(e);
	}
	function setDataFim(e) {
		if (selectedDateInicio !== "" && e !== "") setDisable(false);
		else setDisable(true);
		setPersonalizedFinalDate(e);
	}

	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`
		);
	};
	function setCultivo(cultivoId) {
		setSelectedCultivo(cultivoId);
	}
	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={6} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Cultura: </p>
											<select
												onChange={(e) => setCultivo(e.target.value)}
												value={selectedCultivo}
												className={styles.Select}
											>
												<option value={-1}>Todos</option>
												{allCultivos.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.name}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início:</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização:</p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<ButtonExportChart
										disabled={disable}
										href={`/pdf/?pag=Geral&index=${
											props.index
										}&culturaId=${selectedCultivo}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}

export default RelatorioGeral;
