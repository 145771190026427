import { Document, Page, View } from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { StockService } from "../../../../service/stock_service";

const PdfViewer = ({ visualizacao, id }) => {
	const stockService = new StockService();

	useEffect(() => {
		downloadFiles();
	}, []);

	const downloadFiles = async () => {
		let url = "";
		let base64 = null;

		if (visualizacao === "receituario")
			base64 = await stockService.getPrescription(id); 
		else
			base64 = await stockService.getInvoice(id);

		const mimeType = getMimeType(base64);
		const binaryString = atob(base64, "base64");

		let bytes = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++)
			bytes[i] = binaryString.charCodeAt(i);

		const blob = new Blob([bytes], { type: mimeType });
		url = URL.createObjectURL(blob); 
		window.location = url;
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	return (
		<Document>
			<Page size="A4" >
				<View>
					{/* <Image style={{width:"auto",height:"200px"}} src={files}  />	
					<Image style={{width:"100%"}} src={`blob:${files}`}  /> */}
				</View>
			</Page>
		</Document>	
	);
};

export default PdfViewer;
