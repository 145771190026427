import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { ReportService } from "../../../../service/report_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	textNormal: {
		fontSize: "12px",
	},
	body: {
		marginBottom: "30px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "3px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfPerdas = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [user, setUser] = useState(null);
	const [data, setData] = useState(null);

	const safraID = props.safraId === "undefined" ? -1 : props.safraId;
	const talhaoID = props.talhaoId === "undefined" ? -1 : props.talhaoId;
	const parcelaID = props.parcelaId === "undefined" ? -1 : props.parcelaId;
	const culturaID = props.culturaId === "undefined" ? -1 : props.culturaId;
	const logo = props.logo;

	const [loading, setLoading] = useState(true);

	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			propertyFarm: String(culturaID) !== "-1" && String(culturaID) !== "0" ? culturaID : null,
			plot: String(talhaoID) !== "-1" ? talhaoID : null,
			subdivision: String(parcelaID) !== "-1" ? parcelaID : null,
			property: propertyId,
			cropseason: String(safraID) !== "-1" ? safraID : null,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const lossData = await reportService.getLossData(reportProps);

		setData(lossData);
		setLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date =dateToFormat.split("-");
		return (
			date[2] +
			"/" +
			date[1] +
			"/" +
			date[0]
		);
	};

	const getName = () => {
		if(data.talhao === null)
			return "Todos";

		let name = `Talhão ${data.talhao.nome}`;

		if(data.subdivisao)
			name += ` - ${data.subdivisao.nome}`;

		return name;
	};
	
	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>Perdas</Text>
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>
					<View style={styles.line} fixed></View>
					<View style={styles.containerSubHeader}>
						<View>
							<Text style={styles.textBold}>
								{String(safraID) === "-1" ? "Cultura:" : "Safra:"}
							</Text>
							<Text style={styles.textNormal}>
								{data.safra ? 
									data.safra.nome :
									(data.propriedadeCultivo ? 
										data.propriedadeCultivo.nome :
										"Todas as safras"
									)
								}
							</Text>
							<Text style={styles.textBold}>Área:</Text>
							<Text style={styles.textNormal}>
								{getName()}
							</Text>
							<Text style={styles.textBold}>Período: </Text>
							<Text style={styles.textNormal}>
								{formatDate(props.startDate)} a {formatDate(props.finalDate)}
							</Text>
						</View>
						<View style={styles.containerTotal}>
							<Text style={styles.textNormal}>Perdas Totais</Text>
							<Text style={styles.textBoldBig}>{data.total}</Text>
						</View>
					</View>
					{data.dados.map((item, index) => (
						<View style={styles.body} key={index} wrap={false}>
							<View style={styles.mainTitle}>
								<Text>{item.nome}</Text>
								<Text style={{ fontSize: "10px" }}>total: {item.total}</Text>
							</View>

							<View style={styles.content}>
								<View style={styles.tableTitle}>
									<Text style={styles.larguraTexto}>safra</Text>
									<Text style={styles.larguraTexto}>data</Text>
									<Text style={styles.larguraTexto}>cultura</Text>
									<Text style={styles.larguraTexto}>lote</Text>
									<Text style={styles.larguraTexto}>tipo</Text>

									<Text style={styles.larguraTexto}>quant.</Text>
								</View>

								{item.perdas.map((i, indx) => (
									<View key={indx} style={styles.tableContent} wrap={false}>
										<Text style={styles.larguraTexto}>{i.safra}</Text>
										<Text style={styles.larguraTexto}>{i.data}</Text>
										<Text style={styles.larguraTexto}>{captalizeFirstLetterEacheWord(i.cultura)}</Text>
										<Text style={styles.larguraTexto}>{i.lote}</Text>
										<Text style={styles.larguraTexto}>{i.tipo}</Text>

										<Text style={styles.larguraTexto}>{i.quantidade}</Text>
									</View>
								))}
							</View>
						</View>
					))}
					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date + " - " + hora + " - " + (user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			: <Document></Document>
	);
};

export default PdfPerdas;
