import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText, Grid, Modal,
	Slide, TableSortLabel
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import HelpButtonInput from "../../../components/HelpButtonInput";
import ButtonExportChart from "../../ButtonExportChart";
import Chart from "../../Chart";
const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const headCells = [
	{
		id: "nome",
		label:"Nome"
	},
	{
		id: "data_plantio",
		label: "Data Plantio"
	}
];
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort } =
	props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// import "./style.css";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import InputData from "../../InputData";
import styles from "./RelatorioEstoqueInsumo.module.css";
import { CropSeasonService } from "../../../service/crop_season_service";
import { PropertyService } from "../../../service/property_service";
import { ReportService } from "../../../service/report_service";
import { PropertyInputService } from "../../../service/property_input_service";

function RelatoriosEstoqueInsumos(props) {
	// const [selectedGroup, setSelectedGroup] = useState(-1);
	const [allSafras, setAllSafras] = useState([]);
	const [allInsumos, setAllInsumos] = useState([]);
	// const [allGroups, setAllGroups] = useState([]);
	// const [changedGroup, setChangedGroup] = useState(true);
	const [valueInsumoId, setValueInsumoId] = useState("-1");
	const [allTalhoes, setAllTalhoes] = useState([]);
	const [selectedTalhao, setSelectedTalhao] = useState(-1);
	const [insumoId, setInsumoId] = useState(-1);
	const [disable, setDisable] = useState(true);
	const [personalizedStartDate, setPersonalizedStartDate] = useState(null);
	const [personalizedFinalDate, setPersonalizedFinalDate] = useState(null);
	const [openSafra, setOpenSafra] = useState(false);
	const [disableSelectSafra, setDisableSelectSafra] = useState(false);
	const [selectedSafra, setSelectedSafra] = useState(-1);
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [openSafraDialog, setOpenSafraDialog] = useState(false);
	const [idRowSafra, setIdRowSafra] = useState(null);
	const [idRowTalhao, setIdRowTalhao] = useState(null);
	const [disableInputTalhao, setDisableInputTalhao] = useState(false);
	const [selectedDateInicio, setSelectedDateInicio] = useState("");
	const [selectedDateFim, setSelectedDateFim] = useState("");
	const [dataStock, setDataStock] = useState([
		["Insumo", "Entrada", "Saida"],
		["Insumo", 0, 0]
	]);
	const [dataInsumoUsed, setDataInsumoUsed] = useState([
		["Insumo", "Quantidade utilizada"],
		["Insumo", 0]
	]);
	const keysForActiveSafra = headCells; //array de chaves
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("cuture");

	const cropSeasonService = new CropSeasonService();
	const propertyService = new PropertyService();
	const propertyInputService = new PropertyInputService();
	const reportService = new ReportService();
	
	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const plots = await propertyService.getPlots(propertyId);
		setAllTalhoes(plots);
		
		let cropSeasons = await cropSeasonService.get(propertyId, {limit: 100000});
		cropSeasons = cropSeasons.cropsSeason;
		setAllSafras(await getSafras(cropSeasons));
		setSelectedSafra(-1);

		const propertyInputs = await propertyInputService.get(propertyId, {limit: 100000});
		setAllInsumos(propertyInputs);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleDateChangeInicio = (date) => {
		setSelectedDateInicio(new Date(date).toLocaleDateString("pt-BR"));
		setDataInico(new Date(date).toLocaleDateString("pt-BR"));
	};

	const handleDateChangeFim = (date) => {
		setSelectedDateFim(new Date(date).toLocaleDateString("pt-BR"));
		setDataFim(new Date(date).toLocaleDateString("pt-BR"));
	};

	const formatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	useEffect(() => {
		if (selectedSafra !== "0" && selectedSafra !== -1)
			setDisableInputTalhao(true);
		else setDisableInputTalhao(false);
	}, [selectedSafra]);

	useEffect(() => {
		const dataInicio = formatDate(getStartDate());
		const dataFinal = formatDate(getFinalDate());

		let selectedCropSeason = null;
		let selectedInput = null;

		if (String(selectedSafra !== "0") && String(selectedSafra) !== "-1")
			selectedCropSeason = selectedSafra;

		if (String(valueInsumoId !== "0") && String(valueInsumoId) !== "-1")
			selectedInput = valueInsumoId;
		
		getStockForInsumo(selectedInput, dataInicio, dataFinal);
		getInsumoUsed(selectedInput, selectedCropSeason, dataInicio, dataFinal);
	}, [
		// changedGroup,
		personalizedStartDate,
		personalizedFinalDate,
		// selectedGroup,
		selectedTalhao,
		selectedSafra,
		valueInsumoId,
	]);

	const getStockForInsumo = async (propertyInputId, startDate, finalDate) => {
		const propertyId = localStorage.getItem("propertyId");
		const params = {
			property: propertyId,
			propertyInput: propertyInputId,
			startDate: startDate,
			finalDate: finalDate
		};

		const stockData = await reportService.getStockInputOutputGraphicData(params);
		const newData = [dataStock[0]];
		for (const key in stockData) newData.push([stockData[key].product.nome, stockData[key].input ?? 0, stockData[key].output ?? 0]);

		if(newData.length > 1){
			setDataStock(newData);
		}else {
			setDataStock([
				["Insumo", "Entrada", "Saida"],
				["Insumo", 0, 0]
			]);
		}
	};

	const getInsumoUsed = async (propertyInputId, cropSeason, startDate, finalDate) => {
		const propertyId = localStorage.getItem("propertyId");
		const params = {
			property: propertyId,
			cropSeason: cropSeason,
			propertyInputId: propertyInputId,
			startDate: startDate,
			finalDate: finalDate
		};

		const stockData = await reportService.getStockUsedGraphicData(params);
		const newData = [dataInsumoUsed[0]];
		for (const key in stockData) newData.push([stockData[key].product.nome, stockData[key].quantity ?? 0]);

		if(newData.length > 1){
			setDataInsumoUsed(newData);
		}else {
			setDataInsumoUsed([
				["Insumo", "Quantidade utilizada"],
				["Insumo", 0]
			]);
		}
	};
	
	const getSafras = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			console.log(cropSeason);
			console.log(cropSeason.dataPlantio);
			console.log(new Date(cropSeason.dataPlantio));
			console.log(new Date(cropSeason.dataPlantio).toLocaleDateString(
				"pt-BR"
			));
			const farm = cropSeason.propriedadeCultivo;
			const subDiv = cropSeason.subdivisao;
			const cultivation = farm.cultivo;
			const catSub = subDiv?.categoriaSubdivisao;
			const plot = cropSeason.talhao;
			safras.push({
				talhaoId: plot.id,
				id: cropSeason.id,
				idCultivo: farm.id,
				idTalhao: plot.id,
				idsub: subDiv !== null ? subDiv.id : -1,
				data_plantio: new Date(cropSeason.dataPlantio).toLocaleDateString(
					"pt-BR"
				),
				safra: cropSeason,
				name: `Talhão ${plot.nome}${
					subDiv ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
			});
		}
		return safras;
	};

	// function setGroup(e) {
	// 	console.log(e);
	// 	const groupId = e;
	// 	setSelectedGroup(groupId);
	// 	setChangedGroup(true);
	// }

	const setInsumo = (e) => {
		console.log(e);

		setValueInsumoId(e);
		setInsumoId(e);
		// setChangedGroup(true);
	};
	// allInsumos.findIndex((insumos) => {
	// 	if (insumos.id === valueInsumoId) return setInsumo(insumos.id);
	// });

	function setSafra(safraId) {
		setSelectedSafra(safraId);
		if (selectedDateInicio !== "" && selectedDateFim !== "") setDisable(false);
		else setDisable(true);
	}
	function setDataInico(e) {
		if (e !== "" && selectedDateFim !== "") setDisable(false);
		else setDisable(true);

		setPersonalizedStartDate(e);
	}
	function setDataFim(e) {
		if (selectedDateInicio !== "" && e !== "") setDisable(false);
		else setDisable(true);

		setPersonalizedFinalDate(e);
	}
	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedStartDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		return (
			personalizedFinalDate ??
			`${currentDate.getDate().toString().padStart(2, "0")}/${currentMonth
				.toString()
				.padStart(2, "0")}/${currentDate.getFullYear()}`
		);
	};

	function setAllSafrasCheckBox() {
		if (
			!disableSelectSafra &&
			selectedDateInicio !== "" &&
			selectedDateFim !== ""
		) {
			setSafra(0);
			setDisable(false);
		} else {
			setSafra(-1);
			setDisable(true);
		}
		setDisableSelectSafra(!disableSelectSafra);
	}
	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setOpenSafra(false); //fecha o modal
	};
	let dataAllSafras = [];

	dataAllSafras.push({
		id: 0,
		nome: "TODOS",
		data_plantio: "-",
		index: 0,
		talhaoId: -1,
	});

	allSafras.map((item, index) => {
		return dataAllSafras.push({
			id: item.id,
			nome: item.name,
			data_plantio: item.data_plantio,
			index: index,
			talhaoId: item.talhaoId,
		});
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const handleOpenSafraDialog = (e) => {
		if (e.target.id !== undefined) {
			let eSplie = e.target.id.toString().split("&");
			let idrow = eSplie[0];
			let talhaoId = eSplie[1];
			setIdRowSafra(idrow);
			setIdRowTalhao(talhaoId);
		} //seta o id do elemento clicado para abrir o dialog

		setOpenSafraDialog(true); //abre o modal
	};

	const handleCloseSafraDialog = () => {
		setOpenSafraDialog(false); //fecha o modal
	};
	const handleCloseAddSafra = () => {
		setSafra(idRowSafra);
		setSelectedTalhao(idRowTalhao);
		setOpenSafra(false); //fecha o modal
		setOpenSafraDialog(false);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
	
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);

	return (
		<Box component="main" sx={{ display: "flex", p: 0 }}>
			<Box sx={{ minWidth: "90vw", maxWidth: "95vw", p: 0 }}>
				<Box sx={{ width: "100%", p: 3, m: 0 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={{
							width: "100%",
							m: 0,
							p: 3,
							borderRadius: "8px",
							bgcolor: "white",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 2, md: 3 }}
								sx={{
									p: 0,
									display: "flex",
									flexDirection: { xs: "column-reverse", sm: "row" },
								}}
							>
								<Grid item xs={12} sm={6} md={8}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Safra: </p>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={0}
												sx={{
													p: 0,
													display: "flex",
													width: "100%",
													maxWidth: "350px",
												}}
											>
												<Grid item xs={12} sm={6} md={5}>
													<button
														onClick={handleOpenSafra}
														type="button"
														className={
															disableSelectSafra ? styles.ButtonCancelar : styles.ButtonColher
														}
														disabled={disableSelectSafra}
													>
														Selecionar Safra
													</button>
												</Grid>
												<Grid item xs={12} sm={6} md={7}>
													<select
														id="safra"
														onChange={(e) => setSafra(e.target.value)}
														value={selectedSafra}
														className={styles.SelectSafra}
														disabled
													>
														<option value={-1}>Safra</option>
														<option value={0}>Todas as Safras</option>
														{allSafras.map((item, index) => {
															return (
																<option value={item.id} key={index}>
																	{item.name}
																</option>
															);
														})}
													</select>
												</Grid>
												<Grid
													item
													xs={12}
													sx={{
														display: "flex",
														justifyContent: "start",
														textAlign: "center",
													}}
												>
													<div className={styles.checkboxContainer}>
														<input
															type="checkbox"
															className={styles.inputCheckbox}
															name="allSelect"
															id="allSafraCheckbox"
															onChange={() => setAllSafrasCheckBox()}
														/>
														<label htmlFor="allSafraCheckbox" className={styles.labelTitle}>
															<b>Selecionar todas as Safras</b>
														</label>
													</div>
												</Grid>
											</Grid>
										</Grid>
										{/* <Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Grupo de insumo:</p>
											<select
												className={styles.Select}
												value={selectedGroup}
												onChange={(e) => setGroup(e.target.value)}
											>
												<option value={-1}>Todos</option>
												{allGroups.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.nome}
														</option>
													);
												})}
											</select>
										</Grid> */}
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												maxWidth: "200px",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Insumo:</p>
											<select
												className={styles.Select}
												value={valueInsumoId}
												onChange={(e) => setInsumo(e.target.value)}
											>
												<option value={-1}>Todos</option>
												{allInsumos.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															{item.insumoEmGrupo.insumo.nome}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Talhão:</p>
											<select
												onChange={(e) => setSelectedTalhao(e.target.value)}
												value={selectedTalhao}
												className={styles.Select}
												disabled={disableInputTalhao}
											>
												<option value={-1}>Todos</option>
												{allTalhoes.map((item, index) => {
													return (
														<option value={item.id} key={index}>
															Talhao {item.nome}
														</option>
													);
												})}
											</select>
										</Grid>

										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de início:</p>
											<InputData
												placeholder="Data de início"
												onDateChange={handleDateChangeInicio}
												classN={styles.Select}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={"auto"}
											sx={{
												display: "flex",
												justifyContent: "start",
												width: "max-content",
												flexDirection: "column",
											}}
										>
											<p className={styles.textButton}>Data de finalização:</p>
											<InputData
												classN={styles.Select}
												placeholder="Data de finalização"
												onDateChange={handleDateChangeFim}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									sx={{
										display: "flex",
										justifyContent: { sx: "start", sm: "end" },
										width: "100%",
										px: 1,
									}}
								>
									<ButtonExportChart
										disabled={disable}
										href={`/pdf/?pag=EstoqueInsumos&index=${
											props.index
										}&safraId=${selectedSafra}&talhaoId=${selectedTalhao}&insumoId=${insumoId}&startDate=${getStartDate()}&finalDate=${getFinalDate()}`}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								maxHeight: "50vh",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Quantidade Utilizada</p>
								<Chart
									className={styles.Chart}
									data={dataInsumoUsed}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							sx={{
								display: "flex",
								alignItems: "stretch",
								width: "100%",
								maxHeight: "50vh",
								minHeight: "50vh",
							}}
						>
							<div className={styles.Chart}>
								<p className={styles.ChartTitle}>Entrada e Saída</p>
								<Chart
									className={styles.Chart}
									data={dataStock}
									options={props.options}
									chartType="ColumnChart"
								/>
							</div>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openSafra}
				onClose={handleCloseSafra}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Safra</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Safras"}
									text={"Clique em cima do item desejado para seleciona-lo."}
								/>
							</div>
							<Paper sx={{ width: "100%", p: 0, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer>
										<Table stickyHeader>
											<EnhancedTableHead
												className={styles.tableGenerator}
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onSelectAllClick={handleSelectAllClicks}
												onRequestSort={handleRequestSort}
												rowCount={dataAllSafras.length}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRows
														.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{keysForActiveSafra.map((column) => {
																		return (
																			<TableCell
																				key={column.id}
																				id={row.id + "&" + row.talhaoId}
																				onClick={handleOpenSafraDialog}
																			>
																				{row[column.id]}
																			</TableCell>
																		);
																	})}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRows.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={{ xs: 0, sm: 0, md: 0 }}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
									flexDirection: {
										xs: "column-reverse",
										sm: "column-reverse",
										md: "row",
									},
									pt: 2,
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseSafra}
										className={styles.ButtonCancelarModal}
									>
										Cancelar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Dialog
						open={openSafraDialog}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleCloseSafraDialog}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Deseja Selecionar esse item?
							</DialogContentText>
						</DialogContent>
						<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
							<button
								className={styles.ButtonCancelarModal}
								onClick={handleCloseSafraDialog}
							>
								Cancelar
							</button>

							<button
								className={styles.ButtonColherModal}
								onClick={handleCloseAddSafra}
							>
								Selecionar
							</button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		</Box>
	);
}

export default RelatoriosEstoqueInsumos;
