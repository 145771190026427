import React, { useEffect, useState } from "react";

import Loading from "./components/Loading";

const ProtectedRoute = ({ children, route }) => {
	let [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		initData();
	}, []);

	const hasPermissionGranted = (key) => {
		const user = JSON.parse(localStorage.getItem("user"));
		return (user[key] === true || user[key] === "true");
	};

	const initData = async () => {
		/* if (licensa.contrato_assinado === false || licensa.contrato_assinado === null) {
			alert("Assinatura do contrato pendente");
			window.location.href = "/home"; 
		} */

		if (route === "propriedade") {
			if (hasPermissionGranted("hasPropertyPermission")) { setIsAuthenticated(true); }
			else  {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			} 
		}
		if (route === "usuario") {
			if (hasPermissionGranted("hasUserPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		} 
		if (route === "cultura")	{
			if (hasPermissionGranted("hasFarmPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		} 
		if (route === "servico"){
			if (hasPermissionGranted("hasServicePermission") ) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "cliente"){
			if (hasPermissionGranted("hasCustomerPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "insumo"){
			if (hasPermissionGranted("hasInputPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "safra"){
			if (hasPermissionGranted("hasCropSeasonPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "manejo"){
			if (hasPermissionGranted("hasManagementPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "pedido"){
			if (hasPermissionGranted("hasOrderPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "perda"){
			if (hasPermissionGranted("hasLossPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "ocorrencia"){
			if (hasPermissionGranted("hasIncidentPermission")){ setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "colheita"){
			if (hasPermissionGranted("hasHarvestPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "relatorio"){
			if (hasPermissionGranted("hasReportPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
		if (route === "estoque"){
			if (hasPermissionGranted("hasStockPermission")) { setIsAuthenticated(true); }
			else {
				alert("Usuario Sem Permissâo");
				window.location.href = "/home"; 
			}
		}
	};
	return isAuthenticated ? (
		<div>{children}</div>
	) : (
		<div>
			<Loading /> {/* <p>loading...</p> criar um load */}
		</div>
	);
};

export default ProtectedRoute;
