import { CultivationRepository } from "../repository/cultivation_repository";

export class CultivationService {

	constructor() {
		this.cultivationRepository = new CultivationRepository();
	}

	async get(params) {
		const cultivationData = await this.cultivationRepository.get(params);
		return cultivationData.cultivations;
	}

	async getByProperty(propertyId) {
		const cultivationData = await this.cultivationRepository.getByProperty(propertyId);
		return cultivationData.cultivations;
	}

	async getById(id) {
		const cultivation = await this.cultivationRepository.getById(id);
		return cultivation;
	}

	async save(cultivation) {
		const savedCultivation = await this.cultivationRepository.save(cultivation);
		return savedCultivation;
	}

	async update(cultivation) {
		const savedCultivation = await this.cultivationRepository.update(cultivation);
		return savedCultivation;
	}
}