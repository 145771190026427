/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import { CropSeasonService } from "../../../../service/crop_season_service";
import { BusinessService } from "../../../../service/business_service";
import { PropertyService } from "../../../../service/property_service";
import { FarmService } from "../../../../service/farm_service";
import { ReportService } from "../../../../service/report_service";
import { CultivationService } from "../../../../service/cultivation_service";
import { UserService } from "../../../../service/user_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "50px",
		paddingRight: "50px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "10px",
		fontFamily: "Roboto bold",
	},
	textBoldBig: {
		fontSize: "12px",
	},
	textNormal: {
		fontSize: "12px",
	},
	body: {
		marginBottom: "15px",
		marginTop: "15px",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		marginBottom: "20px",
		fontFamily: "Roboto bold",
	},
	content: {
		fontSize: "8px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
	},
	larguraTexto: {
		width: "16.60%",
		paddingHorizontal: "1px",
		marginBottom: "4px",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
		fontFamily: "Roboto bold",
	},
	containerNumberTotal: {
		width: "85%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfBalancoDeProducao = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");

	const [user, setUser] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	const culturaID = props.culturaId;
	const talhaoID = props.talhaoId;
	const parcelaID = props.parcelaId;
	const safraID = props.safraId;
	const logo = props.logo;

	const reportService = new ReportService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = JSON.parse(localStorage.getItem("user"));
		setUser(user);

		const reportProps = {
			propertyFarm: String(culturaID) !== "-1" && String(culturaID) !== "0" ? culturaID : null,
			plot: String(talhaoID) !== "-1" ? talhaoID : null,
			subdivision: String(parcelaID) !== "-1" ? parcelaID : null,
			propertyId: propertyId,
			cropSeason: String(safraID) !== "-1" ? safraID : null,
			startDate: new Date(props.startDate),
			finalDate: new Date(props.finalDate)
		};
		const productionBalanceData = await reportService.getProductionBalanceData(reportProps);
		
		setData(productionBalanceData);
		setLoading(false);
	};

	const getName = () => {
		if(data.talhao === null)
			return "Todos";

		let name = `Talhão ${data.talhao.nome}`;

		if(data.subdivisao)
			name += ` - ${data.subdivisao.name}`;

		return name;
	};

	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Relatório de</Text>
							<Text style={styles.textTitleDocument}>Balanço de Produção</Text>
							<Text style={styles.textBoldFazenda}>
								{data.empresa?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{data.propriedade?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.line} fixed></View>

					<View style={styles.containerSubHeader}>
						{safraID === "-1" || safraID === "0" ? (
							<View>
								<Text style={styles.textBold}>Cultura:</Text>
								<Text style={styles.textNormal}>{data.propriedadeCultivo?.nome ?? "Todos"}</Text>
								<Text style={styles.textBold}>Area:</Text>
								<Text style={styles.textNormal}>{getName()}</Text>
								<Text style={styles.textBold}>Período: </Text>
								<Text style={styles.textNormal}>
									{new Date(props.startDate).toLocaleDateString("pt-BR")} a{" "}
									{new Date(props.finalDate).toLocaleDateString("pt-BR")}
								</Text>
							</View>
						) : (
							<View>
								<Text style={styles.textBold}>Safra:</Text>
								<Text style={styles.textNormal}>{data.safra.nome}</Text>
								{/* <Text style={styles.textBold}>Area:</Text> */}
								<Text style={styles.textNormal}>{data.safra.area}</Text>
								<Text style={styles.textBold}>Período: </Text>
								<Text style={styles.textNormal}>
									{new Date(props.startDate).toLocaleDateString("pt-BR")} a{" "}
									{new Date(props.finalDate).toLocaleDateString("pt-BR")}
								</Text>
							</View>
						)}

						<View style={styles.containerTotal}>
							<Text style={styles.textBold}>Total Produzido</Text>
							<Text style={styles.textBoldBig}>{data.produzido} {data.unidadeMedida}</Text>
							<Text style={styles.textBold}>Total vendido</Text>
							<Text style={styles.textBoldBig}>{data.vendido} {data.unidadeMedida}</Text>
							<Text style={styles.textBold}>Total perdido</Text>
							<Text style={styles.textBoldBig}>{data.perdido} {data.unidadeMedida}</Text>
							<Text style={styles.textBold}>Total estocado</Text>
							<Text style={styles.textBoldBig}>{data.estocado} {data.unidadeMedida}</Text>
						</View>
					</View>

					{data.dados.map((item, index) => (
						<View style={styles.body} key={index} wrap={false}>
							<View style={styles.mainTitle}>
								<Text>{item.nome}</Text>
							</View>

							<View style={styles.content}>
								<View style={styles.tableTitle}>
									<Text style={(styles.larguraTexto, { width: "150px" })}>
										safra
									</Text>
									<Text style={styles.larguraTexto} wrap={false}>
										Período de colheita
									</Text>
									<Text style={styles.larguraTexto}>produtividade</Text>
									<Text style={styles.larguraTexto}>produzido</Text>
									<Text style={styles.larguraTexto}>vendido</Text>
									<Text style={styles.larguraTexto}>perdido</Text>
									<Text style={styles.larguraTexto}>estocado</Text>
								</View>

								{item.cropSeasons.map((cropseason, indx) => (
									<View key={indx} style={styles.tableContent} wrap={false}>
										<Text style={(styles.larguraTexto, { width: "150px" })}>
											{cropseason.nome}
										</Text>
										<Text style={styles.larguraTexto}>
											{cropseason.periodoColheita}
										</Text>
										<Text style={styles.larguraTexto}>
											{cropseason.produtividade + cropseason.unidadeMedida} / ha
										</Text>
										<Text style={styles.larguraTexto}>
											{cropseason.produzido + cropseason.unidadeMedida}
										</Text>
										<Text style={styles.larguraTexto}>{cropseason.vendido + cropseason.unidadeMedida}</Text>
										<Text style={styles.larguraTexto}>{cropseason.perdido + cropseason.unidadeMedida}</Text>
										<Text style={styles.larguraTexto}>
											{cropseason.estoque + cropseason.unidadeMedida}
										</Text>
									</View>
								))}

								<View style={styles.containerTotalTalhao} wrap={false}>
									<View style={styles.containerTotalTalhaoGrey}>
										<Text>Total:</Text>
										<View style={styles.containerNumberTotal}>
											<Text>{item.produzido} {item.unidadeMedida}</Text>
											<Text>{item.vendido} {item.unidadeMedida}</Text>
											<Text>{item.perdido} {item.unidadeMedida}</Text>
											<Text>{item.estocado} {item.unidadeMedida}</Text>
										</View>
									</View>
								</View>
							</View>
						</View>
					))}

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document> 
			: <Document></Document>
	);
};
export default PdfBalancoDeProducao;
