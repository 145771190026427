//front
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { visuallyHidden } from "@mui/utils";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";

//styles css & module.css
import "./DropzoneAreaStyle.css";
import styles from "./OccurrencesCad.module.css";

//material ul imports
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, TableSortLabel, ThemeProvider, Typography, createTheme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import { Modal } from "@mui/material";
import InputData from "../../../components/InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { CropSeasonService } from "../../../service/crop_season_service";
import { MeasurementUnitService } from "../../../service/measurement_unit_service";
import { OccurrencesService } from "../../../service/occurrences_service";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
const columnForActive = [
	{
		id: "nome",
		label:"Nome"
	},
	{
		id: "data_plantio",
		label:"Data Plantio"
	},
]; 
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,columns } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead  className={styles.tableGenerator}>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function OccurrencesCad() {
	const [openSafra, setOpenSafra] = useState(false);
	const [itemsSafra, setItemsSafra] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("-1");
	const [changedCategory, setChangedCategory] = useState(false);
	const [allTypes, setAllTypes] = useState([]);
	const [id, setId] = useState(null);
	const [searchParams] = useSearchParams();
	const [allSafras, setAllSafras] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [selectedType, setSelectedType] = useState("-1");
	const [initialFiles, setInitialFiles] = useState([]);
	const [files, setFiles] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const helpIdFinter = searchParams.get("id");
	const [errorMessageItemsSafra, setErrorMessageItemsSafra] = useState(false);
	const [errorMessageDateCad, setErrorMessageDateCad] = useState(false);
	const [errorMessageSelectedType, setErrorMessageSelectedType] =
		useState(false);
	const [errorMessageSelectedCategory, setErrorMessageSelectedCategory] =
		useState(false);
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginaçãoconst keysForActive = Object.keys(columnForActive); //array de chaves
	const keysForActive = columnForActive;
	// const columnsForActive = Object.values(columnForActive); //array de valores
	const [selectedDate, setSelectedDate] = useState("");
	const [inputQuantity, setInputQuantity] = React.useState("");
	const [allMeasurementUnits, setAllMeasurementUnits] = useState([]);
	const [inputMedida, setInputMedida] = useState("-1");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("nome");
	const [startCropSeasons, setStartCropSeasons] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const cropSeasonService = new CropSeasonService();
	const measurementUnitService = new MeasurementUnitService();
	const occurrencesService = new OccurrencesService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date).toLocaleDateString("pt-BR"));
	};
	
	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	useEffect(() => {
		fetchTypesByCategory();
	}, [changedCategory]);

	useEffect(() => {
		initData();
	}, []);

	const fetchTypesByCategory = async () => {
		if (selectedCategory !== "-1" && changedCategory) {
			const categoryId = allCategories[selectedCategory].id;
			const params = {
				filterValue: encodeURI(JSON.stringify({categoriaOcorrenciaId: categoryId}))
			};
			const types =  await occurrencesService.getAllTypes(params);
			setAllTypes(types);
		} else if (changedCategory) {
			const types =  await occurrencesService.getAllTypes({});
			setAllTypes(types);
		}
		setChangedCategory(false);
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		let cropSeasons = await cropSeasonService.get(propertyId, {});
		cropSeasons = cropSeasons.cropsSeason;
		const safras = await getSafrasWithDetails(cropSeasons);
		setAllSafras(safras);
		const categories = await occurrencesService.getAllCategories();
		setAllCategories(categories);
		const types =  await occurrencesService.getAllTypes({});
		setAllTypes(types);
		const measurementUnits = await measurementUnitService.get();
		const filteredMeasurementUnit = measurementUnits.filter((measurementUnit) => measurementUnit.ocorrencia === true);
		setAllMeasurementUnits(filteredMeasurementUnit);

		const ocorrenciaId = searchParams.get("id");
		if (ocorrenciaId !== null) {
			setId(ocorrenciaId);
			const ocorrencia = await occurrencesService.getById(ocorrenciaId);
			setInputQuantity(ocorrencia.quantidade);
			setInputMedida(ocorrencia.unidadeMedidaId ?? "-1");
			setSelectedDate(formatDate(new Date(ocorrencia.data)));
			setDescricao(ocorrencia.descricao);
			const tipoOcorrencia = ocorrencia.tipoOcorrencia;
			const categoryIndex = categories.findIndex(
				(category) => category.id === tipoOcorrencia.categoriaOcorrenciaId
			);
			setSelectedCategory(categoryIndex);
			const typeIndex = types.findIndex((type) => type.id === tipoOcorrencia.id);
			setSelectedType(typeIndex);
			downloadFiles(ocorrenciaId);
			const ocorrenciaSafras = ocorrencia.ocorrenciaSafras;
			initSafras(safras, ocorrenciaSafras);
			setStartCropSeasons(ocorrencia.ocorrenciaSafras);
		}
		setIsLoading(false);
	};

	const initSafras = (safras, ocurrenceSafras) => {
		const newItemsSafra = [];
		for (let i = 0; i < ocurrenceSafras.length; i++) {
			const ocurrenceSafra = ocurrenceSafras[i];
			const safraIndex = safras.findIndex(
				(safra) => ocurrenceSafra.safraId === safra.id
			);
			newItemsSafra.push(safras[safraIndex]);
		}
		setItemsSafra(newItemsSafra);
	};

	const downloadFiles = async (ocorrenciaId) => {
		const initiFiles = [];
		const filesInStorage = await occurrencesService.getImages(ocorrenciaId);
		for (let i = 0; i < filesInStorage.length; i++) {
			const fileInStorage = filesInStorage[i];
			const blob = await fetch(fileInStorage).then(r => r.blob());
			initiFiles.push(
				new File([blob], `${i}.jpg`, {
					type: "image/jpeg",
				})
			);
		}
		setInitialFiles(initiFiles);
	};

	const getSafrasWithDetails = async (safrasInTalhao) => {
		const safras = [];
		for (let j = 0; j < safrasInTalhao.length; j++) {
			const cropSeason = safrasInTalhao[j];
			const farm = cropSeason.propriedadeCultivo;
			const subDiv = cropSeason.subdivisao;
			const cultivation = farm.cultivo;
			const catSub = await subDiv?.categoriaSubdivisao;
			safras.push({
				id: cropSeason.id,
				safra: cropSeason,
				nome: `Talhão ${cropSeason.talhao.nome}${
					subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
			});
		}
		return safras;
	};

	const setCategory = (value) => {
		setSelectedCategory(value);
		setChangedCategory(true);
	};

	const handleAddSafra = () => {
		let newItemsSafra = [...itemsSafra];
		for (let j = 0; j < selected.length; j++) {
			let safra = "";
			for (let i = 0; i < allSafras.length; i++) {
				if (allSafras[i].id === selected[j]) {
					safra = allSafras[i];
					newItemsSafra = safra;
					itemsSafra.push(newItemsSafra);
					break;
				}
			}
		}
		return;
	};

	const handleRemoveSafra = (index) => {
		const newItems = itemsSafra.filter((item, itemIndex) => itemIndex !== index);

		setItemsSafra(newItems);
	};

	const validationSave = async () => {
		if (
			itemsSafra.length > 0 &&
			selectedDate.length === 10 &&
			selectedType !== "-1" &&
			selectedCategory !== "-1"
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (itemsSafra.length === 0) {
				message += "Safra, ";
				setErrorMessageItemsSafra(true);
			} else {
				setErrorMessageItemsSafra(false);
			}
			if (selectedCategory === "-1") {
				message += "Categoria, ";
				setErrorMessageSelectedCategory(true);
			} else {
				setErrorMessageSelectedCategory(false);
			}
			if (selectedType === "-1") {
				message += "Tipo, ";
				setErrorMessageSelectedType(true);
			} else {
				setErrorMessageSelectedType(false);
			}
			if (selectedDate.length !== 10) {
				message += "Data, ";
				setErrorMessageDateCad(true);
			} else {
				setErrorMessageDateCad(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const date= selectedDate.split("/");
		const newDate =
			date.length > 1
				? `${date[2]}-${date[1]}-${date[0]}`
				: date;
		const occurrenceToSave = new FormData();
		if (!id) {
			for (let i = 0; i < itemsSafra.length; i++) {
				const safra = itemsSafra[i];
				occurrenceToSave.append("includeCropSeasons[]", safra.id);
			} 

			for (let i = 0; i < files.length; i++) {
				const image = files[i];
				occurrenceToSave.append("images", image);
			}
			occurrenceToSave.append("description", document.getElementById("descricao").value);
			occurrenceToSave.append("incidentTypeId", allTypes[selectedType].id);
			occurrenceToSave.append("amount", Number(inputQuantity));
			occurrenceToSave.append("measureUnitId", inputMedida);
			occurrenceToSave.append("date", newDate);

			await occurrencesService.save(occurrenceToSave);
		} else {
			for (let i = 0; i < files.length; i++) {
				const image = files[i];
				occurrenceToSave.append("images", image);
			}
			occurrenceToSave.append("id", id);
			occurrenceToSave.append("description", document.getElementById("descricao").value);
			occurrenceToSave.append("incidentTypeId", allTypes[selectedType].id);
			occurrenceToSave.append("amount", Number(inputQuantity));
			occurrenceToSave.append("measureUnitId", inputMedida);
			occurrenceToSave.append("date", newDate);

			for (let i = 0; i < startCropSeasons.length; i++) {
				const safra = startCropSeasons[i];
				const safraIndex = itemsSafra.findIndex((itemSafra) => itemSafra.id === safra.safraId);
				if(safraIndex === -1)
					occurrenceToSave.append("removeCropSeasons[]", safra.safraId);
			}
			for (let i = 0; i < itemsSafra.length; i++) {
				const itemSafra = itemsSafra[i];
				const safraId = itemSafra.id;
				const safraIndex = startCropSeasons.findIndex((startCropSeasons) => startCropSeasons.safraId === safraId);
				if (safraIndex === -1) 
					occurrenceToSave.append("includeCropSeasons[]", safraId);
			}
			await occurrencesService.update(occurrenceToSave);
		}
	};

	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};
	const handleCloseAddSafra = () => {
		handleAddSafra(); //adiciona o item no array
		setOpenSafra(false); //fecha o modal
	};
	const handleCloseSafra = () => {
		setOpenSafra(false); //fecha o modal
	};

	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	const dataAllSafras = allSafras.map((item, index) => {
		return {
			id: item.id,
			nome: item.nome,
			data_plantio:new Date(item.safra.dataPlantio).toLocaleDateString("pt-BR"),
			index: index,
		};
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_plantio") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);
	const [descricao, setDescricao] =useState("");
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu
					open={["launch", "occurrences"]}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											helpIdFinter === null ? "Registro de Ocorrência" : "Editar Ocorrência"
										}
										href="/ocorrencias"
										dataCriacao={true}
										dataAlteracao={true}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
										help={helpIdFinter === null ? "occurrencesCad" : "occurrencesEdit"}
									>
										{/* inputs da pagina de resgitro */}
										<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<a onClick={handleOpenSafra} className={styles.addBtn}>
															Adicionar safras *
															<AddIcon style={styleAdd} />
														</a>
													</div>
													<p
														className={
															errorMessageItemsSafra ? styles.errText : styles.noErrText
														}
													>
														Adicione no minimo 1 Safra *
													</p>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={{ minHeight: "45px", minWidth: "50vw" }}>
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 1, sm: 2, md: 3 }}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															width: "100%",
														}}
													>
														{itemsSafra.map((item, index) => (
															<Grid item key={index}>
																<Item sx={stylesItem}>
																	<div className={styles.itemNameCuture}>
																		<button
																			className={styles.itemButtonCuture}
																			type="button"
																			id={item.id}
																			onClick={() => handleRemoveSafra(index)}
																		>
																			X
																		</button>
																		{item.nome + " - " + new Date(item.safra.dataPlantio).toLocaleDateString("pt-BR")}
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>

											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedCategory === "-1"?"hidden":"visible"),transition:"all 0.5s ease"}} >
																Selecionar categoria de ocorrência *	
													</Typography>
		
													<div
														className={
															errorMessageSelectedCategory
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectedCategory ? styles.errText : styles.noErrText
															}
														>
														*
														</p>
														<select
															id="Categoria"
															value={selectedCategory}
															onChange={(e) => setCategory(e.target.value)}
															className={styles.inputOccurrencesCad}
														>
															<option value={"-1"}>
																{selectedCategory !== "-1"
																	? "--------------- *"
																	: "Categoria de ocorrência *"}
															</option>
															{allCategories.map((item, index) => {
																return (
																	<option value={index} key={index}>
																		{item.nome}
																	</option>
																);
															})}
														</select>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedType === "-1"?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Tipo de ocorrência *
													</Typography>
													<div
														className={
															errorMessageSelectedType ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectedType ? styles.errText : styles.noErrText
															}
														>
														*
														</p>
														<select
															value={selectedType}
															onChange={(event) => setSelectedType(event.target.value)}
															placeholder="Tipo de ocorrência"
															onKeyPress={(e) => {
																e.key === "Enter" && e.preventDefault();
															}}
															className={styles.inputOccurrencesCad}
														>
															<option value={"-1"}>
																{selectedType !== "-1"
																	? "--------------- *"
																	: "Selecionar tipo de ocorrência *"}
															</option>
															{allTypes.map((item, index) => {
																return (
																	<option value={index} key={index}>
																		{item.nome}
																	</option>
																);
															})}
														</select>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedDate .length<10?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Data *
													</Typography>
													<div
														className={
															errorMessageDateCad ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={errorMessageDateCad ? styles.errText : styles.noErrText}
														>
														*
														</p>
														<InputData
															placeholder="Data *"
															onDateChange={handleDateChange}
															value={selectedDate} 
															classN={styles.inputOccurrencesCad}/>
													</div>
												</Box>
											</Grid>

											<Grid item xs={12} sm={12} md={3}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(inputQuantity === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Quantidade *
													</Typography>
													<input 
														type="number" 
														placeholder="Quantidade *"
														value={inputQuantity}
														onChange={(event) =>
															setInputQuantity(event.target.value)
														}
														className={styles.inputOccurrencesCad}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={3}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(inputMedida === "-1"?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Unidade de medida *
													</Typography>
													<select
														id="medida"
														onChange={(event) =>
															setInputMedida(event.target.value)
														}
														value={inputMedida}
														className={styles.inputOccurrencesCad}
													>
														<option value={"-1"}>
															{inputMedida !== "-1"
																? "--------------- *"
																: "Selecionar unidade de medida *"}
														</option>
														{allMeasurementUnits.map(
															(itemSigla, index) => {
																return (
																	<option value={itemSigla.id} key={index}>
																		{itemSigla.sigla}
																	</option>
																);
															}
														)}
													</select>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(descricao === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Descrição
													</Typography>
													<input type="text" placeholder="Descrição" id="descricao" value={descricao} onChange={e => setDescricao(e.target.value)} className={styles.inputOccurrencesCad} />
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												{initialFiles.length === 0 && (
													<DropzoneArea
														Icon={CameraAltIcon}
														acceptedFiles={[
															"image/jpeg",
															"image/png",
															"image/bmp",
															"application/pdf",
														]}
														onChange={(addedFiles) => {
															if (addedFiles.length <= 2) setFiles(addedFiles);
														}}
														showPreviews={true}
														showPreviewsInDropzone={false}
														filesLimit={2}
														maxFileSize={5000000}
														previewText=" "
														dropzoneText="Faça upload de até duas imagens da ocorrência JPG e PNG, somente"
														getFileLimitExceedMessage={(filesLimit) =>
															`Quantidade excedida: ${filesLimit} `
														}
														getFileAddedMessage={(fileName) =>
															`Imagen adicionada: ${fileName} `
														}
														getFileRemovedMessage={(fileName) =>
															`Imagen removida: ${fileName} `
														}
														showAlerts={["success", "info"]}
													/>
												)}
												{initialFiles.length > 0 && (
													<DropzoneArea
														Icon={CameraAltIcon}
														onChange={(addedFiles) => {
															if (addedFiles.length <= 3) setFiles(addedFiles);
														}}
														acceptedFiles={[
															"image/jpeg",
															"image/png",
															"image/bmp",
															"application/pdf",
														]}
														showPreviews={true}
														showPreviewsInDropzone={false}
														filesLimit={2}
														maxFileSize={5000000}
														previewText=" "
														dropzoneText="Faça upload de até duas imagens da ocorrência JPG e PNG, somente"
														getFileLimitExceedMessage={(filesLimit) =>
															`Quantidade excedida: ${filesLimit} `
														}
														getFileAddedMessage={(fileName) =>
															`Imagen adicionada: ${fileName} `
														}
														getFileRemovedMessage={(fileName) =>
															`Imagen removida: ${fileName} `
														}
														showAlerts={["success", "info"]}
														initialFiles={initialFiles}
													/>
												)}
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={openSafra}
					onClose={handleCloseSafra}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Safra
								</p>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Paper sx={{ width: "100%", p: 0, m: 0 }}>
										<ThemeProvider theme={theme}>
											<TableContainer sx={{ maxHeight: "45vh" }}>
												<Table stickyHeader>
													<EnhancedTableHead
														numSelected={selected.length}
														order={order}
														orderBy={orderBy}
														onSelectAllClick={handleSelectAllClicks}
														onRequestSort={handleRequestSort}
														rowCount={dataAllSafras.length}
														columns={columnForActive}
													/>
													<TableBody>
														{
															//cria uma linha por objeto
															visibleRows
																.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
																.map((row, index) => {
																	const isItemSelected = isSelected(row.id);
																	return (
																		<TableRow
																			hover
																			onClick={(event) => handleClick(event, row.id)}
																			role="checkbox"
																			aria-checked={isItemSelected}
																			tabIndex={-1}
																			key={index}
																		>
																			<TableCell padding="checkbox">
																				<Checkbox color="success" checked={isItemSelected} />
																			</TableCell>
																			{keysForActive.map((column) => {
																				return <TableCell key={column.id}>{row[column.id]}</TableCell>;
																			})}
																		</TableRow>
																	);
																})
														}
													</TableBody>
												</Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
												component="div"
												colSpan={7}
												count={visibleRows.length}
												rowsPerPage={rowsPerPage}
												page={page}
												labelRowsPerPage="Linhas por pagina"
												SelectProps={{
													inputProps: {
														"aria-label": "rows per page",
													},
													native: false,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActions}
											/>
										</ThemeProvider>
									</Paper>
								</Item>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseSafra}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleCloseAddSafra}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}
export default OccurrencesCad;
