import { Box, Grid, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
// import logoHorizontal from "../../../../Assets/images/logoMenu.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import * as bootstrap from "bootstrap";

import Feature from "ol/Feature";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import View from "ol/View";
import { FullScreen, defaults as defaultControls } from "ol/control.js";
import { Point } from "ol/geom";
import { useGeographic } from "ol/proj.js";
import { XYZ } from "ol/source";
import TileLayer from "ol/layer/Tile";
import { Fill, Icon, Style, Text } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import propriedadeMarkerMap from "../../../../Assets/icons/propriedadeMarkerMap.png";
// import * as queries from "../../../../graphql/queries";
import { captalizeFirstLetterEacheWord } from "../../../../utils/utils";
import styles from "../../LandingPage.module.css";
import "./olMap.css";

const fontDescriStyle = {
	fontWeight: "500",
	fontSize: "20px",
};

useGeographic();

function TraceabilityProdut(props) {
	const [firstTime, setFirstTime] = useState(true);
	const [enderecocliente, setEnderecocliente] = useState("");
	const [enderecoPropri, setEnderecoPropri] = useState("");
	const itemPedido = props.itemPedido;
	const lote = props.lote;

	const embalagem = {
		nome: itemPedido?.embalagem?.nome,
		qte: itemPedido.embalagem?.quantidade,
		sigla: itemPedido?.embalagem?.unidadeMedida?.sigla,	
	};
	const cultivo =  lote.propriedadeCultivo?.cultivo;
	const pedido =  itemPedido.pedido;
	const propriedade =  lote.propriedadeCultivo?.propriedade;
	const cliente = pedido?.cliente;
	const coordenadas = propriedade?.propriedadeCoordenada;

	useEffect(()=> {
		init();
	},[]);
	
	useEffect(()=> {
		init();
	},[cliente,propriedade,lote]);

	async function init() { 
		setEnderecoPropri(propriedade?.endereco);
		setEnderecocliente(cliente?.enderecoCliente);
	}
	
	function formatCpfCnpj(cpfCnpj) {
		let cpfCnpjNumbers = cpfCnpj !== null && cpfCnpj !== undefined ? cpfCnpj.split("") : [];
		if (cpfCnpjNumbers.length === 11) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				cpfCnpjNumbers[2] +
				"." +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				cpfCnpjNumbers[5] +
				"." +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				cpfCnpjNumbers[8] +
				"-" +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10]
			);
		} else if (cpfCnpjNumbers.length === 14) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				"." +
				cpfCnpjNumbers[2] +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				"." +
				cpfCnpjNumbers[5] +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				"/" +
				cpfCnpjNumbers[8] +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10] +
				cpfCnpjNumbers[11] +
				"-" +
				cpfCnpjNumbers[12] +
				cpfCnpjNumbers[13]
			);
		} else {
			return "CPF/CNPJ Não informado";
		}
	}	
	const getCenterOfPolygon = (coordenadas) => {
		let lat = 0;
		let lon = 0;
		for (var i = 0; i < coordenadas.length; i++) {
			const vertex = coordenadas[i];
			lat += Number(vertex.latitude);
			lon += Number(vertex.longitude);
		}
		lat = lat / coordenadas.length;
		lon = lon / coordenadas.length;

		return [lon,lat];
	};
	const midPoint = getCenterOfPolygon(coordenadas);
	
	useEffect(() => {
		if (firstTime && lote?.dataColheita !== undefined && lote?.codigo !== undefined && propriedade !== undefined) {
			mapsInit();
			setFirstTime(false);
		}
	}, [firstTime,lote,propriedade]);

	const mapsInit = async () => {
		
		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: `<br/><i class="fa-regular fa-calendar" style="color:#00A69A;"></i>  Data de colheita: ${new Date(lote?.dataColheita).toLocaleDateString("pt-BR")}<br/>
						<i class="fa-solid fa-house-chimney" style="color:#00A69A;"></i> Fazenda: ${propriedade?.nome}<br/>
						<i class="fa-solid fa-leaf" style="color:#00A69A;"></i> Lote: ${lote?.codigo}<br/>
						<i class="fa-regular fa-address-card" style="color:#00A69A;"></i> CNPJ/CPF: ${formatCpfCnpj(propriedade?.cpf_cnpj)}
						`,

		});
		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 46],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.1,
				src:propriedadeMarkerMap,
			}),
			text: new Text({
				text: name,
				fill: new Fill({
					color: "black",
				}),
				backgroundFill: new Fill({
					color: "#49A09A",
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const source = new VectorSource({
			wrapX: false,
			features: [iconFeature],
			attributions: ["© 2022 SISAGRI, AWS"],
			attributionsCollapsible: false,
		});

		const vector = new VectorLayer({
			source: source,
		});

		const map =	new Map({
			controls: defaultControls().extend([new FullScreen()]),
			target: "maps",
			layers: [
				new TileLayer({
					source: new XYZ({
						url: `https://maps.geo.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/tiles/{z}/{x}/{y}?key=${process.env.REACT_APP_MAP_API_KEY}`,
						wrapX: false,
					}),
				}),
				vector
			],
			view: new View({
				center: midPoint,
				zoom: 16,
			}),
			overlay: new Overlay({
				position: midPoint,
				positioning: "top",
				element: document.getElementById("marker"),
				stopEvent: false,
			}),
		});

		const element = document.getElementById("popup");

		const popup = new Overlay({
			element: element,
			positioning: "bottom-center",
			stopEvent: false,
			
		});
		map.addOverlay(popup);

		let popover;
		function disposePopover() {
			if (popover) {
				popover.dispose();
				popover = undefined;
			}
		}
		
		map.on("click", function (evt) {
			const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
				return feature;
			});
			disposePopover();
			if (!feature) 
				return;
  
			popup.setPosition(evt.coordinate);
			popover = new bootstrap.Popover(element, {
				placement: "top",
				html: true,
				content: feature.get("name")
			});
			popover.show();
		});
		
		map.on("movestart", disposePopover);
	};

	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: "100%",
				minHeight: "100vh",
				m: 0,
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				flexDirection={"row"}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					p: 0,
					m: 0,
				}}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							alignItems: "center",
							py: 5,
							px: { xs: 5, sm: 10 },
							height: "100%",
							minHeight: "100vh",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								variant={"h3"}
								className={styles.titleTraceabilityProdut}
								sx={{
									fontWeight: "700",
									color: "#009199",
								}}
							>
								Rastreabilidade do Produto
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "start",
								flexDirection: "column",
								mb: "25px",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "700",
								}}
							>
								{captalizeFirstLetterEacheWord(cultivo?.cultura + " " + (cultivo?.variedade ?? ""))}
							</Typography>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "500",
								}}
							>
								{embalagem?.nome + " (" + embalagem?.qte+" "+ embalagem?.sigla + ") "}
							</Typography>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "500",
								}}
							>
								Data da colheita: {new Date(lote?.dataColheita).toLocaleDateString("pt-BR")}
							</Typography>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "700",
								}}
							>
								Código de Rastreabilidade:
							</Typography>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "500",
								}}
							>
								{props.id}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								mb: "25px",
							}}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "500",
									display: "flex",
									justifyContent: "start",
									alignItems: "center",
								}}
							>
								<LocationOnIcon sx={{ color: "red" }} />
								<b>Origem:</b>
								{propriedade?.nome}
							</Typography>
							<Typography variant="body1" sx={fontDescriStyle}>
								CPF/CNPJ: {formatCpfCnpj(propriedade?.cpf_cnpj) }
							</Typography>
							<Typography variant="body1" sx={fontDescriStyle}>
								{enderecoPropri?.endereco + " - " + enderecoPropri?.cidade}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								mb: "25px",
							}}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "500",
									display: "flex",
									justifyContent: "start",
									alignItems: "center",
								}}
							>
								<LocationOnIcon sx={{ color: "red" }} />
								<b>Destino:</b>
								{cliente?.nome}
							</Typography>
							<Typography variant="body1" sx={fontDescriStyle}>
								CPF/CNPJ: {formatCpfCnpj(cliente?.cpf_cnpj)}
							</Typography>
							<Typography variant="body1" sx={fontDescriStyle}>
								{enderecocliente?.endereco + " - " + enderecocliente?.cidade}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							textAlign: "center",
							height: "100%",
							minHeight: "100vh",
							// border: "1px solid black",
						}}
					>
						<Grid item xs={12} sm={12} md={12}>
							
							<div>
								<div id="maps" className={styles.map} ></div>
								<div id="popup" title={captalizeFirstLetterEacheWord(cultivo?.cultura + " " + (cultivo?.variedade ?? ""))} >
								
									{/* <div dangerouslySetInnerHTML={{ __html: popupText(midPoint) }} /> */}
								</div>
							</div>
				
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
export default memo(TraceabilityProdut);
