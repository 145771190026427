/* eslint-disable react/prop-types */
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Slide,
} from "@mui/material";
import * as React from "react";
import * as I from "../IconsImport";
import styles from "./Buttons.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const style = { color: "#00A69A", margin: 0 };

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserService } from "../../service/user_service";

export default function Buttonable(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const custonOp = props.custonOpition;
	const [openDialog, setOpenDialog] = React.useState(false);
	const [openDialogAtivar, setOpenDialogAtivar] = React.useState(false);
	const [user, setUser] = React.useState(null);
	const [userNome, setUserNome] = React.useState("");
	const userService = new UserService();

	const handleClick = async (event) => {
		setAnchorEl(event.currentTarget);
		const usuario = await userService.getById(props.rowId);
		setUser(usuario?.ativo);
		setUserNome(usuario?.nome);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenDialog(false);
		props.deletFunc(props.rowId); //execução de function delet
	};
	const handleCloseAtivar = () => {
		setAnchorEl(null);
		setOpenDialogAtivar(false);
		props.deletFunc(props.rowId); //execução de function delet
	};
	const handleCloseCancell = () => {
		setAnchorEl(null);
		setOpenDialog(false);
		setOpenDialogAtivar(false);
	};
	const handleCloseArray = (e) => {
		setAnchorEl(null);
		if(window.location.pathname.includes("pedidos")) 
			e(props.loteID, props.rowId);
		else 
			e(props.rowId); 
	};
	const handleCloseDelet = () => {
		setAnchorEl(null);
		handleClickOpen();
	};
	const handleCloseAtivarOpen = async () => {
		setAnchorEl(null);
		handleClickOpenAtivar();
	};
	const handleCloseEdit = () => {
		setAnchorEl(null);
		window.location.href = props.editHref;
	};

	// determina quais das opções padrao irao aparecer
	function optionSettings() {
		if (props.optionSettings === "delet") {
			return <MenuItem onClick={handleCloseDelet}>Deletar</MenuItem>;
		} else if (props.optionSettings === "edit") {
			return <MenuItem onClick={handleCloseEdit}>Editar</MenuItem>;
		} else if (props.optionSettings === "user") {
			return (
				<>
					<MenuItem onClick={handleCloseEdit}>Editar</MenuItem>
					<MenuItem onClick={handleCloseAtivarOpen}>
						{!user ? "Ativar" : "Desativar"}
					</MenuItem>
				</>
			);
		} else {
			return (
				<>
					<MenuItem onClick={handleCloseEdit}>Editar</MenuItem>
					<MenuItem onClick={handleCloseDelet}>Deletar</MenuItem>
				</>
			);
		}
	}
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};
	const handleClickOpen = () => {
		setOpenDialog(true);
	};
	const handleClickOpenAtivar = () => {
		setOpenDialogAtivar(true);
	};
	//permite a entrada de opções adicionais para isso tem que passar um array com objetos o "item" e a "func" que sera executada pela função
	function custonOpition() {
		if (custonOp !== undefined && custonOp !== null) {
			return (
				<>
					{optionSettings()}
					{custonOp.map((opt) => {
						return (
							<MenuItem key={opt.item} id={props.rowId} onClick={() => handleCloseArray(opt.func)}>
								{opt.item} 
							</MenuItem>
						);
					})}
				</>
			);
		} else {
			return <>{optionSettings()}</>;
		}
	}
	return (
		<div className={props.className}>
			<Button
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				<I.IconMoreVert style={style} />
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseCancell}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{custonOpition()}
			</Menu>
			<Dialog
				open={openDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseDialog}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{props.deleteMessage ?? "Deseja Deletar?"}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button
						className={styles.ButtonCancelarModal}
						onClick={handleCloseCancell}
					>
						Cancelar
					</button>
					<button className={styles.ButtonColherModal} onClick={handleClose}>
						Deletar
					</button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDialogAtivar}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseDialog}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Deseja {!user ? "Ativar" : "Desativar"} {userNome}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button
						className={styles.ButtonCancelarModal}
						onClick={handleCloseCancell}
					>
						Cancelar
					</button>
					<button className={styles.ButtonColherModal} onClick={handleCloseAtivar}>
						{!user ? "Ativar" : "Desativar"}
					</button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
