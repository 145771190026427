import styles from "./SelectProperty.module.css";
import * as React from "react";

import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Popover,
	Typography,
} from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DomainIcon from "@mui/icons-material/Domain";
import { PropertyService } from "../../../service/property_service";

function AccessibleBadges(props) {
	const [property, setProperty] = React.useState(null);
	const [allProperties, setAllProperties] = React.useState([]);
	const propertyService = new PropertyService();

	React.useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		const userId = user.userId;
		
		getAndSetProperties(userId);
	}, []);

	const getAndSetProperties = async (userId) => {
		const userProperties = await propertyService.getByUser(userId);
		const properties =  userProperties.map((prop) => prop.propriedade);
		setAllProperties(properties);

		const propertyId = localStorage.getItem("propertyId");
		if(propertyId) {
			const currentProperty = properties.find((prop) => prop.id === propertyId);
			localStorage.setItem("propertyId", currentProperty.id);
			setProperty(currentProperty);
		} else if (properties.length > 0){
			localStorage.setItem("propertyId", properties[0].id);
			setProperty(properties[0]);
		}
	};

	function changeProperty(item) {
		if (item && (!property || item.id !== property.id)) {
			setProperty(item);
			localStorage.setItem("propertyId", item.id);
			window.location.reload(false);
		}
	}

	function formatCpfCnpj(cpfCnpj) {
		let cpfCnpjNumbers = cpfCnpj.split("");
		if (cpfCnpjNumbers.length === 11) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				cpfCnpjNumbers[2] +
				"." +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				cpfCnpjNumbers[5] +
				"." +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				cpfCnpjNumbers[8] +
				"-" +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10]
			);
		} else if (cpfCnpj.length === 14){
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				"." +
				cpfCnpjNumbers[2] +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				"." +
				cpfCnpjNumbers[5] +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				"/" +
				cpfCnpjNumbers[8] +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10] +
				cpfCnpjNumbers[11] +
				"-" +
				cpfCnpjNumbers[12] +
				cpfCnpjNumbers[13]
			);
		} else {
			return "CPF/CNPJ Inválido";
		}
	}
	return (
		<>
			<PopupState variant="popover" sx={{ width: "50%" }}>
				{(popupState) => (
					<div>
						<Button
							sx={{
								bgcolor: "#00A69A",
								color: "white",
								minWidth: { xs: "0", sm: "230px" },
								maxWidth: "max-content",

								maxHeight: "50px",
								borderRadius: "8px",
								display: "flex",
							}}
							{...bindTrigger(popupState)}
						>
							<Box
								sx={{
									display: { xs: "none", sm: "flex" },
									flexDirection: "column",
									alignItems: "start",
									justifyContent: "center",
									minWidth: { xs: "0", sm: "80%" },
									p: 1,
									m: 0,
								}}
							>
								<Typography visibility={{ xs: "hidden", sm: "visible" }}>
									<h2 className={styles.empresaTitle}>
										{property && property.nome ? property.nome : ""}
									</h2>
								</Typography>
								<Typography
									visibility={{ xs: "hidden", sm: "visible" }}
									className={styles.empresaCnpj}
								>
									{property && property.cpf_cnpj
										? formatCpfCnpj(property.cpf_cnpj)
										: ""}
								</Typography>
							</Box>
							<Box
								sx={{
									display: { xs: "flex", sm: "none" },
									flexDirection: "column",
									alignItems: "start",
									justifyContent: "center",
									minWidth: { xs: "0", sm: "10%" },
									p: 0,
									m: 0,
								}}
							>
								<DomainIcon
									visibility={{ xs: "visible", sm: "hidden", md: "hidden" }}
									sx={{ width: 32, height: 32 }}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "start",
									justifyContent: "center",
									minWidth: { xs: "0", sm: "10%" },
									p: 0,
									m: 0,
								}}
							>
								{popupState.isOpen ? <ExpandLess /> : <ExpandMore />}
							</Box>
						</Button>
						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{
								vertical: props.verticalAnchorOrigin,
								horizontal: props.horizontalAnchorOrigin,
							}}
							transformOrigin={{
								vertical: props.verticalTransformOrigin,
								horizontal: props.horizontalTransformOrigin,
							}}
						>
							<List
								sx={{
									width: "100%",
									maxWidth: 360,
									bgcolor: "background.paper",
									maxHeight: "50vh",
								}}
							>
								{allProperties.map((item) => {
									return (
										<>
											<Divider variant="inset" component="li" />
											<ListItem
												key={item.id}
												alignItems="flex-start"
												sx={{ cursor: "pointer" }}
												onClick={() => changeProperty(item)}
												className={styles.listproperty}
											>
												<ListItemText
													primary={item && item.nome ? item.nome : ""} //nome da propriedade
													secondary={
														<React.Fragment>
															<Typography
																sx={{ display: "inline" }}
																component="span"
																variant="body2"
																color="inherit"
															>
																{item && item.cpf_cnpj
																	? formatCpfCnpj(item.cpf_cnpj)
																	: ""}
															</Typography>
														</React.Fragment>
													}
												/>
											</ListItem>
											<Divider variant="inset" component="li" />
										</>
									);
								})}
							</List>
						</Popover>
					</div>
				)}
			</PopupState>
		</>
	);
}
export default AccessibleBadges;
