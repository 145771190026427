import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

function AvisoPrivacidade() {
	const [id, setId] = useState(0);

	function goTo(e) {
		setId(e);
	}
	return (
		<Box
			component="main"
			sx={{
				position: "relative",
			}}>
			<Typography variant="body2" sx={{ display: "flex", color: "#00A69A", fontWeight: 600, justifyContent: "flex-end" }} id="0">Data da última atualização: <Typography variant="body2"  sx={{ color: "black" }}>09 de dezembro de 2022</Typography></Typography>
			<br/>			
			<Typography variant="body2" sx={{pb:1}}>
        Nós, da <b style={{color:"#00A69A"}}>J&T SOLUÇÕES EM AGRONOMIA E INFORMÁTICA LTDA - SISAGRI</b>, (“Empresa” ou “nós”), inscrita no CNPJ/ME sob o nº 33.508.601/0001-69, levamos a sua privacidade
        e a proteção dos seus dados a sério e nos comprometemos em garantir seus direitos.
        Portanto, este documento explica como tratamos seus dados pessoais, quais são os seus
        direitos e como você pode exercê-los.
			</Typography>
			<br/>
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }}>Como este documento está dividido:</Typography>		
			<br />
			<a
				href={`#${id}`}
				onClick={()=> goTo("1")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>1. POR QUE TRATAMOS SEUS DADOS PESSOAIS?</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("2")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>2. O QUE É PRECISO PARA ENTENDER ESSE AVISO DE PRIVACIDADE?</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("3")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>3. QUE TIPO DE DADOS PESSOAIS NÓS TRATAMOS</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("4")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>4. COM QUEM COMPARTILHAMOS SEUS DADOS PESSOAIS</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("5")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>5. SEUS DIREITOS EM RELAÇÃO AOS SEUS DADOS PESSOAIS</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("6")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>6. PERÍODO DE RETENÇÃO DOS DADOS PESSOAIS</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("7")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>7. COMO PROTEGEMOS SEUS DADOS PESSOAIS</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("8")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>8. CANAIS DE CONTATO</Typography>
			</a>
			<a
				href={`#${id}`}
				onClick={()=> goTo("9")}
				style={{
					color: "#00ba9d",
					textDecoration: "none",
				}}
			>
				<Typography variant="body2" sx={{ color: "black", fontWeight: 600, }}>9. ALTERAÇÕES NESTE AVISO DE PRIVACIDADE</Typography>
			</a>
			
			<br/>
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="1">1. POR QUE TRATAMOS SEUS DADOS PESSOAIS?</Typography>		
			<br />
			<Typography variant="body2" sx={{pb:1}}>
        A SISAGRI é uma agritech cearense parceira do homem do campo. Trabalhamos para
        alavancar o produtor rural por meio de uma plataforma web e mobile que permite a
        adequação às legislações e certificações que contemplam a rastreabilidade vegetal,
        ajudando o produtor a garantir a segurança e qualidade dos alimentos, adequando-os
        às exigências de mercado e levando transparência para toda a cadeia do alimento
        vegetal fresco.
			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
        Nos comprometemos com a conexão do produtor com a solução, criando laços e raízes
        com cada propriedade rural. Afinal, a parceria é nosso diferencial! Assim, para que
        possamos prestar esses serviços adequadamente precisamos tratar alguns dados
        pessoais (cujo tratamento será detalhado ao longo deste documento), pois é necessário
        realizar cadastro dos usuários na Plataforma, manter registros e vincular essas
        informações.
			</Typography>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="2">2. O QUE É PRECISO PARA ENTENDER ESSE AVISO DE PRIVACIDADE?</Typography>		
			<br />
			<Typography variant="body2" sx={{pb:1}}>
        A fim de proporcionar melhor e maior compreensão dos termos usados neste Aviso,
        listamos abaixo alguns conceitos e definições que serão utilizados ao longo deste
        documento. Esses são conceitos e definições utilizados pela Lei Geral de Proteção de
        Dados e outras normas sobre privacidade e proteção de dados.

			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
        Quando estes termos forem utilizados, iniciarão com letra maiúscula:
			</Typography>
			<br />
			<Grid container columnSpacing={2} rowSpacing={1} sx={{ display: "flex", justifyContent: "center",width:"100%", alignItems:"center"}}>
				<Grid item xs={6} sm={2} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0}}>
						Termo
				</Grid>
				<Grid item xs={6} sm={10} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0 }}>
						Definição
				</Grid>
        
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Aviso</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						É este documento: Aviso de Privacidade (Importante destacar que esse
            documento também é chamado por alguns de “Política de Privacidade”,
            porém o termo mais adequado é “Aviso”, sendo a “Política” é um
            documento interno).
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Dado Pessoal</b><br/>
            Art. 5º, I,
            LGPD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						É qualquer informação relacionada a uma pessoa física, que seja capaz
            de identificar essa pessoa ou tornar possível a sua identificação.
            São exemplos de dados pessoais que podem permitir a identificação de
            uma pessoa física: Nome, CPF, endereço, telefone, e-mail, etc.
					</Typography>
				</Grid>
				
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Controlador</b><br/>
            Art. 5º, VI,
            LGPD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						É a pessoa, física ou jurídica, pública ou privada, que pode decidir
            sobre a finalidade e o tratamento de dados. Neste caso, via de regra, o
            Controlador será a Sisagri.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Empresa</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
            Somos nós, a Sisagri.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Operador</b><br/>
            Art. 5º, VII,
            LGPD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						É a pessoa, física ou jurídica, pública ou privada, que realiza o
            tratamento de dados em nome do Controlador. Neste caso, via de regra,
            os Operadores serão as pessoas com as quais a Sisagri compartilha os
            dados pessoais para realizar determinadas atividades em nome da
            Sisagri, como serviços de armazenamento em nuvem.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Tratamento</b><br/>
            Art. 5º, X,
            LGPD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						É toda forma de uso que podemos fazer dos seus Dados Pessoais,
            incluindo, mas não se limitando, às seguintes atividades: coleta,
            armazenamento, consulta, uso, compartilhamento, transmissão,
            classificação, reprodução, exclusão e avaliação.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Titular</b><br/>
            Art. 5º, V,
            LGPD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
          É a pessoa física a quem os dados se referem
					</Typography>
				</Grid>
			</Grid>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="3">3. QUE TIPO DE DADOS PESSOAIS NÓS TRATAMOS</Typography>		
			<br />
			<Typography variant="body2" sx={{pb:1}}>
        Os tipos de Dados Pessoais que tratamos são aqueles que dizem respeito diretamente
        ao serviço que prestamos. Tratamos dados de alguns tipos de usuários, são eles:
        Usuários comuns e Usuários com poderes de administrador, bem como dados de
        clientes inseridos pelos usuários, a fim de proporcionar controle e mecanismos de
        gerenciamento para os nossos usuários.
			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
        Não precisamos, geralmente, tratar Dados Pessoais sensíveis. Se viermos a tratá-los,
        alteraremos este Aviso para informar quais dados tratamos e como o fazemos.
        Listamos abaixo algumas situações em que podemos tratar seus Dados Pessoais, no
        contexto da relação que você mantém (ou pode manter) conosco:
			</Typography>
			<br/> 
			<Grid container columnSpacing={2} rowSpacing={1} sx={{ display: "flex", justifyContent: "center",width:"100%", alignItems:"center"}}>
				<Grid item xs={6} sm={2} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0}}>
					Tipos de
          Dados
				</Grid>
				<Grid item xs={6} sm={2} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0 }}>
					Dados
				</Grid>
				<Grid item xs={6} sm={8} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0 }}>
					Finalidade
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Dados de Cadastro</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						Nome, CPF,CEP, E-mail,Telefone
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2">
						Identificar o usuário e vinculá-lo a propriedades, para fins
            de utilização da plataforma.
            Além disso, esses dados também são utilizados para
            controle e registro de acessos dentro da plataforma, a fim
            de garantir segurança e rastreabilidade no uso das funções
            disponibilizadas.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Dados da Propriedade</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						Endereço
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2">
						Identificação da propriedade para fins de acompanhamento geral
					</Typography>
				</Grid>			
			</Grid>
			<br/>
			<Typography variant="body2" sx={{pb:1}}>
				Além desses dados, é possível que durante a interação com a plataforma e por meio da
        utilização desta outros dados pessoais sejam coletados, a depender da relação que você
        mantenha conosco e da sua interação com a plataforma. Nesses casos, tomaremos
        todas as medidas necessárias para garantir a proteção desses dados.
			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
				Importante destacar também que, por meio da plataforma, poderemos coletar algumas
        informações cadastrais dos clientes dos usuários da plataforma, a fim de proporcionar
        controle e gestão.
			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
				Poderemos ainda, durante o acesso à Plataforma, fazer uso de tecnologias que coletam
        outros dados considerados pessoais, como os cookies.
			</Typography>
			<Typography variant="body2" sx={{pb:1}}>
				Alguns Cookies, denominados necessários, essenciais ao funcionamento do website, não
        podem ser desativados, seja para garantir o funcionamento da página web, seja por
        exigências legais decorrentes do Marco Civil da Internet (Lei 12.965/2014). São eles:
			</Typography>
			<ul>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Dados de sessões, navegação e páginas acessadas;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
				● Domínio, registro de data e hora inicial (primeira visita), último registro de data
          e hora (última visita), registro de data e hora atual (visita atual) e número de
          sessões;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Comportamento de navegação;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Histórico de navegação;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Distinção de Usuários;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Primeira navegação;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Dados de cadastro e navegação;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Dados de cadastro através do endereço IP;
					</Typography>
				</li>
				<li>
					<Typography variant="body2" sx={{ pb: 1 }}>
            ● Dados para ajuste e otimização de navegabilidade.
					</Typography>
				</li>
			</ul>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="4">4. COM QUEM COMPARTILHAMOS SEUS DADOS PESSOAIS</Typography>		
			<br />
			<Typography variant="body2" sx={{pb:1}}>
				Para realizarmos nossas atividades da melhor maneira possível, operamos em parceria
        com outras organizações. Atuamos em parceria com essas empresas, pois elas são
        especializadas em determinados serviços (como de Tecnologia da Informação e
        Armazenamento em Nuvem), o que melhora a qualidade do serviço prestado.
			</Typography>
			<br />
			<Grid container columnSpacing={2} rowSpacing={1} sx={{ display: "flex", justifyContent: "flex-start",width:"100%", alignItems:"center"}}>
				<Grid item xs={12} sm={12} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "flex-start", p:0, m:0,pr:1}}>
					Nossos Parceiros
				</Grid>				
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						Amazon Web Services (AWS)
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} sx={{borderBottom:"1px solid black"}} >
					<Typography variant="body2">
						Serviço de cloud computing utilizado para manter a disponibilidade, integridade e
            confidencialidade dos dados em nuvem.
					</Typography>
				</Grid>
			</Grid>
			<br />
			<Typography variant="body2" sx={{pb:1}}>
				Além disso, poderemos compartilhar alguns dados pessoais a depender de autorização
        judicial ou exigência legal.
			</Typography>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="5">5. SEUS DIREITOS EM RELAÇÃO AOS SEUS DADOS PESSOAIS</Typography>		
			<br />
			<Typography variant="body2" sx={{pb:1}}>
				A Lei Geral de Proteção de Dados (LGPD) garante a você diversos direitos, pois os dados
        pessoais são seus. Nós nos comprometemos e nos preocupamos com sua privacidade e
        proteção de dados pessoais e, por essa razão, listamos abaixo os seus direitos, conforme
        assegurado pela Lei.
			</Typography>
			<br />
			<Grid container columnSpacing={2} rowSpacing={1} sx={{ display: "flex", justifyContent: "center",width:"100%", alignItems:"center"}}>
				<Grid item xs={6} sm={2} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0}}>
					Seus Direitos 
				</Grid>
				<Grid item xs={6} sm={10} sx={{bgcolor:"#00A69A",color:"white",display: "flex", justifyContent: "center", p:0, m:0 }}>
					Explicação
				</Grid>        
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Confirmação e Acesso</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Você pode solicitar à Empresa a confirmação sobre a
            existência de tratamento dos seus Dados Pessoais para que,
            em caso positivo, você possa acessá-los, inclusive por meio
            de solicitação de cópias dos registros que temos sobre você.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Correção</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Você pode solicitar a correção dos seus Dados Pessoais, caso
            estes estejam incompletos, inexatos ou desatualizados.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Anonimização, bloqueio ou eliminação</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Você pode solicitar:<br/>
            i) a anonimização dos seus Dados Pessoais, de forma que
            eles não possam mais ser relacionados a você e, portanto,
            deixem de ser Dados Pessoais;<br/>
            ii) o bloqueio dos seus Dados Pessoais, suspendendo
            temporariamente a possibilidade de os tratarmos para certas
            finalidades; ou<br/>
            iii) a eliminação dos seus Dados Pessoais, caso em que
            devemos apagar todos os seus Dados Pessoais sem
            possibilidade de reversão.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Informação sobre o compartilhamento</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Você tem o direito de saber quais são as entidades públicas e
            privadas com as quais a Empresa realiza uso compartilhado
            dos seus Dados Pessoais. Acima, no tópico 3 deste Aviso, há
            uma indicação das nossas relações com terceiros que podem
            envolver o compartilhamento de Dados Pessoais. Em todo
            caso, se você tiver dúvidas ou quiser mais detalhes, você tem
            o direito de nos solicitar essas informações. A depender do
            caso, podemos limitar as informações fornecidas a você, caso
            a sua divulgação possa violar a propriedade intelectual ou
            segredo de nossos negócios. Além disso, o
            CONTROLADOR poderá compartilhar seus dados com
            outras entidades. Neste caso, é preciso entrar em contato com
            eles para ter essas informações.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}> Informação sobre a possibilidade de não consentir</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Você tem o direito de receber informações claras e completas
            sobre a possibilidade e as consequências de não fornecer
            consentimento, quando ele for solicitado pelo Controlador de
            Dados. O seu consentimento, quando necessário, deve ser
            livre e informado. Portanto, sempre que for solicitado seu
            consentimento, você será livre para negá-lo – nesses casos, é
            possível que alguns serviços não possam ser prestados.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Revogação do consentimento</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						Caso você tenha consentido com alguma finalidade de
            tratamento dos seus Dados Pessoais, você pode sempre optar
            por retirar o seu consentimento. No entanto, isso não afetará
            a legalidade de qualquer Tratamento realizado anteriormente
            à revogação. Se você retirar o seu consentimento, é possível
            que fiquemos impossibilitados de lhe prestar certos serviços.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Oposição</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Typography variant="body2">
						A lei autoriza o tratamento de Dados Pessoais mesmo sem o
            seu consentimento ou um contrato. Nessas situações,
            somente trataremos seus Dados Pessoais se tivermos motivos
            legítimos para tanto, como, por exemplo, quando for
            necessário para garantir a segurança da nossa aplicação.
            Caso você não concorde com alguma finalidade de
            tratamento dos seus Dados Pessoais, você poderá apresentar
            oposição, solicitando a interrupção.
					</Typography>
				</Grid>
			</Grid>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }}>Observações importantes:</Typography>		
			<br />
			<Typography variant="body2">
				Para sua segurança, sempre que você apresentar uma requisição para exercer seus
        direitos, poderemos solicitar algumas informações e/ou documentos complementares
        para que possamos comprovar a sua identidade, buscando impedir fraudes.
			</Typography>
			<br />
			<Typography variant="body2">
				Esses dados serão utilizados apenas para a finalidade de confirmar a identidade do
        titular. 
			</Typography>
			<br />
			<Typography variant="body2">
				Em alguns casos, a Empresa pode ter motivos legítimos para deixar de atender a uma
        solicitação de exercício de direitos. Essas situações incluem, por exemplo, casos em que
        uma revelação de informações específicas poderia violar direitos de propriedade
        intelectual ou segredos de negócio da Empresa ou de terceiros. Mas não se preocupe,
        caso alguma dessas situações aconteça, explicaremos a você!
			</Typography>
			<br />
			<Typography variant="body2" sx={{fontWeight: "bold"}}>
				Caso você fique com alguma dúvida sobre esses pontos, ou sobre outras questões
        acerca dos seus direitos, fique à vontade para entrar em contato conosco por meio
        dos canais informados ao final deste Aviso.
			</Typography>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="6">6. PERÍODO DE RETENÇÃO DOS DADOS PESSOAIS</Typography>		
			<br />
			<Typography variant="body2">
				Dados Pessoais são armazenados somente pelo tempo que forem necessários para
				cumprir com as finalidades para as quais foram coletados, exceto se houver qualquer
				outra razão para sua manutenção como cumprimento de obrigações legais, regulatórias,
				contratuais, entre outras permitidas pela lei.
			</Typography>
			<br />
			<Typography variant="body2">
				Sempre fazemos uma análise técnica para determinar o período de retenção adequado
				para cada tipo de Dado Pessoal coletado, considerando a sua natureza, necessidade de
				coleta e finalidade para a qual ele será tratado, bem como eventuais necessidades de
				retenção para o cumprimento de obrigações ou o resguardo de direitos.
			</Typography>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="7">7. COMO PROTEGEMOS SEUS DADOS PESSOAIS</Typography>		
			<br />
			<Typography variant="body2">
				Nossa responsabilidade é cuidar dos seus Dados Pessoais e utilizá-los somente para as
				finalidades descritas neste Aviso. Para garantir a sua privacidade e a proteção dos seus
				dados Pessoais, adotamos os recursos tecnológicos e medidas administrativas
				necessárias para garantir a segurança de todos os dados tratados por nós. Entre as
				medidas de segurança, técnicas e administrativas implementadas estão: a de controles
				de acesso a sistemas e ambientes de tratamento de dados, técnicas de criptografia e a
				instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls),
				entre outros controles de segurança da informação.
			</Typography>
			<br />
			<Typography variant="body2">
				SOB HIPÓTESE NENHUMA VENDEMOS, ALUGAMOS OU COMPARTILHAMOS
				INDEVIDAMENTE OS DADOS PESSOAIS QUE TRATAMOS.
			</Typography>
			<br />
			<Typography variant="body2">
				Nós nos esforçamos para proteger a privacidade de seus Dados Pessoais, mas
				infelizmente não podemos garantir total segurança. Entradas e usos não autorizados de
				terceiros com informações suas, falhas de hardware ou software que não estejam sob
				nosso controle completo e outros fatores externos podem comprometer a segurança
				dos seus Dados Pessoais.
			</Typography>
			<br />
			<Typography variant="body2">
				Por isso, sua atuação é fundamental para a manutenção de um ambiente seguro para
				todos. Caso você identifique ou tome conhecimento de qualquer fator que comprometa
				a segurança dos seus dados na sua relação conosco, por favor entre em contato por
				meio das informações de contato indicadas abaixo.
			</Typography>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="8">8. CANAIS DE CONTATO</Typography>		
			<br />
			<Typography variant="body2">
				Meios de contato para falar sobre dados pessoais
			</Typography>
			<br />
			<Typography variant="body2">
				Se você acredita que seus Dados Pessoais foram tratados de maneira incompatível com
				este Aviso ou com as suas escolhas enquanto Titular dos seus Dados Pessoais, ou, ainda,
				se você tiver dúvidas, comentários ou sugestões relacionadas a este documento e à
				forma como tratamos seus Dados Pessoais, entre em contato conosco.
			</Typography>
			<br />
			<Grid container columnSpacing={2} rowSpacing={1} sx={{ display: "flex", justifyContent: "center",width:"100%", alignItems:"center"}}>
				<Grid item xs={12} sm={4} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>Endereço para correspondências:</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2">
						Logradouro: Sítio Limoeiro dos Pompeus, nº S/N
						Bairro: Zona Rural, CEP: 62380-000 - Guaraciaba do
						Norte
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4} >
					<Typography variant="body2">
						<b style={{ color: "#00A69A" }}>E-mail para contato:</b><br/>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2">
						<a href="mailto:suportesisagri@gmail.com">suportesisagri@gmail.com</a> e/ou <a href="mailto:atendimento@sisagri.com.br">atendimento@sisagri.com.br</a>						
					</Typography>
				</Grid>				
			</Grid>
			<br />
			<Typography variant="body2" sx={{ color: "#00A69A", fontWeight: 600, }} id="9">9. ALTERAÇÕES NESTE AVISO DE PRIVACIDADE</Typography>		
			<br />
			<Typography variant="body2">
				Como estamos sempre buscando melhorar os nossos serviços e a forma como
				operamos, este Aviso de Privacidade pode passar por determinadas atualizações, de
				modo a refletir as melhorias realizadas e eventuais incrementos aos nossos serviços.
			</Typography>
			<br />
			<Typography variant="body2">
				Portanto, <b style={{ fontWeight:"700" }}>recomendamos a visita periódica desta página</b> para que você tenha
				conhecimento sobre as modificações efetivadas.
			</Typography>
			<br />
		</Box>   
	);
}

export default AvisoPrivacidade;