import React from "react";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";
import "./style.css";
import { IconDashboardCustomize } from "../../components/IconsImport";

//components
//essa pagina nao existe mais
import RegisterArea from "../../components/RegistrationArea";

function PartionCad() {
	return (
		<div>
			<TopMenu open={["register", "property"]} />
			<div>
				{/* href defini a volta  title defini o titulo da pagina*/}
				<RegisterArea title="Resgistro de parcela" href="/parcela">
					{/* inputs da pagina de resgitro */}

					<input type="text" placeholder="Nome" />
					<input type="text" placeholder="Área" />
					<a href="" className="seletorAreaButton">
						<IconDashboardCustomize />
						Selecionar Área
					</a>
				</RegisterArea>
			</div>
		</div>
	);
}
export default PartionCad;
