import { PropertyServiceRepository } from "../repository/property_service_repository";

export class PropertyServiceService {

	constructor() {
		this.propertyServiceRepository = new PropertyServiceRepository();
	}

	async get(propertyId, params) {
		const propertyServices = await this.propertyServiceRepository.get(propertyId, params);
		return propertyServices.services;
	}

	async getById(id) {
		const propertyService = await this.propertyServiceRepository.getById(id);
		return propertyService;
	}

	async save(propertyService) {
		return await this.propertyServiceRepository.save(propertyService);
	}

	async update(propertyService) {
		return await this.propertyServiceRepository.update(propertyService);
	}

	async delete(id) {
		return await this.propertyServiceRepository.delete(id);
	}

	async changeVisibility(id) {
		await this.propertyServiceRepository.changeVisibility(id);
	}
}