import React from "react";

//import css module
import styles from "./AsideMenu.module.css";
import Logo from "../../../Assets/images/logoMenu.svg";
import AsideMenuList from "./AsideMenuList/AsideMenuList";
import AsideMenuListItem from "./asideMenuListItem/asideMenuListItem";
import * as I from "../../IconsImport";
import Divider from "@mui/material/Divider";

function AsideMenu(props) {
	const [isLoaded, setIsLoaded] = React.useState(false);
	const setClassItemFocus = (pag) => {
		if (props.open[1] === pag) return true; //se o subItem estiver aberto, retorna true
	};
	return (
		<div className={styles.asideMenu}>
			<div style={{height: 150}}>
				<img 
					className={styles.logoAsideBar} 
					src={Logo} 
					onLoad={() => setIsLoaded(true)}
					style={{ filter: !isLoaded ? "blur(10px)" : "none", transition: "filter 0.3s ease" }}
					alt="Logo"
				/>
			</div>

			<Divider sx={{ bgcolor: "white", my: 2, width: "100%" }} />

			<aside className={styles.asideMenuList}>
				<div className={styles.menuList}>
					<div>
						<AsideMenuListItem
							nome="home"
							href="/home"
							icon={<I.IconPieChart />}
							text="Inicío"
							classItemStyle={setClassItemFocus("home")}
						/>
					</div>
					<AsideMenuList open={props.open} />
					<Divider sx={{ bgcolor: "white", my: 2 }} />
				</div>
			</aside>
		</div>
	);
}

export default AsideMenu;
