import React, { useEffect, useState } from "react";

import LogoHorizontal from "../../../../Assets/images/logoHeaderPdf.png";
import Logo from "../../../../Assets/images/logoMenu.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
import { PropertyService } from "../../../../service/property_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#00ba9d",
		paddingBottom: "75px",
		paddingTop: "10px",
		paddingLeft: "10px",
		paddingRight: "10px",
	},
	body: {
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	logoPropriedade: {
		width: "auto",
		height: "300px",
		margin: "50px",
		marginBottom: "0",
		padding: "0",
	},
	qrCode: {
		width: "auto",
		minWidth: "200px",
	},

	qrCodeContainer: {
		width: "90%",
		height: "100%",
		margin: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		color: "#009199",
	},
	qrCodeTextContainer: {
		marginLeft: "20px",
		display: "flex",
		height: "100%",
		width: "80%",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		color: "#009199",
	},
	qrCodeContainerTexTitle: {
		fontSize: "74px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	qrCodeContainerTextSubTitle: {
		fontSize: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},

	logoFooter: {
		width: "auto",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "15px",
		paddingHorizontal: "5px",
		color: "white",
	},
	pagnumber: { color: "white", paddingHorizontal: "5px" },
});

const PdfHangTags = (props) => {
	const ids = props.ids;
	const [talhao, setTalhao] = useState("");
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const talhao = await propertyService.getPlotById(props.item);
		setTalhao(talhao);
	};

	function QRCodePage({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();
		return (
			<Page
				key={`page_${id}`}
				size={{ width: 841.89, height: 595.28 }}
				style={styles.page}
			>
				<View style={styles.body}>
					<View>
						<Image style={styles.logoPropriedade} src={LogoHorizontal} />
					</View>
					<View style={styles.qrCodeContainer}>
						<View style={styles.qrCodeTextContainer}>
							<Text style={styles.qrCodeContainerTexTitle}>Talhão {talhao.nome}</Text>
							<Text style={styles.qrCodeContainerTextSubTitle}>
								AQUI TEM RASTREABILIDADE
							</Text>
						</View>
						<View>
							<Image allowDangerousPaths src={dataUrl} style={styles.qrCode} />
						</View>
						{/* <View ref={ref} /> */}
					</View>
				</View>
				{/* roda pé  */}
				<View style={styles.footer} fixed>
					<View style={styles.containerFooter}>
						<Image src={Logo} style={styles.logoFooter} />
						<View style={styles.paggingContainer}>
							<View style={styles.paggingText}>
								<Text>Rastreabiidade vegetal e gestão Agrícola</Text>
								<Text style={{ fontFamily: "Roboto bold" }}>
									www.sisagri.com.br/
								</Text>
							</View>
						</View>
					</View>
				</View>
			</Page>
		);
	}
	return (
		<Document>
			{ids.map((id) => (
				<QRCodePage id={id} key={id} />
			))}
		</Document>
	);
};

export default PdfHangTags;
