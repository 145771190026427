import pt from "date-fns/locale/pt";
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./inputData.module.css";

function InputData(props, ref) {
	const [selectedDate, setSelectedDate] = useState(null);

	function handleDateChange(date) {
		setSelectedDate(date);
		props.onDateChange(date);
	}

	return (
		<div className={style.input}>
			<DatePicker
				className={props.classN}
				value={props.value}
				selected={selectedDate}
				onChange={handleDateChange}
				dateFormat="dd/MM/yyyy"
				portalId="root-portal"
				withPortal={props.withPortal}
				showMonthDropdown={true}
				showYearDropdown={true}
				dropdownMode="select"
				placeholderText={props.placeholder}
				locale={pt}
				ref={ref}
				slotProps={{
					textField: { fullWidth: true, width: "100%" },
					popper: { sx: { zIndex: 1400, width: "100%"} }, // Aumenta o z-index do calendário
				}}
			/>
		</div>
	);
}

InputData.displayName = "InputData";

export default forwardRef(InputData);
