import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";

//import css modules
/* import "./style.css"; */
import styles from "./ButtonExportChart.module.css";

function ButtonExportChart(props) {
	return (
		<button
			className={
				props.disabled
					? styles.ButtonExportChartDisabled
					: styles.ButtonExportChart
			}
			onClick={() => (window.location = props.href)}
			disabled={props.disabled}
		>
			<IosShareIcon /> Exportar relatório em PDF
		</button>
	);
}

export default ButtonExportChart;
