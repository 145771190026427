import React from "react";
import QRCode from "qrcode.react";
// import LogoHorizontal from "../../../Assets/images/logoHeaderPdf.png";

function QRGenerator(props) {
	const { value, documentId, margin } = props;
	return (
		<div>
			<QRCode
				id={documentId}
				value={value}
				size={190}
				bgColor="#FFF"
				fgColor="#000"
				includeMargin={margin}
				level={"H"}
				// imageSettings={{
				// 	src: LogoHorizontal,
				//  excavate: true,
				// 	height: 60,
				// 	width: 60,
				// }}
			/>
		</div>
	);
}

export default QRGenerator;
