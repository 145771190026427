import { ApplicationEquipmentRepository } from "../repository/application_equipment_repository";

export class ApplicationEquipmentService {

	constructor() {
		this.applicationEquipmentService = new ApplicationEquipmentRepository();
	}

	async get(params) {
		const applicationEquipmentData = await this.applicationEquipmentService.get(params);
		return applicationEquipmentData;
	}

}