import { SettingsRepository } from "../repository/settings_repository";

export class SettingsService {

	constructor() {
		this.settingsRepository = new SettingsRepository();
	}

	async getSocialNetwork(propertyId) {
		return await this.settingsRepository.getSocialNetwork(propertyId);
	}

	async saveSocialNetwork(socialNetwork) {
		await this.settingsRepository.saveSocialNetwork(socialNetwork);
	}

	async updateSocialNetwork(socialNetwork) {
		return await this.settingsRepository.updateSocialNetwork(socialNetwork);
	}

	async getTraceabilityPortalDataByProperty(propertyId) {
		return await this.settingsRepository.getTraceabilityPortalDataByProperty(propertyId);
	}

	async saveTraceabilityPortalData(socialNetwork) {
		await this.settingsRepository.saveTraceabilityPortalData(socialNetwork);
	}

	async updateTraceabilityPortalData(socialNetwork) {
		return await this.settingsRepository.updateTraceabilityPortalData(socialNetwork);
	}

	async getPropertyGalleryImages(propertyId) {
		return await this.settingsRepository.getPropertyGalleryImages(propertyId);
	}

	async savePropertyGalleryImages(propertyId, images) {
		return await this.settingsRepository.savePropertyGalleryImages(propertyId, images);
	}

	async updatePropertyGalleryImages(propertyId, images) {
		return await this.settingsRepository.updatePropertyGalleryImages(propertyId, images);
	}

	async getTraceabilityImage(propertyId) {
		return await this.settingsRepository.getTraceabilityImage(propertyId);
	}

	async saveTraceabilityImage(propertyId, image) {
		return await this.settingsRepository.saveTraceabilityImage(propertyId, image);
	}

	async updateTraceabilityImage(propertyId, image) {
		return await this.settingsRepository.updateTraceabilityImage(propertyId, image);
	}

	async getBusinessLogo(businessId) {
		return await this.settingsRepository.getBusinessLogo(businessId);
	}

	async deleteBusinessLogo(businessId) {
		return await this.settingsRepository.deleteBusinessLogo(businessId);
	}

	async saveBusinessLogo(businessId, image) {
		return await this.settingsRepository.saveBusinessLogo(businessId, image);
	}

	async updateBusinessLogo(businessId, image) {
		return await this.settingsRepository.updateBusinessLogo(businessId, image);
	}

	async getReviews(propertyId) {
		return await this.settingsRepository.getReviews(propertyId);
	}

	async saveReview(review) {
		return await this.settingsRepository.saveReview(review);
	}

	async getTraceabilityByOrderItem(harvestCode, orderItemCode) {
		return await this.settingsRepository.getTraceabilityByOrderItem(harvestCode, orderItemCode);
	}

	async getTraceabilityByProperty(propertyId) {
		return await this.settingsRepository.getTraceabilityByProperty(propertyId);
	}
}